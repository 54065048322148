@import '../../style/vars';
@import '../../style/mixins';

.personal {
  // .personal-hiw-container

  .personal-hiw-container{
    margin-top: 90px;
    max-width: 1540px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  // .personal__hiw

  &__hiw {
    position: relative;
    width: 100%;
    display: flex;
  }

  // .personal__center-img

  &__center-img {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
  }

  // .personal__hiw-article

  &__hiw-article {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 90px;
  }

  // .personal__hiw-text

  &__hiw-text {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & p {

    }
  }

  // .personal__hiw-text-container

  &__hiw-text-container {
    max-width: 437px;

    & strong, & b {
      align-self: start;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #1A3B70;
      margin-bottom: 1.5em;
    }

    & p {
      font-size: 16px;
      line-height: 150%;
      &:not(:last-child){
        margin-bottom: 1em;
      }
    }
  }

  // .personal__hiw-title

  &__hiw-title {
    align-self: start;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #1A3B70;
    margin-bottom: 1.5em;
  }

  // .personal__hiw-descr

  &__hiw-descr {
    font-size: 16px;
    line-height: 150%;
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }

  // .personal__hiw-img

  &__hiw-img {
    height: 491px;
    width: 100%;
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .personal__description

  &__description {
    background: #EFF3F9;
    padding: 86px 0;
  }

  &__description-text-wrap{
    max-width: 1284px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;

    & p {
      font-size: 16px;
      line-height: 150%;
      color: #000;
      &:not(:last-child){
        margin-bottom: 1em;
      }
    }
  }

  // .personal__description-text

  &__description-text {
    font-size: 16px;
    line-height: 150%;
    color: #000;
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }
}
.personal-header {
  width: 100%;
  height: 620px;
  position: relative;
  display: flex;
  & .personal-header-container{
    max-width: 1350px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
  }
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #273043;
    opacity: 0.8;
  }
  // .personal-header__bg

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    .video-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .personal-header__body

  &__body {
    position: relative;
    z-index: 1;
    max-width: 592px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  // .personal-header__title

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 40px;
  }

  // .personal-header__subtitle

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    margin-bottom: 70px;
  }

  // .personal-header__order-btn

  &__order-btn {
    padding: 0;
    width: 251px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}










@media only screen and (max-width: 1450px){
  .personal{
    &__center-img{
      display: none;
    }
  }
}

@media only screen and (max-width: 1420px){
  .personal-header{
    &__body {
      margin-left: 10%;
    }
  }
}

@media only screen and (max-width: 1420px){
  .personal-header{
    &__body {
      margin-left: 10%;
    }
  }
}


@media only screen and (max-width: 880px){
  .personal {
    .personal-hiw-container {
      margin-top: 0;
    }
  }

  .personal-header{
    min-height: 531px;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    &__title{
      margin-bottom: 16px;
      font-size: 18px;
    }
    &__subtitle{
      margin-bottom: 30px;
      font-size: 14px;
    }
    &__body{
      margin-left: auto !important;
      margin-right: auto !important;
      padding: 14px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
    }
    &__order-btn{
      width: 194px;
      height: 45px;
      font-size: 14px;
    }
  }
  .personal{
    &__hiw-text-container{
      max-width: 100%;
      padding: 0 20px;

      & p {
        font-size: 14px;
      }
    }
    &__hiw{
      flex-direction: column;
    }
    &__hiw-article{
      width: 100%;
    }
    &__hiw-text{
      height: fit-content;
      margin-top: 40px;
      margin-bottom: 50px;
    }
    &__hiw-title{
      font-size: 18px;
      margin-bottom: 30px;
    }
    &__hiw-descr{
      font-size: 14px;
    }
    &__hiw-article{
      margin-bottom: 17px;
    }
    &__hiw-img{
      width: 100%;
      height: auto;
    }
    &__description{
      padding: 55px 0;
    }
    &__description-text-wrap{
      max-height: 330px;
      overflow-y: auto;
      @include scroll-style;

      & {
        font-size: 14px;
      }
    }
    &__description-text{
    }
  }
}

@media only screen and (max-width: 360px){
  .personal{
    &__description-text-wrap{
      max-height: 250px;
    }
  }
}