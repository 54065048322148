@import '../../style/vars';
@import '../../style/mixins';

.delivery-pay {
  margin: 70px 0 100px;
  // .delivery-pay__title

  &__title {
    @include title;
    color: $primary;
    text-align: center;
  }

  // .delivery-pay__body

  &__body {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
  }

  // .delivery-pay__section-wrap

  &__section-wrap {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .delivery-pay__section-wrap--rigth{
  }

  // .delivery-pay__section

  &__section {
    max-width: 650px;
  }

  // .delivery-pay__section-title

  &__section-title {
    @include description;
    color: $secondary;
    margin-bottom: 40px;
  }

  // .delivery-pay__article

  &__article {
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  // .delivery-pay__article-title

  &__article-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $secondary;
    margin-bottom: 6px;
    padding-left: 30px;
    position: relative;
    &::before{
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 5px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
      background-size: 14px 14px;
      margin-right: 16px;
    }
  }

  // .delivery-pay__article-subtitle

  &__article-subtitle {
    padding-left: 30px;
    margin-bottom: 6px;
    @include main-text;
  }

  // .delivery-pay__logo-box

  &__logo-box {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    align-items: center;
    & img{
      width: 100%;
      height: auto;
      align-self: center;
    }
  }

  // .delivery-pay__logo-wrap

  &__logo-wrap{
    &:not(:last-child){
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 1435px){
  .delivery-pay{
    &__section-wrap{
      width: 48%;
    }
  }
}

@media only screen and (max-width: 880px){
  .delivery-pay{
    margin: 40px 0 60px 0;
    &__body{
      margin-top: 40px;
    }
    &__article-title{
      font-size: 14px;
    }
    &__section-title{
      margin-bottom: 24px;
    }
    .delivery-pay__logo-box{
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 670px){
  .delivery-pay{
    &__body{
      flex-direction: column;
    }
    &__section-wrap{
      width: 100% !important;
      &:not(:last-child){
        margin-bottom: 60px;
      }
    }

  }
}
