@import '../../style/vars';
@import '../../style/mixins';

.contacts {
  margin: 70px 0;
  // .contacts__title

  &__title {
    @include title;
    color: $primary;
    text-align: center;
    margin-bottom: 70px;
  }

  // .contacts__tabs

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
  }

  // .contacts__tab-btn

  &__tab-btn {
    margin: 0 30px;
    @include description;
    color: $secondary-text-color;
    border-bottom: 2px solid transparent;
    transition: .25s;
    &.active{
      border-color: $secondary;
      color: $secondary;
    }
  }

  // .contacts__page

  &__page {
  }

  // .contacts__addresses-wrap
  &__addresses-wrap--branches{
    transform: translateX(-100%);
  }
  &__addresses-wrap--tech{
    transform: translateX(100%);
  }
  &__addresses-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 0;
    height: 0;
    transition: transform 0.3s;
    &.active{
      opacity: 1;
      z-index: 1;
      overflow: hidden;
      // width: 100%;
      position: static;
      width: 100%;
      height: auto;
      transform: translateX(0);
      transition: transform 0.3s;
    }
  }

  // .contacts__address-item

  &__address-item {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-left: 30px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover,&.active{
      & .contacts__address, & .contacts__phone{
        color: $additional;
      }
    }
    &.active{
      & .contacts__address-mark{
        opacity: 1;
      }
    }
  }

  // .contacts__address-mark

  &__address-mark {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: .2s;
  }

  // .contacts__address

  &__address {
    @include main-text;
    transition: .3s;
  }

  // .contacts__phone

  &__phone {
    @include main-text;
    transition: .2s;
  }

  // .contacts__map-wrap

  &__map-wrap {
    position: relative;
  }

  // .contacts__mark-info

  &__mark-info {
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 360px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(44, 89, 157, 0.1);
    padding: 40px 50px;
    justify-content: space-between;
  }

  // .contacts__mark-phone

  &__mark-phone {
    @include description;
    color: $secondary;
  }

  // .contacts__mark-address

  &__mark-address {
    @include main-text;
  }

  // .contacts__mark-info-box

  &__mark-info-box {
  }

  // .contacts__mark-title

  &__mark-title {
    @include main-text;
    font-weight: 500 !important;
    color: $primary;
  }

  // .contacts__mark-subtitle

  &__mark-subtitle {
    @include main-text;
    & a{
      @include main-text;
    }
  }

  // .contacts__mark-btn

  &__mark-btn {
    display: flex;
    padding: 0;
    width: 100%;
    height: 51px;
    justify-content: center;
    align-items: center;
  }
}
.gm-style {
}
.gm-style-pbc {
}
.gm-style-pbt {
}
.btn-secondary {
}
.btn-ghost-secondary {
}
#map-branches{
  height: 420px;
}

.container-addresses{
  overflow: hidden;
  width: 100%;
  height: max-content;
  position: relative;
}
@media only screen and (max-width: 1100px) {
  .contacts__map-wrap{
    display: flex;
    & .contacts__mark-info{
      position: static !important;
    }
  }
  #map-branches{
    position: relative !important;
    width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
  .contacts{
    &__address-item{
      width: 48%;
    }
  }
}
@media only screen and (max-width: 880px) {
  .contacts{
    margin: 40px 0;
    &__title{
      margin-bottom: 40px;
    }
    &__addresses-wrap, &__tabs{
      margin-bottom: 24px;
    }
    &__address-item{
      margin-bottom: 12px;
    }
  }
}
@media only screen and (max-width: 690px) {
  .contacts{
    &__map-wrap{
      flex-wrap: wrap;
    }
    &__mark-info{
      position: static;
      min-height: 360px;
      margin: 0 auto;
    }
  }
}
@media only screen and (max-width: 515px) {
  .contacts{
    &__address-item{
      width: 100%;
    }
  }
}

@media only screen and (max-width: 370px) {
  .contacts{
    &__mark-info{
      padding: 20px;
      min-height: 320px;
    }
  }
}

@media only screen and (max-width: 370px) {
  .contacts{
    &__mark-info{
      padding: 20px;
      min-height: 320px;
    }
  }
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
