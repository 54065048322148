@import '../../style/vars';

.page404 {
  position: relative;
  height: 90vh;
  & .container {
    height: 100%;
  }

  // .page404__bg

  &__bg {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  // .page404__body

  &__body {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 590px;
  }

  // .page404__title

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 25px;
  }

  // .page404__subtitle

  &__subtitle {
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 40px;
  }

  // .page404__back-link

  &__back-link {
    display: block;
    width: fit-content;
  }
}


@media only screen and (max-width: 1375px){
  .page404 {
    &__body {
      background: rgba(255, 255, 255, 0.6);
      max-width: 630px;
      padding: 0 20px;
    }
  }
}

@media only screen and (max-width: 936px){
  .page404 {
    &__bg {
      & img {
        object-position: calc(68% - 20vw) 0;
      }
    }
  }
}

@media only screen and (max-width: 880px){
  .page404 {
    &__title {
      font-size: 24px;
    }
    &__subtitle {
      font-size: 16px;
    }
    &__back-link {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 640px){
  .page404 {
    & .container {
      padding: 0;
    }
  }
}
