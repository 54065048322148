@import '../../style/vars';
@import '../../style/mixins';

.scontrol {
    // .scontrol__top-wrap

    &__top-wrap {
        background: linear-gradient(0.3deg, #F9F8F8 0.29%, #F7F7F7 83.19%);
    }

    // .scontrol__advantages

    &__advantages {
        padding: 60px 0;
    }

    // .scontrol__advantages-body

    &__advantages-body {
        display: grid;
        column-gap: 2%;
        grid-template-columns: repeat(3, 32%);
        margin-top: 20px;
    }

    // .scontrol__advantage

    &__advantage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 38px;
        background: $white;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 6%;
        height: 136px;
    }

    // .scontrol__advantage-ico

    &__advantage-ico {
        height: 60px;
        width: 60px;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10%;
    }

    // .scontrol__advantage-text

    &__advantage-text {
        font-size: 16px;
        line-height: 150%;
        max-width: 302px;
    }

    // .scontrol__parallax-wrap

  &__parallax-wrap {
    position: relative;
  }

  // .scontrol__parallax-item-container-wrap

  &__parallax-item-container-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  // .scontrol__parallax-item-container

  &__parallax-item-container {
    position: relative;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  // .scontrol__parallax-item-wrap

  &__parallax-item-wrap {
    position: sticky;
    z-index: 1;
    width: 341px;
    height: fit-content;
    padding-top: 120px;
    top: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
  }

  // .scontrol__parallax-item

  &__parallax-item {
    width: 341px;
    height: 400px;
    right: 0; bottom: 0; left: 0;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    position: absolute;
    transform: scale(0);
    opacity: 0;
    transition: opacity .2s;
    & .px-item {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    & .px-item-top {
      position: absolute;
      top: 0;
      z-index: 2;
    }
    &.active {
      position: relative;
      display: block;
      transform: scale(1);
      opacity: 1;
    }
  }

  // .scontrol__parallax-item--1

  &__parallax-item--1 {
  }

  // .scontrol__parallax-item--2

  &__parallax-item--2 {
  }

  // .scontrol__parallax-item--3

  &__parallax-item--3 {
  }

  // .scontrol__parallax-item--4

  &__parallax-item--4 {
  }

  // .scontrol__parallax-item--5

  &__parallax-item--5 {
  }

  // .scontrol__parallax-item--6

  &__parallax-item--6 {
    margin-top: 65px;
    height: 335px;
  }

  // .scontrol__parallax-item--7

  &__parallax-item--7 {
  }

  // .scontrol__parallax-item--8

  &__parallax-item--8 {
    height: 440px;
  }

  // .scontrol__parallax-item--9

  &__parallax-item--9 {
    height: 470px;
  }

  // .scontrol__parallax-item--10

  &__parallax-item--10 {
    height: 500px;
  }

  // .scontrol__parallax-item--11

  &__parallax-item--11 {
    height: 525px;
  }

  // .scontrol__parallax-item--12

  &__parallax-item--12 {
    height: 555px;
  }

  // .scontrol__parallax-item--13

  &__parallax-item--13 {
    height: 595px;
  }

  // .scontrol__parallax-item--14

  &__parallax-item--14 {
    height: 630px;
  }

  &__parallax-item--15 {
    height: 595px;
  }

  &__parallax-item--16 {
    height: 555px;
  }

  &__parallax-item--17 {
    height: 525px;
  }

  &__parallax-item--18 {
    height: 500px;
  }

  &__parallax-item--19 {
    height: 470px;
  }

  &__parallax-item--20 {
    height: 440px;
  }

  &__parallax-item--21 {

  }

  &__parallax-item--22 {
    margin-top: 65px;
    height: 335px;

  }

  &__parallax-item--23 {
    height: 400px;
    margin: 0 auto;
  }

  &__parallax-item--24 {

  }

  &__parallax-item--25 {

  }

  &__parallax-item--26 {

  }

  &__parallax-item--27 {

  }
}
.scontrol-header {
    position: relative;
    width: 100%;
    height: 820px;
    // .scontrol-header__bg


    &__images {
      display: flex;
    }
    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0; right: 0; bottom: 0; left: 0;
        margin: auto;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .scontrol-header__body

    &__body {
        max-width: 1050px;
        margin: 0 auto;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
    }

    // .scontrol-header__title

    &__title {
        font-weight: 700;
        font-size: 46px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: $primary;
        text-transform: uppercase;
        margin-top: 65px;
    }

    // .scontrol-header__subtitle

    &__subtitle {
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        margin-bottom: 50px;
    }

    // .scontrol-header__img

    &__img {
        max-width: 443px;
        height: auto;
        margin-bottom: 20px;
        & img {
            object-fit: cover;
            width: 100%;
            height: auto;
        }
    }

    // .scontrol-header__order-btn

    &__order-btn {
        margin-top: 20px;
    }
}
.btn-accent {
}
.container {
}
.scontrol-adv {
    padding: 90px 0 20px;
    // .scontrol-adv__title

    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 150%;
        color: #2C599D;
        margin-bottom: 65px;
        text-align: center;
    }

    // .scontrol-adv__body

    &__body {
        display: flex;
        justify-content: center;
    }

    // .scontrol-adv__roadmap-wrap

    &__roadmap-wrap {
        width: 61px;
        position: relative;
    }


    // .scontrol-adv__item

    &__item {
        // width: 716px;
        max-width: 716px;
        width: 45vw;
        height: 172px;
        padding: 20px;
        display: flex;
        top: 0;
        bottom: 0;
        right: 55px;
        margin: auto;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background: #FFFFFF;
        position: absolute;
        transition: .3s;
        transform: scaleY(0);
        &.active {
            overflow-x: visible;
            transform: scaleY(1);
        }
        &--right {
            right: auto;
            left: 55px;
            &::before {
                content: '';
                position: absolute;
                left: -27px;
                right: auto;
                width: 53px;
                height: 53px;
                background: #FFFFFF;
                transform: rotate(45deg);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            &::after {
                content: '';
                position: absolute;
                right: auto;
                left: 0;
                width: 50px;
                height: 100%;
                background: #FFFFFF;
                z-index: 1;
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: -27px;
            width: 53px;
            height: 53px;
            background: #FFFFFF;
            transform: rotate(45deg);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 50px;
            height: 100%;
            background: #FFFFFF;
            z-index: 1;
        }
    }

    // .scontrol-adv__item-content

    &__item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    // .scontrol-adv__item-ico

    &__item-ico {
        width: 92px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .scontrol-adv__item-list-wrap

    &__item-list-wrap {
        display: flex;
        align-items: center;
    }

    // .scontrol-adv__list

    &__list {
        max-width: 380px;
    }

    // .scontrol-adv__list-item

    &__list-item {
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        &::before {
            margin-right: 15px;
            height: fit-content;
        }
    }

    // .scontrol-adv__list-item--plus

    &__list-item--plus {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.61939 8.22539H3.61878H1.84398C1.25681 8.22539 0.928371 8.9021 1.29138 9.36251L1.29155 9.36272L8.24604 18.1733L8.24619 18.1735C8.77215 18.8391 9.77986 18.8367 10.3068 18.1749L10.3082 18.1732L22.7061 2.4625C22.7067 2.46173 22.7074 2.46095 22.708 2.46018C23.0789 1.99518 22.7346 1.32422 22.1565 1.32422H20.3817C19.9811 1.32422 19.5993 1.5076 19.351 1.8253C19.3507 1.82575 19.3503 1.8262 19.35 1.82666L9.27586 14.5885L4.64888 8.72578L4.25609 9.03516L4.64858 8.7254L4.64881 8.7257C4.52616 8.56999 4.36983 8.44409 4.19156 8.35744C4.01326 8.27078 3.81764 8.22563 3.61939 8.22539Z' fill='%2314A38B' stroke='%2314A38B'/%3E%3C/svg%3E%0A");
            transform: translateY(0px);
        }
    }

    // .scontrol-adv__list-item--minus

    &__list-item--minus {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='22' height='4' viewBox='0 0 22 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.1407 0.535156H1.85944C1.47157 0.535156 1.15631 0.85042 1.15631 1.23828V2.76172C1.15631 3.14958 1.47157 3.46484 1.85944 3.46484H20.1407C20.5285 3.46484 20.8438 3.14958 20.8438 2.76172V1.23828C20.8438 0.850421 20.5285 0.535156 20.1407 0.535156Z' fill='%23FF7171' stroke='%23FF7171'/%3E%3C/svg%3E%0A");
            transform: translateY(-3px);
        }
    }

    // .scontrol-adv__item-title

    &__item-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: $secondary;
        margin-left: 20px;
    }

    // .scontrol-adv__item--right

    &__item--right {
        & .scontrol-adv__item-title {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    // .scontrol-adv__roadmap

    &__roadmap {
        transform: translateX(1.5px);
    }

    // .scontrol-adv__car

    &__car {
        position: absolute;
        width: fit-content;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
    }
}
.px-item {
}
.px-item-top {
}
.scontrol-px-section {
    width: 100%;
    display: flex;



    &--top {
        & .scontrol-px-section__pic-section {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

        }
        & .scontrol-px-section__pic-section-quote {
            margin-left: 10%;
            margin-top: 70px;
        }
    }
    &--bottom {
        & .scontrol-px-section__pic-section {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
        & .scontrol-px-section__pic-section-quote {
            position: relative;
            margin-left: 10%;
            margin-bottom: 70px;
            font-size: 49px;
            text-transform: uppercase;
            color: $white;
            font-weight: 700;
        }
    }

    // .scontrol-px-section__pic-section

    &__pic-section {
        width: 50%;
        position: relative;
    }

  &__quote-img {
    position: absolute;

    &--start {
      top: 0;
      right: 0;
    }
  }



    // .scontrol-px-section__pic-section-bg

    &__pic-section-bg {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .scontrol-px-section__pic-section-quote

    &__pic-section-quote {
        position: relative;
        z-index: 0;
    }

    // .scontrol-px-section__info-section

    &__info-section {
        width: 50%;
        background: $primary;
    }

    // .scontrol-px-section__info-section-body

    &__info-section-body {
        width: 100%;
        max-width: 450px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 110px 0;
    }

    // .scontrol-px-section__mobile-img-top

    &__mobile-img-top{
        display: none;
    }

    // .scontrol-px-section__mobile-img-bottom

    &__mobile-img-bottom{
        display: none;
    }

    // .scontrol-px-section__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $white;
        margin-bottom: 48px;
        &--highlight {
            & span {
                color: #1FB25A;
                font-size: 28px;
                line-height: 150%;
            }
        }
    }

    // .scontrol-px-section__list-check

    &__list-check {
        margin-bottom: 60px;
        & .scontrol-px-section__list-item::before {
            content: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.1564 4.82422H21.3816C21.1328 4.82422 20.8967 4.93848 20.7443 5.13398L10.2758 18.3955L5.25603 12.0352C5.18009 11.9387 5.0833 11.8608 4.97292 11.8071C4.86254 11.7535 4.74145 11.7255 4.61872 11.7254H2.84392C2.6738 11.7254 2.57985 11.9209 2.68396 12.0529L9.63845 20.8635C9.96345 21.2748 10.5881 21.2748 10.9156 20.8635L23.3164 5.14922C23.4205 5.01973 23.3265 4.82422 23.1564 4.82422Z' fill='%23F88125'/%3E%3C/svg%3E%0A");
        }
    }

    // .scontrol-px-section__list-item

    &__list-item {
        font-size: 18px;
        line-height: 150%;
        color: $white;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            color: $white;
            margin-left: 0.3em;
        }
        &::before {
            align-self: flex-start;
            height: fit-content;
            transform: translateY(2px);
            margin-right: 10px;
        }
    }

    // .scontrol-px-section__buy-btn

    &__buy-btn {
        display: flex;
        align-items: center;
        width: fit-content;
        position: relative;
        z-index: 1;
    }

    // .scontrol-px-section__paragraph

    &__paragraph {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #FFF;
        margin-bottom: 53px;
    }

    // .scontrol-px-section__list-square

    &__list-square {
        & .scontrol-px-section__list-item {
            display: flex;
            align-items: center;
            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background: #FB9B51;
                transform: translateY(9px);
            }
        }
    }
}
.scontrol-px-adv {
    padding: 120px 0;
    display: flex;
    // .scontrol-px-adv__section

    &__section {
        width: 476px;
        height: fit-content;
    }

    // .scontrol-px-adv__wrap

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // .scontrol-px-adv__item

    &__item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 80px;
        }
    }

    // .scontrol-px-adv__item-ico

    &__item-ico {
        width: 61px;
        height: 61px;
        min-width: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
    }

    // .scontrol-px-adv__text

    &__text {
        font-size: 16px;
        line-height: 150%;
        color: #4F4F4F;
        & a {
            margin-left: 0.3em;
        }
    }

    // .scontrol-px-adv__text--link

    &__text--link {

    }
}
.scontrol-compare {
    margin-top: 160px;
    margin-bottom: 100px;

    // .scontrol-compare__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $primary;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 70px;
        & span {
            font-weight: 600;
            font-size: 28px;
            line-height: 150%;
            color: #1FB25A;

            margin-left: 0.3em;
        }
    }

    // .scontrol-compare__body

    &__body {
        max-width: 1075px;
        width: 100%;
        margin: 0 auto;
    }

    // .scontrol-compare__header

    &__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;
        overflow-x: hidden;
    }

    // .scontrol-compare__header-cell

    &__header-cell {
        //width: 33%;
    }

    // .scontrol-compare__product-img

    &__product-img {
        width: 260px;
        height: 190px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.scontrol-compare-table {
    width: 100%;
    // .scontrol-compare-table__item

    &__item {
        display: flex;
        padding: 12px;
        border-radius: 4px;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &:nth-child(even) {
            background: #E8E9EA;
        }
    }

    // .scontrol-compare-table__cell

    &__cell {
        width: 33%;
        padding-right: 30px;
        font-size: 16px;
        line-height: 150%;

        // .scontrol-compare-table-cell--link

        &--link {
            cursor: pointer;
            & .scontrol-compare-table__cell-val {
                font-weight: bold;
                color: #2C599D;
            }
        }
    }

    // .scontrol-compare-table__cell--title

    &__cell--title {
        font-weight: 600;
    }

    // .scontrol-compare-table__cell-name

    &__cell-name {
        display: none;
    }
}
.scontrol-buy-section {
    position: relative;
    height: 120px;
    & .container {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 1250px;
        height: 100%;
        z-index: 1;
    }

    // .scontrol-buy-section__bg

    &__bg {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .scontrol-buy-section__logo

    &__logo {
        margin-right: 50px;
        max-width: 225px;
        & img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    // .scontrol-buy-section__title

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        color: $white;
    }

    // .scontrol-buy-section__buy-btn

    &__buy-btn {
        margin-left: auto;
    }
}


@media only screen and (max-width: 1632px){
    .scontrol-px-section--top {
        & .scontrol-px-section__pic-section {
            padding-right: 175px;
        }
        & .scontrol-px-section__info-section {
            padding-left: 175px;
        }
    }
    .scontrol-px-section--bottom {
        & .scontrol-px-section__pic-section {
            padding-left: 175px;
        }
        & .scontrol-px-section__info-section {
            padding-right: 175px;
            padding-left: 10px;
        }
        & .scontrol-px-section__info-section-body {

        }
    }
}

@media only screen and (max-width: 1415px){
    .scontrol-px-adv {
        &__section {
            &:first-child {
                margin-right: 175px;
            }
            &:last-child {
                margin-left: 175px;
            }
        }
    }
}

@media only screen and (max-width: 1190px){
    .scontrol {
        &__advantage-ico {
            width: 48px;
            height: 48px;
            min-width: 48px;
        }
        &__advantage {
            padding: 0 18px;
        }
        &__advantage-text {
            font-size: 14px;
        }
    }
    .scontrol-adv {
        &__item-content {
            justify-content: flex-start;
        }
        &__item-title {
            margin-bottom: 10px;
        }
        &__item-ico {
            width: 52px;
            margin-right: 10px;
        }
        &__item {
            padding: 10px;
        }
        &__item--right {
            & .scontrol-adv__item-ico {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }
}

@media only screen and (max-width: 1160px){
    .scontrol {
        &__parallax-item-container-wrap {
            display: none;
        }
    }
    .scontrol-px-section {
        &--top {
            & .scontrol-px-section__pic-section {
                padding-right: 10px;
            }
            & .scontrol-px-section__info-section {
               padding: 0 20px;
            }
        }

        &--bottom {
            & .scontrol-px-section__pic-section {
                padding-left: 10px;
            }
            & .scontrol-px-section__info-section {
                padding-right: 10px;
            }
        }
    }
    .scontrol-px-adv {
        &__section {
            &:first-child {
                margin-right: 0;
            }
            &:last-child {
                margin-left: 0;
            }
        }
    }
}


@media only screen and (max-width: 1078px){
    .scontrol-adv{
        &__item-list-wrap {
            flex-direction: column-reverse;
        }
        &__item--right {
            & .scontrol-adv__item-list-wrap {
                flex-direction: column;
            }

        }
    }
}

@media only screen and (max-width: 920px){
    .scontrol {
        &__advantages-body {
            grid-template-columns: repeat(2, 48.5%);
            column-gap: 3%;
        }
        &__advantage {
            height: 110px;
        }
        &__advantage-ico {
            margin-right: 5%;
        }
    }
}

@media only screen and (min-width: 881px){
    .scontrol-adv {
        &__circle {
            width: 16px;
            height: 16px;
            background: #FFFFFF;
            border: 2px solid #F88125;
            border-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1;
            &--signalization {
                top: 100px;
            }
            &--casco {
                top: 200px;
            }
            &--gps {
                top: 300px;
            }
        }
    }

}
@media only screen and (max-width: 880px){
    .scontrol-header {
        height: 620px;
        &__title {
            font-size: 24px;
            letter-spacing: 0.05em;
            margin-top: 90px;
        }
        &__subtitle {
            font-size: 16px;
        }
        &__img {
            width: 380px;
        }
    }
    .scontrol-adv {
        padding: 50px 0 20px;
        &__title {
            font-size: 20px;
            margin-bottom: 50px;
        }
        &__roadmap-wrap {
            width: 100%;
        }
        &__item {
            transform: scaleY(1);
            position: static;
            height: fit-content;
            margin-bottom: 24px;
            padding: 20px !important;
            max-width: 550px;
            width: 100%;
            &::before, &::after {
                display: none;
            }
        }
        &__item-content {
            flex-direction: column;
        }
        &__roadmap {
            display: none;
        }
        &__car {
            display: none;
        }
        &__item--right {
            & .scontrol-adv__item-content {
                flex-direction: column-reverse;
            }
        }
        &__item-title {
            margin: 0 0 10px !important;
        }
        &__item-ico {
            margin: 0 0 10px !important;
        }
        &__list-item {
            font-size: 12px;
            margin-bottom: 7px !important;
        }
        &__list-item--plus {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8428 0H14.4723C14.2801 0 14.0978 0.0908596 13.9802 0.246331L5.89616 10.7921L2.01984 5.73425C1.96121 5.65759 1.88646 5.59559 1.80123 5.55293C1.71599 5.51027 1.62248 5.48804 1.52771 5.48792H0.157174C0.0258064 5.48792 -0.0467398 5.6434 0.0336492 5.74839L5.40402 12.7547C5.65499 13.0818 6.13733 13.0818 6.39026 12.7547L15.9664 0.258445C16.0467 0.155471 15.9742 0 15.8428 0Z' fill='%2314A38B'/%3E%3C/svg%3E%0A");
                margin-right: 5px;
            }
        }
        &__list-item--minus {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='13' height='2' viewBox='0 0 13 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8587 0H0.141304C0.063587 0 0 0.0947368 0 0.210526V1.78947C0 1.90526 0.063587 2 0.141304 2H12.8587C12.9364 2 13 1.90526 13 1.78947V0.210526C13 0.0947368 12.9364 0 12.8587 0Z' fill='%23FF7171'/%3E%3C/svg%3E%0A");
                margin-right: 5px;
            }
        }
    }
    .scontrol-px-adv {
        padding: 80px 0 50px 0;
        &__item {
            margin-bottom: 30px;
        }
        &__text {
            font-size: 14px;
        }
        &__item-ico {
            width: 40px;
            height: 40px;
            min-width: 40px;
        }
    }
    .scontrol-px-section {
        &__mobile-img-top, &__mobile-img-bottom {
            display: flex;
            width: fit-content;
            margin-bottom: 40px;
        }
        &__pic-section {
            display: none !important;
        }
        &__info-section {
            width: 100%;
        }
        &__info-section-body {
            align-items: center;
            padding: 45px 0 40px;
        }
        &__list-check {
            margin-bottom: 40px;
        }
        &__title {
            font-size: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
        &__list-item {
            font-size: 14px;
            margin-bottom: 18px;
            & span {
                font-size: 14px;
            }
        }
        &__paragraph {
            font-size: 14px;
            text-align: center;
            margin-bottom: 20px;
        }
        &__list-square {
            & .scontrol-px-section__list-item {
                &::before {
                    transform: translateY(6px);
                }
            }
        }
    }
    .scontrol-compare {
        margin: 50px 0 26px;
        &__title {
            font-size: 20px;
            margin-bottom: 40px;
            & span {
                font-size: 20px;
            }
        }
    }
    .scontrol-compare-table {
        &__cell {
            font-size: 14px;
            padding-right: 10px;
        }
    }
    .scontrol-buy-section {
        &__title {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: 700px){
    .scontrol {
        &__advantage {
            height: 85px;
        }
        &__advantage-ico {
            width: 38px;
            height: 38px;
            min-width: 38px;
        }
        &__advantage-text {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 630px){
    .scontrol-compare {
        &__header {
            display: none !important;
        }
    }
    .scontrol-compare-table {
        &__item {
            flex-direction: column;
            align-items: flex-start;
        }
        &__cell {
            width: 100%;
            margin-bottom: 4px;
        }
        &__cell-name {
            display: block;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #9C9C9C;
        }
        &__cell-val {
            font-size: 14px;
        }
    }
    .scontrol-buy-section {
        height: fit-content;
        & .container {
            flex-direction: column;
            align-items: center;
            padding: 26px 32px;
        }
        &__logo {
            margin-right: 0;
            margin-bottom: 27px;

        }
        &__buy-btn {
            margin-left: 0;
        }
        &__title {
            margin-bottom: 28px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 580px){
    .scontrol-px-adv {
        &__wrap {
            flex-direction: column;
        }
        &__item {
            width: 100%;
            margin-bottom: 38px !important;
        }
        &__section {
            width: 100%;
            padding: 0 15px;
        }
    }
}
@media only screen and (max-width: 552px){
    .scontrol {
        &__advantage {
            justify-content: flex-start;
            margin-bottom: 8px;
        }
        &__advantages-body {
            grid-template-columns: 1fr;
            column-gap: 3%;
        }
        &__advantage-ico {
            margin-right: 25px;
        }
        &__advantage-text {
            max-width: 500px;
        }

        & .btn-accent {
            height: 51px;
        }
    }
}
@media only screen and (max-width: 520px){
    .scontrol-header {
        &__img {
            width: 277px;
        }
    }
    .scontrol {
        &__advantages {
            padding-top: 0;
        }
    }
}

@media only screen and (max-width: 354px){
    .scontrol-px-section {
        &__list-square {
            & .scontrol-px-section__list-item {
                font-size: 12px;
                &::before {
                    transform: translateY(3px);
                }
                & span {
                    font-size: 12px;
                }
            }
        }
    }
}
