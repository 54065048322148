@import '../../style/vars';

.solution-choose {
  margin: 70px 0;

  // .solution-choose__sections-wrap

  &__sections-wrap {
    width: 100%;
  }

  // .solution-choose__section

  &__section {
    display: none;
    &.active{
      display: block;
    }
  }

  // .solution-choose__title

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: $primary;
    text-align: center;
    margin-bottom: 60px;
  }

  // .solution-choose__body

  &__body {
    display: grid;
    column-gap: 2%;
    grid-template-columns: repeat(3, 32%);
  }


  // .solution-choose__next-btn

  &__next-btn {
    border-radius: 10px;
    width: 283px;
    height: 44px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    &::before{
      border-radius: 10px;
    }
  }

  // .solution-choose__submit-btn

  &__submit-btn {
    margin: 20px auto 0;
    display: flex;
  }
}
.sc-transport-item {
  position: relative;
  cursor: pointer;
  transition: .3s;
  &.active{
    background: $secondary;
    box-shadow: none;
    & .sc-transport-item__title{
      color: $white;
    }
    & .sc-transport-item__img{
      filter: brightness(100);
    }
  }
  & .checkbox-wrap{
    position: absolute;
    top: 18px;
    right: 18px;
  }

  // .sc-transport-item__img-wrap

  &__img-wrap {
    position: relative;
  }

  // .sc-transport-item__img-wrap--standard-alt

  &__img-wrap--standard-alt {
    position: absolute;
  }

  // .sc-transport-item__title

  &__title {
    transition: .3s;
  }

  // .sc-transport-item__img-wrap--plane-alt

  &__img-wrap--plane-alt {
    position: absolute;
  }
}

.task {
  background: #FFFFFF;
  box-shadow: 0 0 16px #DBE2EA;
  border-radius: 8px;
  // max-width: 490px;
  height: 86px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 14px;
  margin-bottom: 6%;
  cursor: pointer;
  &.active{
    & .task__name{
      color: $primary;
    }
  }
  & .checkbox-wrap {
    margin-left: auto;
    & .checkbox{
      img{
        width: 14px;
        height: 10px;
        top: 12px;
        left: 9px;
      }
      &::before{
        width: 32px;
        height: 32px;
        margin-right: 0;
      }
    }
  }

  // .task__ico

  &__ico {
    width: 57px;
    height: 57px;
    min-height: 57px;
    min-width: 57px;
    margin-right: 30px;
  }

  // .task__name

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    max-width: 320px;
    width: 100%;
    margin-right: 8px;
  }
}



@media only screen and (max-width: 1370px){
  .solution-choose{
    &__body{
      column-gap: 3%;
      grid-template-columns: repeat(2, 48.5%);
    }
  }
}

@media only screen and (max-width: 976px){
  .task {
    &__name {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 880px){
  .solution-choose{
    margin: 50px 0;
    &__title{
      font-size: 18px;
      margin-bottom: 36px;
    }
    &__submit-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 276px;
      height: 44px;
      border-radius: 10px;
      &::before{
        border-radius: 10px;
      }
    }
  }
  .task {
    padding: 0 17px 0 7px;
    height: 60px;
    &__name {
      font-size: 14px !important;
    }
    &__ico {
      height: 32px;
      width: 32px;
      min-width: 32px;
      min-height: 32px;
      margin-right: 14px;
      & img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media only screen and (max-width: 720px){
  .solution-choose{
    &__body{
      grid-template-columns: repeat(2, 48.5%) !important;
      column-gap: 3% !important;
    }
  }
}

@media only screen and (max-width: 685px){
  .solution-choose__body--tasks {
    grid-template-columns: 1fr !important;
    max-width: 500px;
    margin: 0 auto;
    & .task{
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 536px){
  .solution-choose{
    &__body{
      grid-template-columns: 100% !important;
    }
  }
}
