@import '../../style/vars';
@import '../../style/mixins';

.support {
    margin: 70px auto;
    max-width: 1270px;

    & .textarea-wrap {
        height: 100%;
    }

    & .form__textarea {
        height: 100%;
    }

    // .support__main-title

    & .field-title {
        top: 18px;
    }

    & .form__input {
        &:focus ~ .field-title {
            top: 1px;
        }

        &:not(:placeholder-shown) ~ .field-title {
            top: 1px;
        }
    }

    &__main-title {
        @include title;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    // .support__text-container

    &__text-container {
        margin-bottom: 70px;
        & .support__paragraph{
            margin-bottom: 20px;
        }
    }

    // .support__paragraph

    &__paragraph {
        @include main-text;
        margin-bottom: 20px;
    }

    // .support__list

    &__list {
        @include main-text;
        margin-bottom: 20px;
    }

    // .support__list-item

    &__list-item {
        margin-bottom: 6px;
    }

    // .support__content-wrap

    &__content-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 70px;
        margin-bottom: 30px;
        & .support__section-title{
            margin-bottom: 40px;
            width: 100%;
        }
    }

    // .support__info-wrap

    &__info-wrap {
        width: 40%;
    }

    // .support__section-title

    &__section-title {
        @include description;
        color: $secondary;
        margin-bottom: 60px;
    }

    // .support__info-title

    &__info-title {
        @include button-text;
        margin-bottom: 15px;
    }

    // .support__info-list

    &__info-list {
        margin-bottom: 16px;
    }

    // .support__info-li

    &__info-li {
        &:not(:last-child){
            margin-bottom: 12px;
        }
    }

    // .support__li-title

    &__li-title {
        @include main-text;
        position: relative;
        padding-left: 28px;
        &::before{
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 3px;
            left: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
            background-size: 18px 18px;
            margin-right: 10px;
        }
    }

    // .support__li-link

    &__li-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $additional;

        &--large {
            font-size: 22px;
        }
    }

    // .support__li-subtitle

    &__li-subtitle {
        @include main-text;
        padding-left: 28px;
    }

    &__li-qr-codes {
        display: flex;
        justify-content: space-evenly;
    }

    &__li-qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__request-form-wrapper {
        width: 55%;
    }

    // .support__request-form

    &__request-form {
        background: #FFFFFF;
        box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
        border-radius: 8px;
        padding: 40px 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & .support__section-title{
            text-align: center;
        }
    }

    // .support__form-wrap

    &__form-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 196px;
    }
    &__form-wrap--left{
        width: 45%;
    }
    &__form-wrap--right{
        width: 50%;
    }

    // .support__submit-btn

    &__submit-btn {
        margin: 0 auto;
        background: $secondary;
        color: $white;
        @include button-text;
        border-radius: 5px;
        height: 51px;
        width: 207px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        &::before{
            border-color: $secondary;
        }
    }

    // .support__tabs-wrap

    &__tabs-wrap {
        overflow: hidden;
    }

    // .support__tabs

    &__tabs {

    }

    // .support__tab-wrap

    &__tab-wrap {
        max-height: 1200px;
        border-bottom: 1px solid $muted;
        &:first-child{
            border-top: 1px solid $muted;
        }
    }

    // .support__tab

    &__tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 30px;
        border-radius: 8px;
        transition: .3s;
        cursor: pointer;
        &.active{
            background: $tint;
            & + .support__tab-drop{
                height: fit-content;
                padding: 40px 0;
                transform: translateX(0);
                transition: .3s;
            }
            & .support__tab-title{
                font-weight: 600;
                color: $primary;
            }
        }
    }

    // .support__tab-title

    &__tab-title {
        @include main-text;
    }

    // .support__tab-drop

    &__tab-drop {
        height: 0;
        max-height: 1200px;
        overflow: hidden;
        transform: translateX(-100%);
        transition: .5s ease-in;
    }

    // .support__drop-p

    &__drop-p {
        @include main-text;
    }

    // .support__drop-list

    &__drop-list {
        margin: 28px 0;
    }

    // .support__drop-li

    &__drop-li {
        @include main-text;
        &:not(:last-child){
            margin-bottom: 5px;
        }
        &:before{
            content: '•';
            color: $accent;
            font-size: 16px;
            transform: translateY(-1px);
            display: inline-block;
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width: 1276px){
    .support{
        &__content-wrap{
            flex-direction: column;
        }

        &__request-form-wrapper {
          margin-top: 20px;
          width: 100%;
        }

        &__info-wrap, &__request-form{
            width: 100%;
        }
    }
}
@media only screen and (max-width: 880px){
    .support{
        margin: 40px 0;
        &__main-title, &__text-container{
            margin-bottom: 40px;
        }
        &__section-title{
            margin-bottom: 20px !important;
        }
        &__info{
            margin-bottom: 20px;
        }
        &__request-form{
            box-shadow: 0 1px 20px rgba(44, 39, 56, 0.05);
        }
        .support__content-wrap{
            padding-bottom: 40px;
        }
        &__request-form{
            padding: 30px 20px;
        }
        &__li-link{
            font-size: 14px;
        }
        & .file-upload-btn{
            height: 100%;
        }
    }
}

@media only screen and (max-width: 655px){
    .support{
        &__form-wrap--left{
            width: 100%;
        }
        &__form-wrap--right{
            width: 100%;
            margin-top: 20px;
        }
    }
}

//add
