// px to rem
@function rem($pixels, $context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

@mixin size($width,$height: $width) {
  width: $width;
  height: $height;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin flex-center {
  justify-content: center;
  align-items: center;
}

@mixin h1 {
  @media (min-width: 768px) {
    font-weight: 800;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 130%;
  }
}
@mixin h2-bnr {
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
@mixin title {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  @media (max-width: 880px) {
    font-size: 24px;
  }
}
@mixin h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: 0.02em;
}
@mixin subtitle {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
  @media (max-width: 880px) {
    font-size: 20px;
  }
}
@mixin description {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  @media (max-width: 880px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-transform: uppercase;
  }
}
@mixin card-title {
  @media (min-width: 880px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
  }
  @media (max-width: 880px) {
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
  }
}
@mixin button-text {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19.4px;
  }
  @media (max-width: 880px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.9px;
  }
}

@mixin main-text {
  @media (min-width: 880px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }
  @media (max-width: 880px) {
    font-size: 14px;
    line-height: 150%;
  }
}
@mixin secondary-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}

@mixin small-text {
  font-weight: normal;
  font-size: 11px;
  line-height: 120%;
}

@mixin input-textfield-normal{
  background: $white;
  border: 1px solid #DBE2EA;
  box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 6px;
  padding: 9px 22px 9px 8px;
  font-size: 11px;
  line-height: 120%;
  color: $secondary-text-color;
}


@mixin scroll-style {
  &::-webkit-scrollbar-button {
    width: 6px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #DBE2EA;
    box-shadow: 0 0 3px #DBE2EA inset;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #1A3B70;
    box-shadow: 0 1px 1px #fff inset;
  }

  &::-webkit-resizer {
    width: 4px;
    height: 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
    transform: translateX(20px);
  }
}

  @mixin adaptive-font($maxSize, $minSize) {
    $size: $maxSize - $minSize;
    $mobSize: $size + $size * 0.7;
    font-size: calc(#{$minSize + px} + #{$size} * (100vw / 1920));
  }