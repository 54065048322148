@import '../../style/vars';
@import '../../style/mixins';

.product-page {
    & .product-slider-wrap {
        padding-top: 60px;
    }
}

.implementions {
    padding-bottom: 90px;
    border-bottom: 1px solid #8D8D8D;
    border-top: 1px solid #8D8D8D;
    // .implementions__title

    &__title {
        margin: 52px 0 60px;
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $secondary;
    }

    // .implementions__slider

    &__slider {
        & .slick-slide > div {
            display: flex;
            justify-content: center;
        }
        & .slick-track {
            margin-left: 0 !important;
        }
        & .main-sl-dots {
            bottom: -24px;
            & li:not(.slick-active){
                & button {
                    background: $primary;
                }
            }
        }
    }

    & .cases__item-wrap {
        max-width: 360px;
        width: 100%;
    }
}

.product-description {
    border-top: 1px solid $secondary-text-color;
    // .product-description__wrap

    &__wrap {
        margin: 70px 0 0;
        @include main-text;
        color: $text-color;
    }

    // .product-description__title

    &__title {
    }

    // .product-description__content

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    // .product-description__main-section

    &__main-section {
        width: 60%;
        & li {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
                margin-right: 8px;
            }
        }
        & p, h4 {
            margin-bottom: 8px;
        }

        & li {
            margin-bottom: 4px;
        }
    }

    // .product-description__text

    &__text {
        &:last-child{
            margin-top: 30px;
        }
    }

    // .product-description__secondary-section

    &__secondary-section {
        width: 36%;
        & p {
            font-weight: 500;
            margin-bottom: 8px;
        }
        & li {
            margin-bottom: 4px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
                margin-right: 8px;
            }
        }
    }

    // .product-description__subtitle

    &__subtitle{
        margin-bottom: 30px;
    }

    // .product-description__list

    &__list {
    }

    // .product-description__list-item

    &__list-item {
        display: flex;
        align-items: flex-end;
        height: 24px;
    }

    // .product-description__list-ico

    &__list-ico {
        margin-right: 8px;
        display: none;
    }

    // .product-description__list-text

    &__list-text{

    }
}

@media only screen and (max-width: 880px){
    .product-description{
        &__wrap{
            margin: 40px 0;
        }
    }
    .section-title{
        margin-bottom: 24px;
    }
}
@media only screen and (max-width: 760px){
    .product-description{
        &__content{
            flex-direction: column;
        }
        &__main-section, &__secondary-section{
            width: 100%;
        }
    }
}

.product-set {
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 0 0 30px rgba(219, 226, 234, 0.7);
    border-radius: 8px;
    margin-bottom: 100px;

    // .product-set__header

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 87px;
        padding-top: 6px;
    }

    // .product-set__set-text

    &__set-text {
        @include description;
        text-transform: uppercase;
        color: $primary;
        margin-right: 16px;
    }

    // .product-set__set-name

    &__set-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $additional;
    }

    // .product-set__set-number

    &__set-number {
        position: absolute;
        display: block;
        top: 6px;
        right: 36px;
        bottom: 0;
        margin: auto 0;
        height: fit-content;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $secondary-text-color;
    }

    // .product-set__slider

    &__slider {
    }

    // .product-set__slide-item

    &__slide-item {
        max-width: 1270px;
        margin: 0 auto;
    }

    // .product-set__slide-main

    &__slide-main {
        display: flex;
        margin-top: 36px;
        align-items: center;
        justify-content: space-between;
    }

    // .product-set__slide-card

    &__slide-card {
        position: relative;
        display: flex;
        padding: 21px;
        width: 550px;
        max-width: 550px;
        height: 171px;
        background: $white;
        border: 1px solid $muted;
        border-radius: 8px;
    }

    // .product-set__slide-card--service

    &__slide-card--service{
        flex-direction: column;
        padding: 30px 59px;
    }

    // .product-set__current-product-label

    &__current-product-label {
        position: absolute;
        right: 16px;
        top: 16px;
        font-size: 14px;
        line-height: 150%;
        color: $secondary-text-color;
    }

    // .product-set__card-content

    &__card-content {
        display: flex;
        align-items: center;
    }

    // .product-set__card-img

    &__card-img {
        width: 138px;
        height: auto;
        margin-right: 20px;
        & img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    // .product-set__card-info

    &__card-info {
    }

    // .product-set__card-name

    &__card-name {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: $additional;
    }

    // .product-set__card-price

    &__card-price {
        @include button-text;
        color: $text-color;
    }

    // .product-set__plus-ico

    &__plus-ico {
    }

    // .product-set__card-title

    &__card-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: $additional;
        margin-bottom: 10px;
    }

    // .product-set__card-info
    &__card-info{
        margin-bottom: 9px;
    }


    // .product-set__card-text-item

    &__card-text-item {
        font-size: 14px;
        line-height: 17px;
        color: $text-color;
    }

    // .product-set__slide-footer

    &__slide-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 30px 23px 36px 0;
    }

    // .product-set__total-price

    &__total-price {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #000;
        margin-right: 82px;
    }

    .total-price {
        // .total-price__title

        &__title {
        }

        // .total-price__old

        &__old {
            font-weight: 400;
            color: $secondary-text-color;
            text-decoration-line: line-through;
            margin: 0 10px;
        }

        // .total-price__new

        &__new {
            color: $secondary;
        }
    }
    // .product-set__buy-btn

    &__buy-btn {
    }

    & .slider__button-sec{
        top: 0;
    }
    & .slider__next-sec{
        right: 0;
    }
    & .slider__prev-sec{
        left: 0;
    }
}
@media only screen and (max-width: 1500px){
    .product-set{
        &__slide-item{
            width: 90%;
        }
        &__slide-card{
            width: 46%;
        }
    }
}

@media only screen and (max-width: 1200px){
    .product-set{
        & .slider__button-sec{
            top: 0;
        }
        & .slider__next-sec{
            right: 0;
        }
        & .slider__prev-sec{
            left: 0;
        }
    }
}

@media only screen and (max-width: 990px){
    .product-set{
        &__header{
            justify-content: flex-start;
            padding-left: 28px;
        }
    }
}
@media only screen and (max-width: 880px){
    .product-set{
        margin-bottom: 40px;
        & .slider__button-sec{
            width: 32px;
            height: 32px;
            & svg{
                width: 12px;
                height: auto;
            }
        }
        &__header{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            height: fit-content;
            padding-bottom: 12px;
            padding-top: 10px;
            padding-right: 28px;
        }
        &__set-text{
            margin: 0;
            text-align: center;
            width: 100%;
            margin-bottom: 6px;
        }
        &__set-name{
            font-size: 14px;
            width: 80%;
            text-align: left;
        }
        &__set-number{
            font-weight: normal;
            font-size: 11px;
            line-height: 120%;
            position: static;
            width: 20%;
            text-align: right;
            transform: translate(0px, 2px);
        }
        &__slide-main{
            flex-direction: column;
        }
        &__current-product-label{
            font-size: 14px;
            right: 0;
            left: 0;
            text-align: center;
        }
        &__card-name{
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 10px;
        }
        &__card-price{
            font-size: 14px;
            line-height: 17px;
        }
        &__card-text-item{
            font-size: 11px;
            line-height: 120%;
        }
        &__plus-ico{
            margin: 12px 0;
        }
        &__slide-card{
            width: 80%;
            justify-content: center;
            border: none;
            padding: 0;
            height: fit-content;
            flex-direction: column;
            align-items: center;
        }
        &__card-title{
            text-align: center;
        }
        &__card-info{
            display: flex;
            flex-direction: column;
            align-self: center;
        }
        &__slide-card--service{
            & .product-set__card-price{
                text-align: center;
                transform: translateX(50px);
            }
        }
        &__total-price{
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 0;
            margin-bottom: 10px;
            font-size: 18px;
            .total-price__old{
                font-size: 16px;
                margin-left: auto;
            }
        }
        &__slide-main{
            margin-top: 20px;
        }
        &__current-product-label{
            position: static;
            text-align: center;
            width: 100%;
        }
        &__slide-footer{
            flex-direction: column;
            padding: 6px 8px 22px 8px;
        }
    }
}

@media only screen and (max-width: 450px){
    .product-set__buy-btn{
        width: 100%;
    }
}

.product-demo {
    display: flex;
    flex-direction: column;
    height: fit-content;
    // .product-demo__main-slider

    &__main-slider {
        width: 359px;
        margin-bottom: 40px;
    }

    // .product-demo__slide-item

    &__slide-item {

    }
    &__main-slider-img{
        width: 100%;
    }
    // .product-demo__thumbnail-slider

    &__thumbnail-slider {
        width: 359px;
        & .product-demo__slide-item{
            width: 100px !important;
            height: 100px;
            cursor: pointer;
            & .product-demo__main-slider-img{
                width: 100%;
                height: auto;
                object-fit: contain;
                border: 1px solid #DBE2EA;
            }
        }
        & .slick-current{
            & .product-demo__slide-item{
                border: 1px solid #5C83C4;
            }
        }
    }
    // .product-demo__slider-counter-wrap

    &__slider-counter-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
    }

    // .product-demo__slider-line

    &__slider-line {
        width: 80px;
        height: 1px;
        border-bottom: 1px solid $shade;
        margin-right: 20px;
    }

    // .product-demo__slider-counter

    &__slider-counter {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    // .product-demo__slider-current-slide

    &__slider-current-slide {
        color: #5C83C4;
    }

    // .product-demo__slider-total-amount

    &__slider-total-amount {
        color: #DBE2EA;
    }
}

.product-is {
    padding-bottom: 100px;
    // .product-is__title

    &__title {
        @include h2;
        color: $secondary;
        margin: 52px 0 60px;
    }

    // .product-is__body

    &__body {
        display: flex;
        justify-content: space-between;
    }

    // .product-is__item

    &__item {
        width: 32%;
    }

    // .product-is__img-wrap

    &__img-wrap {
        width: 100%;
        max-height: 252px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 20px;
        & img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    // .product-is__item-title

    &__item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: $text-color;
        margin-bottom: 20px;
    }

    // .product-is__item-subtitle

    &__item-subtitle {
        @include main-text;
        color: $text-color;
        display: block;
        margin-bottom: 20px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    // .product-is__item-link

    &__item-link {
        color: $additional;
        font-size: 14px;
        line-height: 150%;
    }
}

@media only screen and (max-width: 880px){
    .product-is{
        margin-top: 60px;
        padding-bottom: 60px;
        &__title{
            font-size: 24px;
            margin-bottom: 50px;
        }
        &__item-title{
            font-size: 16px;
        }
    }
}
@media only screen and (max-width: 640px){
    .product-is{
        padding-bottom: 40px;
        &__body{
            flex-direction: column;
        }
        &__item{
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

.product-specifications {
    border-top: 1px solid $secondary-text-color;
    padding-top: 70px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8D8D8D;
    // .product-specifications__body

    &__body {
        width: 60%;
        margin-bottom: 30px;
    }

    // .product-specifications__spec-item

    &__spec-item {
        display: flex;
        padding: 15px 30px;
        border-radius: 8px;
        align-items: center;
        @include main-text;
        &:nth-child(even){
            background: $tint;
        }
    }

    // .product-specifications__spec-title

    &__spec-title {
        width: 30%;
        margin-right: 1%;
    }

    // .product-specifications__spec-text-sec

    &__spec-text-sec {
        width: 69%;
        word-wrap: break-word;
    }

    // .product-specifications__spec-text

    &__spec-text {
    }

    // .product-specifications__tecdoc

    &__tecdoc {
        width: 36%;
    }

    // .product-specifications__tecdoc-item

    &__tecdoc-item {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    // .product-specifications__tecdoc-text

    &__tecdoc-text {
        @include main-text;
        color: $text-color;
        width: max-content;
        background: $white;
        position: relative;
        z-index: 1;
    }

    // .product-specifications__tecdoc-line

    &__tecdoc-line {
        position: absolute;
        border-bottom: 1px solid #DCDCDC;
        left: 0;
        width: 93%;
        transform: translateY(5px);
    }

    // .product-specifications__tecdoc-link

    &__tecdoc-link {
        background: $white;
        position: relative;
        z-index: 1;
        min-width: 32px;
        min-height: 32px;
        & img{
            width: 100%;
            height: auto;
        }
    }
}

@media only screen and (max-width: 880px){
    .product-specifications{
        padding-top: 40px;
        &__spec-item{
            padding: 6px;
        }
    }
}
@media only screen and (max-width: 760px){
    .product-specifications{
        flex-direction: column;
        &__body, &__tecdoc{
            width: 100%;
        }
    }
}
@media only screen and (max-width: 562px){
    .product-specifications{
        &__spec-item{
            font-size: 11px;
            line-height: 120%;
        }
    }
}

.product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    // .product__features

    &__features {
        min-width: 68px;
        width: 68px;
        height: fit-content;
        overflow: visible;
        margin-right: 50px;
        transform: translateY(-10px);
        z-index: 1;
    }

    // .product__feature-ico

    &__feature-ico {
        margin-right: 8px;

        img {
            width: 48px;
            height: 48px;
            max-width: 48px;
            filter: grayscale(1) brightness(1);
        }
    }

    // .product__feature-body

    &__feature-body {
        //transition: opacity 0.2s;
        width: 250px;
        max-width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        opacity: 0;
        z-index: 1;
    }

    // .product__feature-item-wrap

    &__feature-item-wrap{
        position: relative;
        height: 69px;
    }

    // .product__feature-text

    &__feature-text {
    }

    // .product__feature-item

    &__feature-item {
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: $white;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 68px;
        height: 69px;
        padding: 0 20px;
        &:hover{
            box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
            z-index: 1;
            width: fit-content;
            & .product__feature-body {
                opacity: 1;
            }
            & .product__feature-ico img{
                filter: grayscale(0);
            }
        }
    }
    // .product__feature-ico

    &__feature-ico {
    }

    // .product__feature-body

    &__feature-body {
        height: 100%;

    }

    // .product__feature-text

    &__feature-text {
    }

    // .product__info-card

    &__info-card {
        width: 584px;
    }

    // .product__info-card-title

    &__info-card-title {
        @include title;
        font-size: 28px;
        color: $primary;
        margin-bottom: 20px;
    }

    // .product__info-card-subtitle

    &__info-card-subtitle {
        @include main-text;
        color: $text-color;
        margin-bottom: 55px;
        & p, h4 {
            margin-bottom: 8px;
        }
    }

    // .product__middle-wrap

    &__middle-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 360px;
        margin-bottom: 74px;
    }

    // .product__price-wrap

    &__price-wrap {

    }

    // .product__price-value

    &__price-value {
        font-weight: 600;
        color: $text-color;
        margin-bottom: 5px;
        & .product__price{
            font-size: 36px;
            line-height: 44px;
        }
        & .product__currency{
            line-height: 29px;
            font-size: 24px;
        }
    }


    // .product__bonus-value

    &__bonus-value {
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: $text-color;
    }

    // .product__quantity

    &__quantity {
        text-align: center;
    }

    // .product__quantity-title

    &__quantity-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: $text-color;
    }

    // .product__quantity-counter

    &__quantity-counter {
        width: 100px;
        height: 36px;
        background: $white;
        border: 1px solid $secondary;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 16px;
        line-height: 150%;
        color: $secondary;
        display: flex;
    }

    // .product__quantity-button

    &__quantity-button {
        width: 33.33%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .product__quantity-button--dec

    &__quantity-button--dec {
        cursor: pointer;
    }
    &__quantity-button--dec, &__quantity-button--inc{
        transition: .1s;
        user-select: none;
        &:hover, &:active{
            background: $secondary;
            color: $white;
        }
    }
    // .product__quantity-value

    &__quantity-value {
        text-align: center;
        color: $secondary;
    }

    // .product__quantity-button--inc

    &__quantity-button--inc {
        padding-bottom: 1.5px;
        cursor: pointer;
    }

    // .product__bottom-wrap

    &__bottom-wrap {
        max-width: 410px;
        width: 410px;
    }

    // .product__btn-wrap

    &__btn-wrap {
        display: flex;
        margin-bottom: 35px;
    }

    // .product__buy-btn

    &__buy-btn {
        margin-right: 30px;
    }

    // .product__compare-btn

    &__compare-btn {
        background: none;
        cursor: pointer;

        &.active {
            path {
                fill: #F88125;
            }

            .product__compare-text {
                color: #F88125;
            }
        }
    }

    // .product__compare-ico

    &__compare-ico {
    }

    // .product__compare-text

    &__compare-text {
        font-size: 11px;
        line-height: 13px;
        color: $primary;
        margin-top: 4px;
    }

    // .product__proposal-request-btn

    &__proposal-request-btn {
        padding: 0;
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .product__buy-info

    &__buy-info {
        max-width: 360px;
        width: 360px;
        height: fit-content;
    }
}
.sim-ico {
}
.g-ico {
}
.ecu-ico {
}
.fuel-sensor-ico {
}
.tachograph-ico {
}
.networks-port-ico {
}
.btn-accent {
}
.btn-ghost-accent {
}
.buy-info {

    // .buy-info__section

    &__section {
        padding-bottom: 20px;
        &:not(:first-child){
            padding-top: 20px;
        }
        &:not(:last-child){
            border-bottom: 1px solid #bebebe;
        }
    }

    // .buy-info__row

    &__row {
        display: flex;
        width: 333px;
        justify-content: space-between;
        font-size: 14px;
        &:first-child{
            margin-bottom: 8px;
        }
    }

    // .buy-info__row-item

    &__row-item {
        line-height: 150%;
        color: $secondary;
        &:first-child{
            color: $text-color;
        }

        &:last-child {
            width: 80px;
        }
    }

    // .buy-info__title

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $text-color;
        margin-bottom: 10px;
    }

    // .buy-info__subtitle

    &__subtitle {
        @include secondary-text;
        margin-bottom: 8px;
        & span{
            font-weight: 600;
        }
    }

    &__link {
        @include secondary-text;
        color: #2C599D;
        font-weight: 600;
    }

    // .buy-info__question-btn

    &__question-btn {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1490px) {
    .product{
        align-items: center;
        & .product-demo{
            width: 270px;
            height: fit-content;
            &__main-slider{
                width: 100%;
                margin-bottom: 35px;
            }
            &__thumbnail-slider{
                width: 100%;
                & .product-demo__slide-item{
                    width: 75px !important;
                    height: 75px;
                }
            }
        }
        &__feature-item-wrap{
            height: 60px;
        }
        &__info-card{
            width: auto;
        }
        &__info-card-subtitle{
            margin-bottom: 35px;
            margin-right: 8px;
        }
        &__middle-wrap{
            margin-bottom: 55px;
        }
        &__bottom-wrap{
            max-width: 410px;
            width: auto;
        }
        &__short-info{
            align-items: center;
        }
        &__features{
            margin-right: 38px;
        }
    }
}
@media only screen and (max-width: 1240px) {
    .product{
        flex-wrap: wrap;
        justify-content: flex-start;
        &__info-card{
            width: calc(100% - 376px);
        }

    }
}
@media only screen and (min-width: 1100px) and (max-width: 1240px) {
    .product{
        &__buy-info{
            width: 100%;
            max-width: 100% !important;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            & .buy-info__section{
                display: flex;
                padding: 0 !important;
                flex-direction: column;
                border: none;
                & .buy-info__question-btn{
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px){
    .product{
        margin-bottom: 40px;
        &__buy-info{
            width: 75% !important;
            margin: 0 auto;
            margin-top: 40px;
        }
    }
}
@media only screen and (max-width: 880px){
    .product{
        &__info-card-title{
            font-size: 20px;
        }
        &__info-card-subtitle{
            font-size: 14px;
            line-height: 120%;
        }
        &__price, &__currency{
            font-size: 24px !important;
            line-height: 29px !important;
        }
        &__bonus-value{
            font-size: 11px;
            line-height: 120%;
        }
        &__quantity{
            display: none;
        }
        .buy-info{
            &__title{
                font-size: 14px;
            }
            &__row-item, &__subtitle{
                font-size: 11px;
                line-height: 120%;
            }
        }
        & .product-demo{
            // width: 50%;
            &__thumbnail-slider{
                // display: none;
            }
        }
    }
    .btn-accent, .btn-ghost-accent, .btn-ghost-secondary, .btn-secondary, .btn-medium-secondary {
        vertical-align: middle;
    }

    .product-btn {
        height: 29px;
    }
}
@media only screen and (max-width: 792px){
    .product{
        justify-content: center;
        position: relative;
        &__features{
            display: flex;
            position: relative;
            justify-content: space-evenly;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 30px;
            margin-right: 0;
        }
        &__feature-item-wrap{
            position: static;
            &:hover{
                .product__feature-ico{
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
        &__info-card-title{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
        }
        &__feature-item{
            position: static;
            padding: 0;
            background: none;
            &:hover{
                box-shadow: none;
            }
        }
        &__feature-body{
            position: absolute;
            height: fit-content;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            padding: 10px;
            font-size: 11px;
            line-height: 120%;
            min-height: 46px;
            top: 70px;
            left: 0;
            right: 0;
            margin: auto;
            bottom: auto;
        }
        &__feature-ico{
            position: relative;
            &::after{
                content: '';
                clip-path: polygon(50% 0%, 0 100%, 100% 100%);
                width: 14px;
                height: 14px;
                // border: 1px solid black;
                background: $white;
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
                opacity: 0;
            }
        }
        & .product-demo{
            width: 50%;
            &__thumbnail-slider{
                display: none;
            }
        }
        &__info-card{
            width: 100%;
        }
        .product-demo__main-slider{
            margin-top: 50px;
            & .slider__prev-sec{
                left: -30%;
            }
            & .slider__next-sec{
                right: -30%;
            }
            & .slider__button-sec{
                & svg{
                    width: 12px;
                    height: auto;
                }
            }
        }
        &__middle-wrap, &__info-card-subtitle{
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 460px){
    .product{
        &__feature-item{
            width: fit-content;
            height: fit-content;
        }
        &__feature-item-wrap{
            width: fit-content;
            height: fit-content;
            margin-bottom: 10px;
        }
        &__feature-ico{
            margin-right: 0;
            &::after{
                bottom: -14px;
            }
        }
        &__feature-image {
            height: 40px;
            width: 40px;
        }
        &__feature-body{
            top: 45px;
        }
        &__btn-wrap{
            position: relative;
            margin-bottom: 20px;
        }
        &__buy-btn{
            width: 100%;
            margin-right: 0;
        }
        &__compare-btn{
            position: absolute;
            right: 0;
            top: -63px;
        }
        &__buy-info{
            margin: 40px 0 0 0;
            width: 100% !important;
        }
        .buy-info__row{
            width: 100%;
        }
        .buy-info__section{
            padding-bottom: 10px;
        }
        .buy-info__section:not(:first-child) {
            padding-top: 10px;
        }
        .buy-info__question-btn{
            display: none;
        }
    }
}