@import '../../style/vars';
@import '../../style/mixins';

.catalog {
  &__catalog-empty {
    margin: 80px 0;
  }

  &__filter-empty {
    padding: 40px;
  }

  &__filter-empty-text {

  }

  .catalog-mobile {
    // .catalog-mobile__heading
    &__heading {
      width: 100%;
      display: none;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    // .catalog-mobile__filter-btn

    &__filter-btn {
      font-size: 14px;
      line-height: 17px;
      color: $primary;
      border: 1px solid $secondary;
      border-radius: 5px;
      background: $white;
      width: 128px;
      height: 30px;
    }

    // .catalog-mobile__filter-sort

    &__filter-sort {
      position: relative;
      z-index: 2;
    }

    // .catalog-mobile__sort-title

    &__sort-title {
      font-size: 14px;
      line-height: 17px;
      text-decoration: underline;
      color: $primary;
      padding-right: 10px;
    }

    // .catalog-mobile__sort-items

    &__sort-items {
      background: $white;
      box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
      border-radius: 4px;
      position: absolute;
      top: 20px;
      right: 0;
      padding: 7px 0;
      opacity: 0;
      z-index: 0;
      transition: 0.2s;

      &.active {
        opacity: 1;
        z-index: 2;
      }
    }

    // .catalog-mobile__sort-item

    &__sort-item {
      width: 130px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-text-color;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      transition: 0.2s;

      &:hover {
        background: $tint;
        color: $primary;
      }
    }
  }

  // .catalog__content

  &__content {
    display: flex;
    padding-bottom: 50px;
  }

  // .catalog__filter

  &__filter {

  }

  // .catalog__main

  &__main {
    width: 100%;
    padding-left: 18px;

    & .pagination-wrap {
      margin-bottom: 50px;
    }
  }


  // .catalog__section

  &__section {
    width: calc(100% - 360px);
    min-width: 10%;
  }

  // .catalog__heading

  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 2px 20px 0;
    margin-bottom: 30px;
  }

  // .catalog__header

  &__header {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: $primary;
  }

  // .catalog__heading-filter

  &__heading-filter {
    display: flex;
  }

  // .catalog__hfilter-title

  &__hfilter-title {
    @include card-title;
    margin-right: 50px;
  }

  // .catalog__hfilter-popular

  &__hfilter-popular {
    margin-right: 30px;
  }

  // .catalog__hfilter

  &__hfilter {
    @include main-text;
    color: $text-color;
    cursor: pointer;
    min-width: max-content;

    &.active {
      text-decoration-line: underline;
    }
  }

  // .catalog__hfilter--popular

  &__hfilter--popular {
    margin-right: 30px;
  }


  // .catalog__hfilter--price

  &__hfilter--price {
  }

  // .catalog__hfilter-arrow

  &__hfilter-arrow {
    transform: translateY(-2px);
    margin-left: 4px;
  }

  // .catalog__body

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 30px;
  }

  // .catalog__description

  &__description {
    font-size: 14px;
    line-height: 150%;
    color: $text-color;
    //width: calc(100% - 360px);
    margin-left: 20px;
    margin-bottom: 40px;


    & p {
      margin-bottom: 10px;
    }
  }
}

.filter {
  max-width: 360px;
  width: 360px;
  min-width: 360px;
  background: $white;
  border: 1px solid $shade;
  border-radius: 8px;
  margin-top: 10px;
  height: fit-content;

  // .filter__mobile-close-btn
  &__mobile-close-btn {
    display: none;
  }


  // .filter__header

  &__header {
    padding: 40px 40px 0 40px;
    display: flex;
    align-items: center;
  }

  // .filter__header-title

  &__header-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: $primary;
    margin-left: 18px;
  }

  // .filter__title

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $text-color;
    margin-bottom: 15px;
  }

  // .filter__price

  &__price {
    & .filter__accept-btn {
      margin: 50px auto 0 auto;
    }
  }

  // .filter__container

  &__container {
    padding: 30px 32px;

    &--boolean {
      display: flex;
      justify-content: space-between;
    }

    &:not(:last-child) {
      border-bottom: 0.5px solid $shade;
    }
  }

  // .filter__price-input-wrap

  &__price-input-wrap {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
  }

  // .filter__from-wrap

  &__from-wrap {
  }

  // .filter__to-wrap

  &__to-wrap {
  }


  &__to-wrap, &__from-wrap {
    & span {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $text-color;
      margin-right: 15px;
    }

    & input {
      border: 1px solid $tint;
      box-shadow: 1px 2px 10px rgba(235, 244, 248, 0.5);
      border-radius: 8px;
      background: #FFFFFF;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: $secondary;
      max-width: 75px;
      padding: 8px 4px;
      text-align: center;
    }
  }

  // .filter__range-input

  &__range-input {
    & #range-slider {
      width: 96.5%;
      height: 2px;
      background-color: $shade;
      border: none;
    }

  }

  // .filter__checkbox-section

  &__checkbox-section {
  }

  // .filter__form

  &__form {
    & .checkbox-wrap, & .radio-wrap {
      margin-bottom: 15px;
    }
  }

  // .filter__radio-section

  &__radio-section {
  }


}

.noUi-target {
  border: none !important;
}

.noUi-ltr {
}

.noUi-horizontal {
  height: 2px !important;

}

.noUi-txt-dir-ltr {
}

.noUi-base {
}

.noUi-connects {
}

.noUi-connect {
  background-color: $additional !important;
}

.noUi-origin {
}

.noUi-handle {
  background: none !important;
  border: none !important;
  height: auto !important;
  width: auto !important;
  box-shadow: none !important;
  transform: translateY(-20%);
  cursor: pointer !important;

  &::before, &::after {
    display: none !important;
  }
}

.noUi-touch-area {
  width: 21px !important;
  height: 21px !important;
  background-color: $additional;
  border-radius: 50%;
  border: 2px solid $white;
}

.noUi-handle-lower {
}

.noUi-touch-area {
}

.noUi-handle-upper {
}

.checkbox-wrap {
  color: $text-color;
  font-size: 16px;
  line-height: 150%;
}

.radio-wrap {
  color: $text-color;
  font-size: 16px;
  line-height: 150%;
}

.product-item {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  // .product-item__compare-button

  &__img-wrap {
    display: flex;
  }

  &__img {
    margin: auto;
    height: 180px;
    object-fit: contain;
  }

  &__compare-button {
    width: 30px;
    height: 30px;
    padding: 0;

    & svg {
      width: 100%;
      height: auto;
    }
  }

  // .product-item__info

  &__info {
    margin-top: 20px;
  }

  // .product-item__subtitle

  &__subtitle {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 48px;
  }

  // .product-item__buy-box

  &__buy-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    margin-bottom: 20px;
  }

  // .product-item__price

  &__price {
    margin-bottom: 0;
    margin-right: 4px;
    transition: .3s;
  }

  // .product-item__buy-btn

  &__buy-btn {
    position: absolute;
    right: 0;
    opacity: 0;
    padding: 0 0;
    width: 170px;
    height: 51px;
  }

  // .product-item__drop-content

  &__drop-content {
    position: absolute;
    left: 0;
    right: 0;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
    background-color: #FFFFFF;
    padding: 0 30px 30px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 40px 40px rgba(219, 226, 234, 0.7), 0 17.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 13.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 12.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 11.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 10.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
  }

  // .product-item__ico-box

  &__ico-box {
    display: flex;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 0.1s;

    & img {
      filter: grayscale(1);
    }
  }

  // .product-item__ico

  &__ico {
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }

  // .product-item__drop-descr

  &__drop-descr {
    @include secondary-text;
    color: $text-color;
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 0.15s;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}

.product-item-wrap--drop {
  cursor: pointer;
  position: relative;
  max-width: 360px;
  width: 100%;
  height: 100%;
  max-height: 497px;
  margin-bottom: 2.5%;
  //margin-right: 1.2%;
  margin-left: 0;

  &:hover {
    & .product-item {
      border-radius: 8px 8px 0 0;
    }
  }

  & .product-item-stock {
    position: absolute;
    left: 0;
    top: 20px;
    width: 110px;
    height: 30px;
    border-radius: 0 8px 8px 0;
    background: $accent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: $white;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
  }

  & .product-item__price {
    margin-bottom: 0;
  }

  // ..product-item__img

  &__img {
    width: auto;
    object-fit: contain;
    height: 100%;
  }

  &__img-wrap {
    height: 160px;
  }
}

@media only screen and (min-width: 1100px) {
  .product-item-wrap--drop:hover {
    z-index: 2;

    & .product-item__ico-box {
      margin-top: 20px;
      margin-bottom: 20px;
      opacity: 1;
    }

    & .product-item__drop-content {
      transform: scaleY(1);
      height: auto;
    }

    & .product-item__drop-descr {
      opacity: 1;
    }

    & .product-item__buy-btn {
      opacity: 1;
      display: block !important;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .product-item-wrap--drop {
    & .product-item {
      padding: 30px;

      &__compare-button {
        top: 15px;
        right: 15px;
      }

      &__buy-box {
        flex-wrap: nowrap;
      }

      &__img-wrap {
        width: 70%;
        margin: 0 auto;
      }

      &__buy-btn {
        width: 115px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .product-item-wrap--drop {
    & .product-item {
      &__buy-box {
        flex-wrap: wrap;
      }

      &__subtitle {
        margin-bottom: 20px;
      }
    }
  }

  .product-item {
    &__img {
      height: 120px;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .catalog {
    &__section {
      width: calc(100% - 270px);
      min-width: 10%;
    }
  }

  .filter {
    width: 270px;
    min-width: 270px;

    &__header {
      padding: 40px 20px 0 20px;
    }

    &__container {
      padding: 30px 20px;
    }

    &__price-input-wrap {
      & span {
        margin-right: 9px;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .filter__mobile-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
  }

  .catalog__description {
    margin-left: 0;
  }

  .catalog__filter.filter {
    overflow-y: scroll;
    transform: translateX(-600px);
    position: absolute;
    transition: .3s;
    z-index: 3;
    top: -10px;
    left: -10px;

    &.active {
      transform: translateX(0px);
    }

    &__header {
      padding: 26px 20px 0 20px !important;

      & svg {
        display: none;
      }
    }

    &__header-title {
      margin-left: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    &__mobile-close-btn {
      display: block;
    }

    &__container {
      padding: 15px 20px;
    }

    &__accept-btn {
      margin: 36px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 40px;
      font-size: 14px;
      line-height: 17px;
      padding: 0;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    &__price-input-wrap {
      & span {
        font-size: 14px;
      }

      & input {
        font-size: 12px;
      }
    }
  }
  .checkbox-wrap {
    font-size: 14px;
  }
  .radio-wrap {
    font-size: 14px;
  }
  .catalog {
    &__main {
      padding-left: 0;
    }

    &__section {
      width: 100% !important;
    }

    &__heading {
      flex-wrap: wrap;
    }

    & .catalog-mobile__heading {
      display: flex;
    }

    &__header {
      font-size: 16px;
      line-height: 150%;
    }

    &__heading-filter {
      display: none;
    }

    &__body {
      .product-item-wrap--drop {
        margin: 0 0 18px 0;
        width: 100%;

        & .product-item-stock {
          width: 93px;
          height: 25px;
          font-weight: normal;
          font-size: 11px;
          line-height: 120%;
        }

        .product-item {
          box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04) !important;

          &__subtitle {
            display: none;
          }

          &__title {
            text-align: center;
            font-size: 12px;
            line-height: 130%;
          }

          &__price {
            width: 100%;
            text-align: center;
            font-size: 12px;
            line-height: 150%;
          }

          &__compare-button {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .catalog {
    &__body {
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;
    }

    & .product-item-wrap--drop {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .catalog {
    &__body {
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;

      & .product-item {
        padding: 30px 5px !important;
      }
    }

    &__heading {
      padding: 0;
    }
  }

  .product-item {
    &__img {
      height: 85px;
    }
  }
}
