@import "../../style/mixins";

.partnership {
  background-color: #E5E5E5;
  &__page  {
    margin: 0 auto
  };

  &__formBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: url('../../images/partnership/tabletBackGround.jpg') no-repeat bottom center;
    background-size: 90% 95%;
    & > img {
      width: 50%;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    max-width: 425px;
    width: 100%;
  }

  &__formTitle {
    @include adaptive-font(24, 20);
    color: #fff;
    font-weight: 600;
    margin-bottom: 24px;
  }

  &__official {
    height: 100%;
    margin: 0 auto;
    padding: 60px 0;
    background: url('../../images/partnership/truck.jpg') no-repeat bottom center;
  }

  &__officialTitle {
    @include h2;
    @include adaptive-font(32, 20);
    text-transform: uppercase;
    text-align: center;
    color: #1A3B70;
    margin-bottom: 30px;
  }

  &__officialFlex {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__officialText {
    width: 45%;
    margin-left: 70px;
    @include adaptive-font(18, 16);

    & > p {
      margin-bottom: 20px;
    }
  }

  &__banerInfo {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 18px;
    padding: 10px;


    &--title {
      @include adaptive-font(24, 18);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      color: rgba(26, 59, 112, 1);
      width: 80%;
      margin: 0 auto;
    }

    & > .banner__btn {
      background-color: rgba(251, 155, 81, 1);
      width: 45%;
      padding: 15px 10px;
      margin: 0 auto;
    }

    & > .btn-secondary::before {
      border-color: rgba(251, 155, 81, 1);
    }
  }

  &__banner {
    background: #FFFFFF;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: space-between;

    & > img {
      width: 50%;
    }

  }

  &__skills {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    width: 80%;
    padding:  60px 0;
    .skill__item {
      display: flex;
      align-items: center;
      padding: 10px 35px;
      background: #FFFFFF;
      box-shadow: 0 0 16px #DBE2EA;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s all ease-in-out;
      margin-bottom:  10px;
     & > h3 {
       color: #1A3B70;
       font-weight: 600;
       @include adaptive-font(18, 16);

     }
      & > img {
        margin-right: 20px;
      }
        &:hover, &.active {
        background-color: #1A3B70;
        & > h3 {
          color: #fff;
        }
        & > img {
          filter: brightness(10);

        }
      }

    }
    .skill__list {
      position: relative;
    }
    .skills__title {
      font-weight: 600;
      @include adaptive-font(28, 20);

      color: #1A3B70;
      margin-bottom: 30px;
    }
    .skill__info {
      position: absolute;
      opacity: 0;
      top:  0;
      width: 50vw;
      left: 120%;
      transition: 0.3s all ease-in-out;
      & > h3 {
        font-weight: bold;
        @include adaptive-font(18, 16);
        line-height: 150%;
        color: #1A3B70;
        margin-bottom: 10px;
      }
      &  > p {
        font-weight: 500;
        @include adaptive-font(18, 16);
        line-height: 150%;
      }

      & > img {
        margin: 20px 0;
      }
    }
  }

  &__wialon {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    height: 100%;


    &--wrapper {
      background-image: url('../../images/partnership/mabBg.svg') ;
      background-size: 55%;
      background-position: 105% 50%;
      background-repeat: no-repeat;
      background-color: #1A3B70;
      padding: 80px;
    }
  }

  &__list {
    max-width: 500px;
    width: 100%;

    & > h3 {
      color: #fff;
      font-weight: 600;
      @include adaptive-font(28, 18);
      width: 100%;
      margin-bottom: 30px;
    }
    &  > p {
      @include adaptive-font(16, 12);
      color: #fff;

    }
  }
    &__wialon__radius {
     & > p {
      @include adaptive-font(16, 12);
       margin-top: 80px;
       margin-bottom: 20px;
      color: #fff;
     }
      & > img {
        width: 50%;
      }
    }
  &__montoring {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 60px 0;
    background-color: #E5E5E5;
  }
  &__monitoringText {
    width: 70%;
    text-align: center;
    margin-bottom: 40px;
    color: #000;
    font-weight: 500;
    @include adaptive-font(20, 18);

  }
  &__montoringCards {
    display: flex;
    margin: 0 auto;


  }
  &__montoringCard {
    max-width: 490px;
    max-height: 626px;
    border-radius: 40px;
    padding: 46px 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.light {
      background-color: #fff;
      color: #000000;
      margin-right: 50px;
    }
    &.dark {
      background-color: #1A3B70;
      & * {
        color: white;
        & > li:before {
            border-color: #fff;
        }
      }
    }
    & > img {
      max-width: 356px;
      margin-bottom: 16px;
    }

    & > .banner__btn {
      background-color: rgba(251, 155, 81, 1);
      width: 50%;
      margin: 0 auto;
    }

    & > .btn-secondary::before {
      border-color: rgba(251, 155, 81, 1);
    }
  }
  &__montoringCardText {
    @include adaptive-font(18, 15);
    line-height: 17px;
  }
  &__montoringCardList {
    margin-left: 40px;
    margin-bottom: 30px;
    & > li {
      @include adaptive-font(16, 14);

      color: #1A3B70;
      font-weight: 500;
      margin-bottom: 15px;
      position: relative;
      &:before {
        content: '\2713';
        position: absolute;
        font-size: 15px;
        text-align: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #1A3B70;
        color: inherit;
        left: -40px;
        transform: translateY(-50%);
        top: 50%;

      }


      &:first-child {
        margin-top: 16px;
      }
    }
  }
  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../../images/partnership/introbg.jpg') no-repeat center center;
    background-size: cover;
  }

  &__introTitle {
    align-items: center;
    display: flex;
    margin-top: 60px;
    text-align: center;
    @include adaptive-font(28, 18);

    font-weight: 100;
    & > p {
      line-height: 65px;
      @include adaptive-font(28, 18);
      color: #2C599D;
      font-family: Gilroy, sans-serif;
      letter-spacing: 29px;
    }
    & > span {
      color: red;
      @include adaptive-font(28, 18);
      letter-spacing: 0;
      &:first-child {
        letter-spacing: 29px;

      }
    }
  }
  &__logos {
    margin-top: 65px;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    & > div img {
      width: 355px;
    }
    .hrest {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__introBanner {
    border-radius: 10px;
    margin: 77px  auto 20px;
    width: 80%;
    background: url('../../images/partnership/introBanner.jpg') no-repeat center center ;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .btn-secondary {
      padding: 15px 0;
    }
  }
  &__introBannerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 220px;
    height: 100%;
    text-align: center;
    width: 32%;
    margin-right: 40px;
    padding: 20px 0;
    row-gap: 10px;
    & > h2 {
      font-weight: 600;
      @include adaptive-font(24, 16);
      line-height: 150%;
      color: #fff;
    }
    & >  p {
      font-weight: 600;
      color: #fff;
      @include adaptive-font(16, 14);
    }
    &  span {
      color: #fff;
      white-space: nowrap;
    }
    & > .banner__btn {
      background-color: rgba(251, 155, 81, 1);
      width: 265px;

    }

    & > .btn-secondary::before {
      border-color: rgba(251, 155, 81, 1);
    }
  }

  @media (max-width: 1440px) {
    &__form {
      padding: 0 15px;
    }
    &__formBlock {
      flex-direction: column;
      background-size: contain;
      background: none;
      margin: 0 auto;
      & > :first-child {
        background: url('../../images/partnership/tabletBackGround.jpg') no-repeat bottom center;
        width: 100%;
        & > img {
          margin: 0 auto;
          display: block;
          width:90%;
        }
      }
    }
    &__formTitle {
      color: #1A3B70;
      text-align: center;
      margin-top:  20px;
    }
    &__skills .skill__info {
      left: 105%;
      width: 45vw;
    }
    &__introBanner {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    &__introBannerInfo {
      width: 40%;

    }

    &__wialon {
      width: 90%;
      &--wrapper {
        background-position: 100% 80%;
      }
    }
  }

  @media (max-width: 1100px) {
    &__montoringCard > .banner__btn {
      width: 70%;
    }
    &__banner {
      & > img {
        width: 40%;
      }
    }
    &__officialFlex {
      flex-direction: column;
      & div {
        order: -1;
        width: 70%;
        margin: 0 auto;
      }
    }
    &__logos {
      & > div img {
        width: 200px;
      }
      .hrest {
        width: 40px;
      }
    }
    &__circle {
      width: 250px;
      height: 250px;
    }
    .skill__info {
      width: 100%;

    }
    &__skills .skill__info {
      height: 100%;
      position: static;
      display: none;
      text-align: center;
      width: 50vw;
      &.active  {
        display: block;
      }
    }
    &__skills {
      width: 100%;
    }
    .skill__block {
      position: relative;

    }
    .skill_item:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: red;
    }
    &__skills {
      justify-content: center;
      align-items: center;

    }
    &__officialFlex {
      padding: 0 12px;
      & > img {
        width: 50%;
      }
    }

    &__montoringCard.light {
      margin-right: 10px;

    }
    &__montoringCard.dark {
      margin-left: 10px;
    }
    &__montoringCard {
      padding: 20px 30px;
      max-width: 400px;
    }
     .banner__bt.btn-secondary {
      width: 100%;
    }
    &__introBanner {
      background: linear-gradient(357.77deg, #1A3B70 4.38%, rgba(0, 0, 0, 0) 66.35%), #1A3B70;
    }
    &__introBannerInfo {
      width: 45%;
      margin: 0 auto;
    }
  }
  @media (max-width: 880px) {
    &__skills {
      padding: 20px;
    }
    &__skills .skill__info {
      margin:  0 auto;
      text-align: left;
      width: 90%;
    }
    &__list {
      & > h3 {
        margin-top: 25px;
      }
    }
    &__banner {
      padding: 20px;
      & > img {
        display: none;
      }
    }

    &__wialon {
      padding: 20px 0;
      width: 100%;
      &--wrapper {
        background-image: none;
      }
      &__radius > img {
        width: 100%;
      }
    }
    &__montoringCards {
      flex-direction: column;
    }
    &__montoringCard.light {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &__montoringCard.dark {
      margin-left: 0;
    }
  }
  @media (max-width: 768px) {
    &__introTitle > p {
       letter-spacing: 10px;
      span {
        letter-spacing: 0;

      }
    }
    &__logos {
      width: 100%;

      &  > div img {
        width: 70%;
        max-width: 150px;
        margin: 0 auto;
        display: block;
      }
      .hrest {
        width: 30px;
      }
    }
    &__wialon {
      background: #1A3B70;
    }
    &__wialon--wrapper {
      padding: 30px;
    }
    &__list {
      width: 90%;
    }
    &__circles {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      & * .partnership__circle {
        margin-bottom: 20px;
      }
    }
    &__circle {

      &:nth-child(2) {
        margin: 0;
      }
    }
    &__introBannerInfo {
      width: 80%;
    }
    &__monitoringText {
      width: 90%;
    }
  }
  @media (max-width: 653px) {
    &__wialon__radius > p {
      margin-top: 30px;
    }
    &__monitoring {
      padding: 60px 12px;
    }
    &__montoringCards {
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &__montoringCard {
      width: 90%;
      height:  100%;
      max-height: 100%;
      padding: 20px 16px;
    }
    &__officialFlex > div {
      width: 95%;
      padding-bottom:  80px;
    }
    &__officialFlex img {
      width: 80%;
      margin:  0 auto;
    }
    &__introBannerInfo {
      width: 95%;
      & > .btn-secondary {
        padding: 16px 30px;
      }
    }
    &__introBanner {
      margin-top: 30px;
    }

   &__banner {
     width: 95%;
     padding: 12px;
     .btn-secondary {
       padding: 20px 30px;
       width: 265px;
     }
   }
    &__wialon {
        padding: 0;
      }
    &__logos {
      margin:  10px;
      & > span {
        &:before {
          height: 20px;
          top: 30%;
        }
        &:after {
          height: 20px;
          top: 30%;
        }
      }
    }
    &__wialon--wrapper {
      padding: 10px;
    }

  }
  @media (max-width: 320px) {
    &__introTitle > p {
      letter-spacing: 10px;
    }
  }
}