@import '../../style/vars';
@import '../../style/mixins';

.about-us {
  // .about-us__article

  & .marker {
    position: initial;
    transform: initial;
  }

  & .field-title {
    top: 16px;
  }

  & .form__input {
    &:focus ~ .field-title {
      top: 1px;
    }

    &:not(:placeholder-shown) ~ .field-title {
      top: 1px;
    }
  }



  &__article {
    margin-bottom: 100px;
  }

  // .about-us__article-img

  &__article-img {
    max-width: 615px;
    width: 100%;
    height: auto;
    float: left;
    margin-right: 30px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .about-us__article-p

  &__article-p {
    font-size: 16px;
    line-height: 150%;
    color: #000;
    margin-bottom: 1em;
  }

  // .about-us__slider-wrap

  &__slider-wrap {
  }

  // .about-us__slider

  &__slider {
  }
}



.about-us-header {
  position: relative;
  height: 576px;
  margin-bottom: 100px;
  &::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(248, 129, 37, 0.62) 0%, rgba(26, 59, 112, 0.76) 100%);
    mix-blend-mode: multiply;
  }

  // .about-us-header__bg

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .about-us-header__body

  &__body {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 1640px;
    margin: 0 auto;
    padding: 0 10px;
  }

  // .about-us-header__title

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 150%;
    margin-bottom: 26px;
    text-transform: uppercase;
    color: $white;
  }

  // .about-us-header__subtitle

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: $white;
  }
}




.container {
}
.about-us-adv {
  position: relative;
  height: 295px;
  margin-bottom: 70px;

  // .about-us-adv__bg

  &__bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(45, 50, 88, 0.9);
      mix-blend-mode: multiply;
    }
  }

  // .about-us-adv__body

  &__body {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  // .about-us-adv__item

  &__item {
    max-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  // .about-us-adv__title

  &__title {
    color: $white;
    text-align: center;
    font-weight: 600;
    font-size: 72px;
    line-height: 150%;
  }

  // .about-us-adv__subtitle

  &__subtitle {
    color: $white;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
}
.about-us-clients {
  margin-bottom: 100px;
  padding: 0 20px;

  // .about-us-clients__header

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  // .about-us-clients__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $secondary;
  }

  // .about-us-clients__arrows

  &__arrows {
    width: 115px;
    display: flex;
    justify-content: space-between;
  }

  // .about-us-clients__arrow

  &__arrow {
    width: 42px;
    height: 42px;
    cursor: pointer;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      transform: scale(0.9);
    }
  }

  // .about-us-clients__slider

  &__slider {
    & .slick-slide {
      & > div {
        display: flex;
        align-items: center;
        height: 169px;
      }
    }
  }

  // .about-us-clients__slider-item

  &__slider-item {
    cursor: pointer;
    & img {
      margin: 0 auto;
      filter: grayscale(1);
      transition: .2s;
    }
    &:hover {
      & img {
        filter: grayscale(0);
      }
    }
  }
}

.container-about-us {
  max-width: 1670px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.about-us-cases {
  background: #EFF3F9;
  padding: 70px 0 100px;

  // .about-us-cases__header

  &__header {
    max-width: 1520px;
    width: 100%;
    margin: 0 auto;
  }

  // .about-us-cases__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $secondary;
    margin-bottom: 50px;
  }

  // .about-us-cases__slider-wrap

  &__slider-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  // .about-us-cases__slider

  &__slider {
    width: 100%;
    & .slick-slide > div{
      display: flex;
      justify-content: center;
    }
  }

  .about-us-cases__arrow,
  &__arrow {
    height: fit-content;
    cursor: pointer;
    transition: .2s;
    &:active {
      transform: scale(0.9);
    }
  }
}
.about-us .cases {

  // .cases__item-wrap

  &__item-wrap {
    width: 23.5%;
    max-width: 360px;
    height: 330px;
    cursor: pointer;
    margin-bottom: 1.8%;
    position: relative;
    overflow: hidden;
    & .cases__item{
      padding: 6% 8%;
    }
  }

  // .cases__item-container

  &__item-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  // .cases__item-bg

  &__item-bg {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(26, 59, 112, 0.75) 0%, #091A34 100%);
      mix-blend-mode: multiply;
      border-radius: 8px;
      z-index: 1;
      opacity: 0;
      transition: .3s;
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
      mix-blend-mode: multiply;
      border-radius: 8px;
      z-index: 1;
      opacity: 1;
      transition: .3s;
    }
  }

  // .cases__item

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translatey(35%) translateZ(0);
    will-change: transform;
    backface-visibility: hidden;
    filter: blur(.0px);
    transition: .3s;
  }

  // .cases__item-title

  &__item-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;
    text-align: center;
  }

  // .cases__item-subtitle

  &__item-subtitle {
    @include main-text;
    color: $white;
    margin-bottom: 28px;
    opacity: 0;
    transition: .3s;
    transition-delay: 0.2s;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  // .cases__item-details

  &__item-details {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $white;
    opacity: 0;
    transition: .3s;
    transition-delay: 0.2s;
    & svg{
      margin-left: 10px;
    }
  }

  &__item-wrap:hover{
    & .cases__item-bg {
      box-shadow: 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
      transition: .2s;
      &::before{
        opacity: 1;
      }
      &::after{
        opacity: 0;
      }
    }
    & .cases__item{
      transform: translatey(0%);
    }
    & .cases__item-details{
      opacity: 1;
    }
    & .cases__item-subtitle{
      opacity: 1;
    }
  }
}

.about-us-team {
  padding: 80px 0 130px;

  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 370px;
  }

  // .about-us-team__header

  &__header {
    max-width: 1520px;
    width: 100%;
    margin: 0 auto;
  }

  // .about-us-team__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 60px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $secondary;
  }

  // .about-us-team__paragraph

  &__paragraph {
    font-size: 16px;
    line-height: 150%;
    color: #000;
  }

  // .about-us-team__slider-wrap

  &__slider-wrap {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }

  // .about-us-team__arrow

  &__arrow {
    cursor: pointer;
    transition: .2s;
    height: fit-content;
  }

  // .about-us-team__slider

  &__slider {
    width: 100%;
    & .slick-slide > div{
      display: flex;
      justify-content: center;
    }
  }

  // .about-us-team__slider-item

  &__slider-item {
    max-width: 230px;
    width: 100%;
  }

  // .about-us-team__slide-img

  &__slide-img {
    border-radius: 50%;
    width: 230px;
    height: 230px;
    overflow: hidden;
    margin: 0 auto 40px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .about-us-team__slide-title

  &__slide-title {
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
    font-weight: 600;
  }

  // .about-us-team__slide-subtitle

  &__slide-subtitle {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #000;
  }
}

.vacancies-container {
  max-width: 1260px;
  padding: 0 10px;
  margin: 0 auto;
}

.vacancies {
  background: #EFF3F9;
  padding: 70px 0;
  // .vacancies__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 50px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $secondary;
  }

  // .vacancies__body

  &__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  // .vacancies__tabs

  &__tabs {
    max-width: 620px;
    width: 100%;
  }

  // .vacancies__tab

  &__tab {
    position: relative;
    box-shadow: 0 0 16px #DBE2EA;
    border-radius: 8px;
    overflow: hidden;
    transition: .3s;
    height: fit-content;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    &.active {
      & .vacancies__header {
        background: #1A3B70;
      }
      & .vacancies__header-title {
        color: $white;
      }
      & .vacancies__header-arrow {
        transform: rotate(-90deg);
        & svg path {
          fill: $white;
        }
      }
      & .vacancies__tab-body {
        max-height: 2000px;
        padding: 50px 35px;
      }
    }
  }

  // .vacancies__header

  &__header {
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    height: 96px;
    cursor: pointer;
    transition: .2s;
  }

  // .vacancies__header-title

  &__header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #1A3B70;
    transition: .2s;
  }

  // .vacancies__header-arrow

  &__header-arrow {
    margin-right: 28px;
    transition: .2s;
    & svg path {
      transition: .2s;
    }
  }

  // .vacancies__tab-body

  &__tab-body {
    background: $white;
    padding: 0;
    max-height: 0;
    transition: .3s;

    & p {
      font-size: 16px;
      line-height: 150%;
      color: #000;
      margin-bottom: 10px;
    }

    & ul {
      margin-bottom: 10px;
    }

    & li {
      &::before {
        content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
        margin-right: 8px;
      }
    }
  }

  // .vacancies__tab-title

  &__tab-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 0.5em;
    &:first-child {
      margin-bottom: 2em;
    }
  }

  // .vacancies__tab-p

  &__tab-p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 2em;
  }

  // .vacancies__tab-list

  &__tab-list {
    margin-bottom: 2em;
  }

  // .vacancies__tab-list-item

  &__tab-list-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 0.5em;
  }
}

.vacancies-request {
  background: #FFFFFF;
  box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
  border-radius: 8px;
  padding: 50px 48px;
  max-width: 526px;
  width: 100%;
  height: fit-content;
  letter-spacing: 0.01em;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .form__label {
    width: 100%;
  }

  & .error-message {
    bottom: 10px;
  }

  & .form__input {
    margin-bottom: 25px;
  }
  //     &:focus ~ span.required, &:not(:focus):valid ~ span.required, &:not(:placeholder-shown) ~ span.required{
  //         top: -14px !important;
  //         font-size: 11px;
  //     }
  // }
  // & .form__input ~ span {
  //     top: 2px !important;
  // }

  // .vacancies-request__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $secondary;
    text-align: center;
    margin-bottom: 8px;
  }

  // .vacancies-request__subtitle

  &__subtitle {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 40px;
  }

  // .vacancies-request__download-body

  &__download-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    &.empty {
      justify-content: center;
    }
  }

  // .vacancies-request__download-btn

  &__download-btn {
    display: flex;
    align-items: center;
    border: 1px solid #A5A5A5;
    border-radius: 5px;
    color: #858585;
    font-size: 14px;
    line-height: 17px;
    height: 49px;
    background: transparent;
    padding: 0 60px;
    cursor: pointer;

    & > input {
      display: none;
    }

    &.empty {
      padding: 0 48px;
    }
  }

  // .vacancies-request__file-link

  &__file-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #2C599D;
    & svg {
      margin-right: 8px;
    }
  }

  & .checkbox-wrap {
    margin-bottom: 40px;
  }
  .checkbox {
    & span {
      font-size: 11px;
      line-height: 120%;
      color: #756F86;
      display: flex;
      align-items: center;
    }
  }
  .btn-accent {
  }


  // .vacancies-request__confirm-btn

  &__confirm-btn {
  }
}

@media only screen and (max-width: 1060px) {
  .vacancies {
    &__body {
      flex-direction: column;
      align-items: center;
    }
    &__title {
      text-align: center;
    }
    &__tabs {
      margin-bottom: 25px;
    }
    &__tab {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}



@media only screen and (max-width: 976px) {
  .about-us-clients {
    &__slider-item {
      & img {
        filter: grayscale(0);
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .about-us {
    &__article-img {
      float: none;
      margin: 0 auto;
      margin-bottom: 34px;
    }
  }
  .about-us-header {
    margin-bottom: 30px;
  }
}


@media only screen and (max-width: 880px) {
  .about-us-header {
    height: 400px;
    &__title {
      font-size: 28px;
      margin-bottom: 18px;
      font-weight: 600;
    }
    &__subtitle {
      font-size: 16px;
    }
  }
  .about-us {
    &__article-p {
      font-size: 12px;
    }
    &__article {
      margin-bottom: 20px;
    }
  }
  .about-us-adv {
    margin-bottom: 34px;
    &__title {
      font-size: 48px;
    }
    &__subtitle {
      font-size: 16px;
    }
  }
  .about-us-cases {
    &__slider {
      max-width: 100%;
      .main-sl-dots {
        bottom: -30px;
        & li:not(.slick-active) button {
          background: $primary;
        }
      }
    }
    &__arrow {
      display: none;
    }
  }
  .about-us-clients {
    margin-bottom: 40px;
    &__header {
      margin-bottom: 30px;
    }
  }
  .about-us-cases {
    padding: 40px 0 45px;
    &__title {
      text-align: center;
      margin-bottom: 32px;
    }
  }
  .about-us-team {
    padding: 35px 0 45px;

    &__slide {
      height: 225px;
    }

    &__title {
      text-align: center;
      margin-bottom: 32px;
    }
    &__paragraph {
      font-size: 12px;
    }
    &__slider-wrap {
      margin-top: 50px;
    }
    &__slider {
      max-width: calc(100% - 70px);
    }
    &__slide-img {
      width: 140px;
      height: 140px;
      margin-bottom: 9px;
    }
    &__slide-title {
      font-size: 14px;
      margin-bottom: 5px;
    }
    &__slide-subtitle {
      font-size: 12px;
    }
    &__arrow {
      width: 34px;
      height: 34px;
      & svg {
        width: 34px;
        height: 34px;
      }
    }
  }
  .vacancies {
    padding: 38px 0 60px;
    &__title {
      margin-bottom: 32px;
    }
    &__header {
      min-height: 53px;
      height: auto;
      padding: 16px 0 16px 16px;
    }
    &__header-title {
      font-size: 12px;
    }
    &__header-arrow {
      margin-right: 16px;
    }
    &__tab.active {
      & .vacancies__tab-body {
        padding: 18px 16px;
      }
    }
    &__tab-title, &__tab-p, &__tab-list-item {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 610px) {
  .container-about-us {
    padding: 0 10px;
  }

  .about-us-adv {
    height: fit-content;
    &__body {
      flex-direction: column;
      align-items: center;
      padding: 40px 0 60px;
    }
    &__subtitle {
      max-width: 250px;
    }
    &__item {
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}


@media only screen and (max-width: 576px) {
  .about-us-header {
    height: 310px;
    &__title {
      font-size: 18px;
      margin-bottom: 8px;
    }
    &__subtitle {
      font-size: 14px;
    }
  }
}


@media only screen and (max-width: 545px) {
  .vacancies-request {
    &__download-body {
      flex-direction: column;
      align-items: center;
    }
    &__file-link {
      margin-bottom: 10px;
    }
    &__title {
      margin-bottom: 28px;
    }
    &__subtitle {
      margin-bottom: 8px;
    }
  }
}

@media only screen and (max-width: 486px) {
  .vacancies-request {
    padding: 50px 8px;
  }
}
