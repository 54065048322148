@import '../../style/vars';
@import '../../style/mixins';

.solution-choose {

    & .product-slider-wrap {
      padding-left: 25px;
      padding-right: 25px;
    }
    // .solution-choose__tabs

    &__tabs {
        width: 100%;
        border-bottom: 3px solid #2C599D;
        display: flex;
        justify-content: center;
    }

    // .solution-choose__tab

    &__tab {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 89px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: $additional;
        text-align: center;
        cursor: pointer;
        transition: .2s;

        &.active {
            background: #2C599D;
            font-weight: 600;
            font-size: 28px;
            color: $white;
        }
    }

    // .solution-choose__content-wrap

    &__content-wrap {
        width: 100%;
        overflow: hidden;
    }

    // .solution-choose__content

    &__content {
        display: flex;
    }

    // .solution-choose__page

    &__page {
        min-width: 100%;
        width: 100%;
        display: none;
        &.active{
            display: block;
        }
    }
}
.solution-article {
    margin: 90px 0 90px;
    display: flex;

    &--empty {
      margin: 50px 0;
    }

    // .solution-article__photo

    &__photo {
        width: 490px;
        height: 490px;
        min-width: 490px;
        min-height: 490px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-right: 60px;
        position: relative;
        & img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: none;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
    }

    // .solution-article__section

    &__section {
        max-width: 980px;
        padding: 0 10px;
    }

    // .solution-article__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        margin-bottom: 29px;
    }

    // .solution-article__description

    &__description {
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;

        & li {
            margin-bottom: 4px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
                margin-right: 8px;
            }
        }
    }

    // .solution-article__download-btn

    &__download-btn {
        max-width: 386px;
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        font-size: 16px;
        line-height: 19px;
        color: #2C599D;
        transition: .3s;
        background: $white;
        border: 1px solid #2C599D;
        border-radius: 10px;
        & svg {
            margin-right: 11px;
            & path {
                transition: .3s;
            }
        }
        &:hover {
            background: #2C599D;
            color: $white;
            & svg {
                & path {
                    fill: $white;
                }
            }
        }
    }

    // .solution-article__list

    &__list {
        margin-bottom: 12px;
    }

    // .solution-article__list-item

    &__list-item {
        font-size: 14px;
        line-height: 150%;
    }
    & .suitable-tabs__title {
        color: #2C599D;
    }
}

@media only screen and (max-width: 1150px){
    .solution-article {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__photo {
            margin: 0 0 50px;
        }
    }
}

@media only screen and (max-width: 880px){
    .solution-choose {
        &__tab {
            width: 296px;
            height: 59px;
            font-size: 14px;
            line-height: 150%;
            &:first-child{
                margin-right: 0;
            }
            &.active {
                font-size: 14px;
            }
        }
    }
    .solution-article {
        margin: 75px 0 65px;
        &__title {
            font-size: 18px;
            color: $secondary;
            margin-bottom: 12px;
        }
        &__description {
            font-size: 14px;
        }
        &__list-item {
            font-size: 14px;
        }
        &__download-btn {
            margin-top: 24px;
        }
    }
}

@media only screen and (max-width: 580px){
    .solution-choose {
        &__tabs {
            border-bottom: none;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        &__tab {
            border-radius: 10px;
            padding: 0;
            &:first-child {
                margin-bottom: 16px;
            }
        }
    }

    .solution-article {
        &__photo {
            & img {
                object-fit: cover;
            }
        }
    }
}

@media only screen and (max-width: 528px){
    .solution-article {
        &__photo {
            width: 296px;
            height: 296px;
            min-height: 296px;
            min-width: 296px;
        }
    }
}
