@import '../../style/vars';

.gsm {
    & .user-documents {
        width: 100%;
        margin-bottom: 100px;

        // .user-documents__body

        &__body {
            display: flex;
        }

        // .user-documents__section

        &__section {
            &:not(:last-child){
                margin-right: 280px;
            }
        }

        // .user-documents__section-title

        &__section-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 40px;
            color: $secondary;
        }

        // .user-documents__documents

        &__documents {

        }

        // .user-documents__document

        &__document {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:last-child){
                margin-bottom: 12px;
            }
            & img{
                margin-right: 30px;
            }
            & .download-btn{
                cursor: pointer;
                & path{
                    transition: .2s;
                }
                &:hover{
                    & path{
                        fill: $secondary;
                    }
                }
            }
        }

        // .user-documents__document-name

        &__document-name {
            font-size: 16px;
            line-height: 150%;
            margin-right: 34px;
        }
    }

}
.gsm-header {
    position: relative;
    height: 700px;
    margin-bottom: 90px;
    &::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1A3B70;
        mix-blend-mode: multiply;
        opacity: 0.7;
    }

    // .gsm-header__bg

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .gsm-header__body

    &__body {
        position: relative;
        z-index: 1;
        max-width: 592px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    // .gsm-header__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        margin-bottom: 40px;
        color: $white;
        text-transform: uppercase;
    }

    // .gsm-header__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 70px;
        color: $white;
    }

    // .gsm-header__order-btn

    &__order-btn {
        display: block;
        width: fit-content;
    }
}
.gsm-header-container {
    max-width: 1350px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}
.gsm-descr {
    margin: 120px auto 130px;
    display: flex;
    max-width: 1272px;
    justify-content: space-between;

    // .gsm-descr__section

    &__section {
        max-width: 630px;
        width: 100%;
    }

    // .gsm-descr__title

    &__title {
        font-weight: 500;
        font-size: 28px;
        line-height: 150%;
        color: #2D3258;
        margin-bottom: 34px;
    }

    // .gsm-descr__paragraph

    &__paragraph {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000;
        &:not(:last-child){
            margin-bottom: 30px;
        }
        &.sol{
            color: #273043;
        }
    }

    // .gsm-descr__img

    &__img {
        max-width: 488px;
        & img{
            width: 100%;
            object-fit: cover;
        }
    }
}
.container-gsm{
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 10px;
}
.gsm-methods {
    margin: 100px 0;
    // .gsm-methods__title

    &__title {
        font-weight: 500;
        font-size: 28px;
        line-height: 150%;
        color: #2D3258;
        margin-bottom: 42px;
        max-width: 1264px;
        width: 100%;
        text-align: center;
    }

    // .gsm-methods__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000;
        margin-bottom: 70px;
        text-align: center;
    }

    // .gsm-methods__tabs-wrap

    &__tabs-wrap {
        position: relative;
        margin-bottom: 70px;
        &::after{
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
            width: calc(100% - 100px);
            height: 2px;
            border-top: 2px dashed #2C599D;
        }
    }

    // .gsm-methods__tabs

    &__tabs {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 0 28px;
    }

    // .gsm-methods__tab

    &__tab {
        width: 175px;
        height: 175px;
        padding: 21px 10px 10px;
        background: #FFFFFF;
        box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        transition: .3s;
        &.active{
            background: #2C599D;
            & .gsm-methods__tab-ico {
                & .ico {
                    opacity: 0;
                    transition: .3s;

                }
                & .ico-active {
                    opacity: 1;
                    transition: .3s;
                }
            }
            & .gsm-methods__tab-title {
                color: $white;
            }
        }
    }

    // .gsm-methods__tab-ico

    &__tab-ico {
        position: relative;
        & .ico {
            opacity: 1;
            transition: .3s;

        }
        & .ico-active {
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
            opacity: 0;
            transition: .3s;
        }
    }

    // .gsm-methods__tab-title

    &__tab-title {
        width: 136px;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #2C599D;
        margin-top: auto;
        transition: .3s;
    }

    // .gsm-methods__slider-wrap

    &__slider-wrap {
        max-width: 1452px;
        padding: 0 10px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        & .arrow {
            height: 300px;
            display: flex;
            align-items: center;
            & svg {
                transition: .2s;
            }
            &:active {
                & svg {
                    transform: scale(0.8);
                }
            }
        }
    }

    // .gsm-methods__slider

    &__slider {
        max-width: 1330px;
        width: calc(100% - 40px);
        & .slick-slide{
            & .method-wrap{
                opacity: 0;
            }
        }
        & .slick-slide.slick-active{
            & .method-wrap{
                opacity: 1;
                padding-bottom: 20px;
            }
        }
    }
}

.method-wrap {
    transition: 0.4s;
    padding: 0 30px;
}
.method {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 8px;
    padding: 50px;
    // .method__img

    &__img {
        width: 136px;
        height: 136px;
        min-width: 136px;
        min-height: 136px;
        margin-top: 30px;
        margin-right: 80px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .method__body

    &__body {
    }

    // .method__paragraph

    &__paragraph {
        font-size: 16px;
        line-height: 150%;
        color: #000;
        margin-bottom: 12px;
    }

    // .method__dot-list

    &__dot-list {
        margin-bottom: 20px;
        & .method__list-item{
            &::before{
                content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
                margin-right: 8px;
                display: block;
                transform: translateY(3px);
            }
        }
    }

    // .method__list-item

    &__list-item {
        display: flex;
        font-size: 16px;
        line-height: 150%;
        color: #000;
    }

    // .method__footer

    &__footer {
        margin-top: 46px;
        display: flex;
        & .method__dot-list:not(:last-child){
            margin-right: 8%;
        }
    }

    // .method__plus-list

    &__plus-list {
        max-width: 450px;
        margin-right: 7%;
        & .method__list-item{
            &::before{
                content: "+";
                display: block;
                color:#F88125;
                font-size: 30px;
                transform: translateY(-3px);
                margin-right: 8px;
            }
        }
    }

    // .method__minus-list

    &__minus-list {
        max-width: 450px;
        width: max-content;
        & .method__list-item{
            &::before{
                content: "-";
                display: block;
                color: #2C599D;
                font-size: 30px;
                transform: translateY(-1px);
                margin-right: 8px;
            }
        }
    }
}
.method-not-recommended {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #979797;

    & span {
        text-align: center;
    }
}
.method-order-btn {
    width: fit-content;
    margin: 34px auto 0;
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 1320px){
    .method {
        &__img {
            margin-right: 30px;
        }
        &__body {
            width: calc(100% - 156px);
        }
    }
}

@media only screen and (max-width: 1240px){
    .gsm-descr {
        flex-direction: column;
        align-items: center;
        &__section {
            max-width: 100%;
            margin-bottom: 32px;
        }
    }
}

@media only screen and (max-width: 1024px){
    .gsm-methods {
        &__tabs-wrap {
            &::after {
                display: none;
            }
        }
        &__tabs {
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;
        }
        &__tab {
            margin: 10px;
            width: 125px;
            height: 125px;
            padding: 15px 14px 7px;
        }
        &__tab-ico {
            display: flex;
            justify-content: center;
            & .ico, & .ico-active {
                width: 70%;
            }
        }
        &__tab-title {
            width: 100%;
            font-size: 12px;
            text-align: center;
            height: 38px;
        }
    }
}
@media only screen and (max-width: 992px){
    .method {
        &__footer {
            flex-direction: column;
        }
        &__plus-list {
            margin-bottom: 24px;
        }
        &__plus-list, &__minus-list {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
        }
    }
}

@media only screen and (max-width: 880px){
    .gsm-header-container {
        display: flex;
        justify-content: center;
    }
    .gsm-header {
        height: 440px;
        margin-bottom: 60px;

        &__body {
            align-items: center;
        }

        &__title {
            font-size: 18px;
            margin-bottom: 52px;
            text-align: center;
        }
        &__subtitle {
            font-size: 14px;
            text-align: center;
            margin-bottom: 50px;
        }
        &__order-btn {
            margin: 0 auto;
        }
    }
    .gsm-descr {
        margin-top: 60px;
        margin-bottom: 50px;
        padding: 0 10px;

        &__title {
            font-size: 20px;
            margin-bottom: 34px;
        }
        &__paragraph {
            font-size: 14px;
            &:not(:last-child){
                margin-bottom: 5px;
            }
        }
    }
    .gsm-methods {
        margin: 60px 0;
        padding: 0 10px;
        &__title {
            font-size: 20px;
            margin-bottom: 34px;
        }
        &__subtitle {
            font-size: 14px;
            margin-bottom: 40px;
        }
        &__tabs-wrap {
            margin-bottom: 50px;
        }
    }
    .method {
        padding: 16px 16px 24px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &__body {
            width: 100%;
        }
        &__img {
            margin: 0 0 40px;
        }
        &__paragraph {
            font-size: 14px;
            margin-bottom: 6px;
        }
        &__list-item {
            font-size: 14px;
        }
        &__footer {
            margin-top: 28px;
        }
        &__dot-list {
            & .method__list-item{
                &::before{
                    margin-right: 8px;
                    display: block;
                    transform: translateY(3px);
                }
            }
        }
    }
    .method-not-recommended {
        font-size: 16px;
    }
}

@media only screen and (max-width: 630px){
    .gsm-methods {
        &__slider-wrap {
            & .arrow {
                display: none !important;
            }
        }
        &__slider {
            width: 100%;
            & .main-sl-dots {
                bottom: -28px;
                & li:not(.slick-active){
                    & button {
                        background: #1A3B70;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 538px){
    .method-wrap {
        padding: 0 10px;
    }
    .method {
        box-shadow: 0 5px 10px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    }
}
