@import '../../style/vars';
@import '../../style/mixins';

.cases {
    padding: 70px 0;
    overflow: hidden;
    // .cases__title

    &__title {
        @include title;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    // .cases__body

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    // .cases__item-wrap

    &__item-wrap {
        width: 49%;
        height: 330px;
        cursor: pointer;
        margin-bottom: 1.8%;
        position: relative;
        overflow: hidden;
        & .cases__item{
            padding: 6% 8%;
        }
    }

    // .cases__item-container

    &__item-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    // .cases__item-wrap--big

    &__item-wrap--big {
        width: 49%;
        & .cases__item{
            padding: 6% 17%;
        }
    }

    // .cases__item-bg

    &__item-bg {
        height: 100%;
        width: 100%;
        position: relative;
        border-radius: 8px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(26, 59, 112, 0.75) 0%, #091A34 100%);
            mix-blend-mode: multiply;
            border-radius: 8px;
            z-index: 1;
            opacity: 0;
            transition: .3s;
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
            mix-blend-mode: multiply;
            border-radius: 8px;
            z-index: 1;
            opacity: 1;
            transition: .3s;
        }
    }

    // .cases__item

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transform: translatey(55%) translateZ(0);
        will-change: transform;
        backface-visibility: hidden;
        filter: blur(.0px);
        transition: .3s;
    }

    // .cases__item-title

    &__item-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-bottom: 20px;
        text-align: center;
    }

    // .cases__item-subtitle

    &__item-subtitle {
        @include main-text;
        color: $white;
        margin-bottom: 28px;
        opacity: 0;
        transition: .3s;
        transition-delay: 0.2s;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        >* {
            color: white;
        }
    }

    // .cases__item-details

    &__item-details {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: $white;
        opacity: 0;
        transition: .3s;
        transition-delay: 0.2s;
        & svg{
            margin-left: 10px;
        }
    }

    &__item-wrap:hover{
        & .cases__item-bg {
            box-shadow: 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
            transition: .2s;
            &::before{
                opacity: 1;
            }
            &::after{
                opacity: 0;
            }
        }
        & .cases__item{
            transform: translatey(0%);
        }
        & .cases__item-details{
            opacity: 1;
        }
        & .cases__item-subtitle{
            opacity: 1;
        }
    }
}


@media only screen and (max-width: 1480px){
    .cases{
        &__item-wrap{
            width: 49%;
        }
        &__item-wrap--big {
            width: 49%;
            & .cases__item{
                padding: 6% 8%;
            }
        }
    }
}


@media only screen and (max-width: 1175px){
    .cases{
        &__item-wrap{
            width: 49%;
        }
        &__item-wrap--big{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 880px){
    .cases{
        padding: 40px 0;
        &__title{
            margin-bottom: 30px;
        }
        &__item-title{
            font-size: 18px;
        }
        &__item-subtitle{
            font-size: 12px;
        }
        &__item-details{
            font-size: 11px;
        }
        &__item-bg {
            &::before{
                opacity: 1;
            }
            &::after{
                opacity: 0;
            }
        }
        &__item{
            transform: translatey(0%);
        }
        &__item-details{
            opacity: 1;
        }
        &__item-subtitle{
            opacity: 1;
        }
    }

}

@media only screen and (max-width: 670px){
    .cases{
        &__item-wrap{
            width: 100%;
        }
        &__item-bg {
            height: 100%;
            width: 100%;
            position: relative;
            box-shadow: 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
            transition: .2s;
        }
    }
}