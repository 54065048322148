@import '../../style/vars';
@import '../../style/mixins';

.user-account {
    margin-bottom: 70px;
    // .user-account__header

    & .message-label {
        &--success {
            color: $success;
        }

        &--error {
            color: $error;
        }
    }

    &__header {
        width: 100%;
        border-bottom: 2px solid #DBE2EA;
        margin-top: 70px;
        margin-bottom: 70px;

        &::-webkit-scrollbar {
            height: 4px;
        }
    }

    // .user-account__tabs

    &__tabs {
        display: flex;
        max-width: 905px;
        justify-content: space-between;
        height: 52px;
        margin-bottom: -3px;
    }

    // .user-account__tab-wrap

    &__tab-wrap{
        height: 100%;
        display: flex;
        align-items: flex-start;
        position: relative;
        cursor: pointer;
        transition: .2s;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 4px;
            background: transparent;
            border-radius: 2px;
            transition: .2s;
        }
        & .user-account__tab-ico{
            & svg path{
                fill: $secondary-text-color;
            }
        }
        &.active{
            &::after{
                background: $additional;
            }
            & .user-account__tab-ico{
                & svg path{
                    fill: $secondary;
                }
            }
            & .user-account__tab-title{
                color: $secondary;
            }
        }
    }

    // .user-account__tab

    &__tab {
        display: flex;
        align-items: center;
        height: 32px;
    }

    // .user-account__tab-ico

    &__tab-ico {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }

    // .user-account__tab-title

    &__tab-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $secondary-text-color;
        transition: .2s;
    }

    // .user-account__body

    &__body {
        display: flex;
        width: 100%;
        //overflow: hidden;
    }

    // .user-account__container

    &__container {
        display: flex;
        width: max-content;
        overflow: hidden;

    }

    // .user-account__personal

    &__personal {
        display: flex;
        width: 100%;
        min-width: 100%;
        justify-content: space-between;
    }


    // .user-account__forms-wrap

    &__forms-wrap{
        display: flex;
        justify-content: space-between;
        max-width: 881px;
        width: 100%;
    }

    // .user-account__personal-form

    &__personal-form {
        max-width: 390px;
        width: 100%;
        padding-right: 10px;
        padding-bottom: 20px;
        &--password{
            max-width: 261px;
            width: 100%;
            & .input-with-label{
                margin-bottom: 0 !important;
            }
            & .password-forgot{
                display: block;
                margin: 6px 0 12px 3px;
                font-size: 12px;
                line-height: 120%;
                text-decoration-line: underline;
                color: $secondary-text-color;
                transition: .2s;
                &:hover{
                    color: $secondary;
                }
            }
            & .password-label{
                margin: 6px 0 12px 3px;
                font-size: 12px;
                line-height: 120%;
                color: $secondary-text-color;
            }
            & .user-account__edit-btn{
                margin-left: 0;
            }
        }
        & .form__label{
            & .form__input{
                width: 100%;
                margin-bottom: 12px;
                &::placeholder{
                    color: $text-color;
                }
                &:focus ~ span, &:not(:focus):valid ~ span, &:not(:placeholder-shown) ~ span{
                    top: -15px !important;
                    font-size: 11px;
                }
            }
            & span{
                top: -15px;
                font-size: 11px;
                width: max-content;
            }
        }
    }

    // .user-account__personal-title

    &__personal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: $secondary;
        margin-bottom: 40px;
    }

    // .user-account__edit-btn

    &__edit-btn {
        margin-top: 28px;
        margin-left: 10px;
        width: 188px;
        padding: 0;
        height: 45px;
    }

    // .user-account__bonus-section

    &__bonus {
        margin-bottom: 30px;
    }

    &__bonus-section {
        max-width: 360px;
        width: 100%;
        padding-top: 10px;
    }

    // .user-account__bonus

    &__bonus {
        width: 100%;
        padding: 20px 32px;
        background: #EBF4F8;
        box-shadow: 0 4px 4px rgba(44, 89, 157, 0.25);
        border-radius: 8px;
    }

    // .user-account__bonus-body

    &__bonus-body {
        display: flex;
        align-items: center;
    }

    // .user-account__bonus-ico

    &__bonus-ico {
        margin-right: 32px;
    }

    // .user-account__bonus-info

    &__bonus-info {
    }

    // .user-account__bonus-title

    &__bonus-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
        margin-bottom: 8px;
    }

    // .user-account__bonus-text

    &__bonus-text {
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 8px;
    }

    // .user-account__bonus-value

    &__bonus-value {
        color: $accent;
        margin-right: 0.5em;
        font-weight: 600;
    }

    // .user-account__bonus-link

    &__bonus-link {
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: $additional;
    }
}



.order-history {
    width: 100%;
    // .order-history__order

    &__order {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }

    // .order-history__order-header

    &__order-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 42px;
    }

    // .order-history__order-number

    &__order-number {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
    }

    // .order-history__right-wrap

    &__right-wrap {
        display: flex;
        justify-content: space-between;
        width: 35%;
        align-items: center;
    }

    // .order-history__value-title

    &__value-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: $primary;
        text-align: center;
    }

    // .order-history__product

    &__product {
        display: flex;
        justify-content: space-between;
    }

    // .order-history__product-body

    &__product-body {
        display: flex;
    }

    // .order-history__product-img

    &__product-img {
        width: 172px;
        height: 172px;
        min-width: 172px;
        min-height: 172px;
        margin-right: 92px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .order-history__product-info

    &__product-info {
        max-width: 486px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // .order-history__product-name

    &__product-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: $primary;
        margin-bottom: 10px;
    }

    // .order-history__product-model

    &__product-model {
        font-size: 14px;
        line-height: 150%;
    }

    // .order-history__product-quantity

    &__product-quantity {
        max-width: 118px;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
    }

    // .order-history__product-price

    &__product-price {
        max-width: 118px;
        width: 100%;
        text-align: right;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
    }
}
.order-history-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    // .order-history-footer__info-section

    &__info-section {
        margin-top: 26px;
    }

    // .order-history-footer__info-title

    &__info-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        margin: 8px 0;
    }

    // .order-history-footer__info

    &__info {
        font-size: 14px;
        line-height: 150%;
    }

    // .order-history-footer__price-section

    &__price-section {
        max-width: 230px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    // .order-history-footer__price-section-body

    &__price-section-body {
        margin-bottom: 50px;
    }

    // .order-history-footer__price-item

    &__price-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
    }

    // .order-history-footer__price-label

    &__price-label {
        font-size: 18px;
        line-height: 120%;
    }

    // .order-history-footer__price

    &__price {
        font-size: 18px;
        line-height: 150%;
    }

    // .order-history-footer__price-item--total

    &__price-item--total {

        & .order-history-footer__price-label {
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            color: $secondary;
        }
        & .order-history-footer__price {
            font-weight: bold;
            font-size: 20px;
            line-height: 150%;
            color: $secondary;
        }
    }

    // .order-history-footer__repeat-order-btn

    &__repeat-order-btn {
        width: 198px;
        height: 45px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        align-self: center;
    }
}




.user-documents {
    width: 100%;
    margin-bottom: 100px;

    // .user-documents__body

    &__body {
        display: flex;
    }

    // .user-documents__section

    &__section {
        &:not(:last-child){
            margin-right: 280px;
        }
    }

    // .user-documents__section-title

    &__section-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 40px;
        color: $secondary;
    }

    // .user-documents__documents

    &__documents {

    }

    // .user-documents__document

    &__document {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child){
            margin-bottom: 12px;
        }
        & img{
            margin-right: 30px;
        }
        & .download-btn{
            cursor: pointer;
            & path{
                transition: .2s;
            }
            &:hover{
                & path{
                    fill: $secondary;
                }
            }
        }
    }

    // .user-documents__document-name

    &__document-name {
        font-size: 16px;
        line-height: 150%;
        margin-right: 34px;
    }
}



// ========================================================================= Content Switch
.user-account__container, .user-documents, .order-history{
    width: 0;
    height: 0;
    overflow: hidden;
    transition: .3s;
    &.active{
        height: fit-content;
        width: 100%;
    }
}





@media only screen and (max-width: 1065px){
    .user-account{
        &__forms-wrap{
            flex-direction: column;
            justify-content: flex-start;
        }
        &__personal-form{
            margin-bottom: 36px;
        }
    }
    .user-documents{
        &__body{
            display: flex;
            justify-content: space-between;
        }
        &__section{
            &:not(:last-child){
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 880px){
    .user-account{
        margin-bottom: 40px;
        &__header{
            margin-top: 40px;
            margin-bottom: 40px;
        }
        &__personal-title{
            font-size: 16px;
            margin-bottom: 32px;
        }
        &__tabs{
            height: 42px;
        }
        &__tab-ico{
            width: 24px;
            height: 24px;
            & svg{
                width: 24px;
                height: 24px;
            }
        }
        &__tab-title{
            font-size: 16px;
        }
        & .form__label{
            & span{
                background: $white;
            }
        }
        &__edit-btn{
            margin-top: 18px;
        }
        &__bonus-section{
            //max-width: 320px;
        }
        &__bonus{
            padding: 16px 24px;
        }
        &__bonus-ico{
            margin-right: 16px;
        }
        &__bonus-title{
            font-size: 14px;
        }
        &__bonus-text{
            font-size: 14px;
        }
        &__bonus-link{
            font-size: 12px;
        }
    }
    .order-history{
        &__order-header{
            margin-bottom: 32px;
        }
        &__order-number{
            font-size: 14px;
        }
        &__value-title{
            font-size: 17px;
        }
        &__product-img{
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
            margin-right: 24px;
        }
        &__product-info{
            max-width: 210px;
        }
        &__product-name{
            font-size: 14px;
        }
        &__product-model{
            font-size: 12px;
        }
        &__product-quantity, &__product-price, &__value-title{
            max-width: 100px;
            width: 100%;
        }
        &__value-title{
            &:last-child{
                text-align: right;
            }
        }
        &__product-price, &__product-quantity{
            font-size: 17px;
        }
        &__right-wrap{
            min-width: 200px;
        }
    }
    .order-history-footer{
        &__price-label, &__price{
            font-size: 16px;
        }
        &__price-item--total{
            & .order-history-footer__price-label, & .order-history-footer__price{
                font-size: 17px;
            }
        }
        &__price-section-body{
            margin-bottom: 32px;
        }
    }
    .user-documents{
        margin-bottom: 70px;
        &__body{
            flex-direction: column;
            align-items: center;
        }
        &__section{
            &:not(:last-child){
                margin-bottom: 38px;
            }
        }
        &__section-title{
            font-size: 16px;
            margin-bottom: 24px;
        }
        &__document{
            & img{
                width: 16px;
                height: 16px;
            }
        }
        &__document-name{
            font-size: 14px;
        }
    }
}


@media only screen and (max-width: 650px){
    .user-account{
        &__personal{
            flex-direction: column;
            align-items: center;
        }
        &__forms-wrap{
            width: fit-content;
        }
        &__bonus-section{
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: 625px){
    .user-account{
        &__tab{
            min-width: max-content;
        }
        &__tab-title{
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 515px){
    .user-account{
        &__header{
            overflow-x: auto;
            overflow-y: hidden;
            @include scroll-style;
            padding-bottom: 10px;
            border: none;
        }
        &__tab-wrap{
            &:not(:last-child){
                margin-right: 24px;
            }
        }
    }
}

@media only screen and (max-width: 490px){
    .order-history{
        &__order-header{
            flex-wrap: wrap;
            & .order-history__right-wrap{
                margin-left: auto;
            }
        }
        &__product{
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
        &__order-number{
            font-size: 12px;
            width: 100%;
        }
        &__value-title{
            font-size: 14px;
        }
        &__product-name{
            font-size: 12px;
            margin-bottom: 6px;
        }
        &__product-model{
            font-size: 11px;
        }
        &__product-quantity, &__product-price{
            font-size: 14px;
        }
        &__right-wrap{
            min-width: 165px;
        }
        &__product-img{
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;
            margin-right: 10px;
        }
    }
    .order-history-footer{
        flex-direction: column;
        align-items: center;
        &__info-section{
            text-align: center;
            margin-bottom: 18px;
        }
        &__price-item{
            margin-bottom: 6px;
        }
        &__price-label, &__price{
            font-size: 14px;
        }
        &__info-title, &__info{
            font-size: 11px;
        }
    }
}

@media only screen and (max-width: 490px){
    .order-history{
        &__product-body{
            flex-direction: column;
        }
    }
}
