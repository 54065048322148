@import '../../style/vars';
@import '../../style/mixins';

.warranty {
  max-width: 1270px;
  margin: 70px auto 100px auto;
  // .warranty__main-title

  &__main-title {
    @include title;
    color: $primary;
    margin-bottom: 70px;
    text-align: center;
  }

  // .warranty__text-container

  &__text-container {
    margin-bottom: 50px;
    & .warranty__p{
      &:not(:last-child){
        margin-bottom: 10px;
      }
      & a{
        color: $additional;
      }
    }
  }

  // .warranty__p

  &__p {
    @include main-text;
  }

  // .warranty__title

  &__title {
    @include description;
    color: $secondary;
    margin-bottom: 40px;
  }

  // .warranty__container

  &__container {
    margin-bottom: 70px;
  }

  // .warranty__p-highlighted

  &__p-highlighted {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;
  }

  // .warranty__list-secondary

  &__list-secondary {
    margin-bottom: 20px;
  }

  // .warranty__li-secondary

  &__li-secondary {
    @include main-text;
    &:before{
      content: '•';
      color: $accent;
      margin-right: 5px;
      font-size: 16px;
    }
  }

  // .warranty__list-main

  &__list-main {
    margin-bottom: 100px;
  }

  // .warranty__li-main

  &__li-main {
    position: relative;
    padding-left: 30px;
    @include main-text;
    &::before{
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 3px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
      background-size: 14px 14px;
    }
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  // .warranty__highlighted-container

  &__highlighted-container {
    padding: 58px 100px;
    border-radius: 8px;
    background: $tint;
  }

  // .warranty__hl-title

  &__hl-title {
    color: $primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  // .warranty__hl-p

  &__hl-p {
    @include main-text;
    color: #000;
    &:not(:last-child){
      margin-bottom: 6px;
    }
  }
}

@media only screen and (max-width: 880px){
  .warranty{
    margin: 40px auto 60px auto;
    &__p-highlighted, &__hl-title{
      font-size: 14px;
    }
    &__highlighted-container{
      padding: 30px;
    }
    &__main-title{
      margin-bottom: 40px;
    }
    &__text-container{
      margin-bottom: 30px;
    }
    &__container{
      margin-bottom: 40px;
    }
    &__title{
      margin-bottom: 24px;
    }
    &__list-main{
      margin-bottom: 60px;
    }
    &__hl-title{
      margin-bottom: 20px;
    }
  }
}
