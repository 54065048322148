@import '../../style/vars';
@import '../../style/mixins';

.blog {
    padding: 70px 0 100px 0;
    // .blog__title

    &__title {
        @include title;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    // .blog__content-wrap

    &__content-wrap {
        margin-bottom: 70px;
    }

    // .blog__filter-wrap

    &__filter-wrap {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
    }

    // .blog__filter

    &__filter {
        min-width: 190px;
        position: relative;
        margin-right: 10px;
        &.active{
            & .blog__filter-drop-content{
                max-height: 400px;
                padding: 8px 0;
                z-index: 1;
            }
        }
    }

    // .blog__filter--sections

    &__filter--sections {
    }

    // .blog__filter-head

    &__filter-head {
        display: flex;
        align-items: center;
        cursor: pointer;
        & .arrow-down{
            margin-left: 10px;
        }
    }

    // .blog__filter-title

    &__filter-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $text-color;
        margin-right: 10px;
    }

    // .blog__filter-selected

    &__filter-selected {
        cursor: pointer;
        @include main-text;
        display: flex;
        align-items: center;
    }

    // .blog__filter-drop-content

    &__filter-drop-content {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
        overflow: hidden;
        background: $white;
        border-radius: 8px;
        max-height: 0;
        padding: 0 0;
        transition: .3s;
    }

    // .blog__filter-drop-item

    &__filter-drop-item {
        width: 100%;
        height: 40px;
        background: $white;
        cursor: pointer;
        display: flex;
        align-items: center;
        @include secondary-text;
        padding-left: 16px;
        &:hover{
            background: $tint;
            color: $primary;
        }
    }

    // .blog__filter--subject

    &__filter--subject {
    }

    // .blog__content

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 2%;
    }
}
.blog-item {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(15, 15, 15, 0.1);
    transition: 0.2s;
    margin-bottom: 10%;
    &:hover{
        box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    }
    // .blog-item__image-wrap

    &__image-wrap {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        height: 150px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .blog-item__category-wrap

    &__category-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    // .blog-item__category-section

    &__category-section {
        padding: 5px 18px;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: $white;
        text-align: center;
        min-width: fit-content;
    }

    // .blog-item__category-section--news

    &__category-section--news {
        background: #F2C94C;
    }

    // .blog-item__category-subject

    &__category-subject {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $secondary-text-color;
        margin-left: 10px;
    }

    // .blog-item__category-title

    &__category-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: $secondary;
        margin-bottom: 20px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    // .blog-item__category-subtitle

    &__category-subtitle {
        font-size: 14px;
        line-height: 150%;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    // .blog-item__category-section--cases

    &__category-section--cases {
        background:#923CB7;
    }

    // .blog-item__category-section--recc

    &__category-section--recc {
        background: #488CDD;
    }

    // .blog-item__category-section--useful

    &__category-section--useful {
        background: #82C0CC;
    }

    // .blog-item__info
    &__info{
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 1280px){
    .blog{
        &__content{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
@media only screen and (max-width: 880px){
    .blog-item{
        &__category-title{
            font-size: 14px;
        }
        &__category-section{
            font-size: 11px;
            padding: 5px 5px;
        }
        &__category-subject{
            font-size: 10px;
        }
        &__category-subtitle{
            font-size: 12px;
        }
    }
    .blog{
        padding: 60px 0;
        &__filter-title{
            font-size: 14px;
        }
        &__filter-selected{
            font-size: 11px;
        }
        &__filter-drop-item{
            font-size: 11px;
        }
        &__filter{
            min-width: auto;
        }
        &__content-wrap{
            margin-bottom: 40px;
        }
        &__title{
            margin-bottom: 40px;
        }
    }
}
@media only screen and (max-width: 720px){
    .blog{
        &__content{
            grid-template-columns: 1fr 1fr;
        }
    }
}
@media only screen and (max-width: 450px){
    .blog{
        &__content{
            grid-template-columns: 1fr !important;
        }
    }
}

