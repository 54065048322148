.main-page{
  width: 100%;

  &__hidden-title {
    position: absolute;
    visibility: hidden;
  }
}

.recommended-slider {
  width: 100%;
}

.product-slider-title {
  color: #2c599d;
  margin-bottom: 50px;
}

@media (max-width: 880px) {
  .product-slider-title {
    font-size: 20px;
  }

}

@media (min-width: 880px) {
  .product-slider-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0;
  }
}
