@import '../../../style/vars';
@import '../../../style/mixins';

.production-wrap {
    padding: 100px 0;

    .production-btn {
        display: block;
        margin: 0 auto;
    }
}

.production {

    // .production__title

    &__title {
        @include title;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    &__wrapper {
        width: 100%;
        height: 500px;
        overflow: hidden;
        margin-bottom: 100px;
    }

    &__items {
        width: 100%;
        display: flex;
    }

    &__item-body{
        position: absolute;
        top: 78%;
        left: 0;
        right: 0;
        height: fit-content;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: .5s;
    }

    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 16.65%;
        height: 500px;
        transition: all 500ms ease;
        flex-grow: 2;
        filter: drop-shadow(0px 24px 48px rgba(44, 89, 157, 0.16));
        &:not(:last-child){
            margin-right: 2.5px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(26, 59, 112, 0.75) 0%, #091A34 100%);
            mix-blend-mode: multiply;
            border-radius: 8px;
            z-index: 1;
            opacity: 0;
            transition: .3s;
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
            mix-blend-mode: multiply;
            border-radius: 8px;
            z-index: 1;
            opacity: 1;
            transition: .3s;
        }
    }

    &__item-block {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        border-radius: 8px;
    }

    &__item-image {
        height: 500px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    &__item-title {
        color:$white;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        position: relative;
        width: 100%;
        &::after{
            content: '';
            position: absolute;
            width: 279px;
            border-top: 2px solid #FFFFFF;
            left: 0;
            right: 0;
            bottom: -16px;
            margin: 0 auto;
            opacity: 0;
            transition: .3s;
        }
    }
    &__item-subtitle-wrap {
        max-width: 431px;
        margin-top: 66px;
        margin-bottom: 55px;
        padding: 1.5em;
    }
    &__item-subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: $white;
        text-align: center;
        opacity: 0;
        transition: .3s;
        -webkit-line-clamp: 7;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &:not(:last-child){
            margin-bottom: 1em;
        }
    }

    // .production__item-button

    &__item-button {
        background: $accent;
        border-radius: 5px;
        border: none;
        padding: 16px 60px;
        @include button-text;
        text-align: center;
        color: $white;
        cursor: pointer;
        position: relative;
        backface-visibility: hidden;
        transform: translateZ(0);
        opacity: 0;
        transition: .3s;
        &:before {
            content: '';
            border-radius: 5px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
            animation-fill-mode: forwards;
        }
        &:hover{
            background: #F88125;
        }
        &:hover:before, &:focus:before, &:active:before {
            -webkit-animation-name: hvr-ripple-out;
            animation-name: hvr-ripple-out;
            animation-fill-mode: forwards;
            // opacity: 0;
        }
    }

    & .production__items:hover .production__item {
        width: 13% !important;
    }

    & .production__items:hover .production__item:hover {
        width: 40% !important;
        & .production__item-body {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
        &::before{
            opacity: 1;
        }
        &::after{
            opacity: 0;
        }
        & .production__item-title {
            &::after {
                opacity: 1;
            }
        }
        & .production__item-subtitle {
            opacity: 1;
        }
        & .production__item-button {
            opacity: 1;
        }
    }
}


@media only screen and (max-width: 1400px){
    .production{
        &__items{
            width: 100%;
            max-width: 1530px;
            display: grid;
            column-gap: 1.33%;
            grid-template-columns: 32.5% 32.5% 32.5%;
            padding: 0 10px;
        }
        &__item{
            position: relative;
            width: 100%;
            height: 330px;
            margin-bottom: 40px;
            transition: .3s;
        }
        &__wrapper{
            height: fit-content;
        }
        &__item-body{
            top: 45%;
        }
        &__item-block{
            height: 100%;
        }
        &__item-image{
            height: 100%;
        }
        & .production__items:hover .production__item {
            width: 100% !important;
        }
        &__item-subtitle-wrap {
            margin: 0;
            max-height: 180px;
            overflow: hidden;
            padding-bottom: 0;
            margin-bottom: 1.5em;
        }
        &__item-title{
            font-size: 20px;
        }
        &__item-subtitle {
            font-size: 14px;
        }
        & .production__items:hover .production__item:hover {
            width: 100% !important;
            & .production__item-body {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
            &::before{
                opacity: 1;
            }
            &::after{
                opacity: 0;
            }
            & .production__item-title {
                &::after {
                    opacity: 1;
                }
            }
            & .production__item-subtitle {
                opacity: 1;
            }
            & .production__item-button {
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 880px){
    .production .production__wrapper{
        & .production__items{
            grid-template-columns: 49.5% 49.5%;
        }
    }
    .production {
        &__item-button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
        }
        &__wrapper {
            margin-bottom: 40px;
        }
        &__title{
            margin-bottom: 40px;
            text-transform: uppercase;
        }
    }
    .production-wrap{
        padding: 50px 0;
    }
}

@media only screen and (max-width: 600px){
    .production .production__wrapper{
        & .production__items{
            grid-template-columns: 100%;
        }
    }
    .production{
        &__items {
            padding: 0 20px;
        }

        &__item{
            height: 280px;
        }
    }
}

@media only screen and (max-width: 490px){
    .production{
        &__item {
            height: 188px;
        }

        &__item-subtitle{
            -webkit-line-clamp: 3;
        }

        &__item-button {
            display: none;
        }

        & .production__items:hover .production__item:hover {
            & .production__item-body{
                height: 100%;
                padding: 20px 0;
            }
        }
    }
}