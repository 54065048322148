$desktopWidth            : 1280px;
$smDesktopWidth          : 980px;
$tableWidth              : 768px;
$phoneWidth              : 480px;
$smPhoneWidth            : 320px;


//=========================================================================== TOTAL CONTROL Color Scheme
$accent                  : #FB9B51;
$hover                   : #F88125;
$primary                 : #1A3B70;
$secondary               : #2C599D;
$additional              : #5C83C4;
$text-color              : #273043;
$secondary-text-color    : #8D8D8D;
$muted                   : #DCDCDC;
$white                   : #FFFFFF;
$shade                   : #DBE2EA;
$tint                    : #EBF4F8;
$success                 : #14A38B;
$error                   : #FF7171;
$muted-text              : #979797;
