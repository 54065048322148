.pagination-wrap {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination, .pagination-wrap {
  display: flex;
}

.pagination__arrow--left {
  margin-right: 15px;
}

.pagination__arrow--right {
  margin-left: 15px;
}

.pagination__arrow {
  width: 32px;
  height: 32px;
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.pagination__item, .pagination__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination__items {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination__item:not(:last-child) {
  margin-right: 15px;
}

.pagination__item, .pagination__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination__item {
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0);
  font-size: 15px;
  line-height: 20px;
  color: #2c599d;
  cursor: pointer;
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
}

.pagination__item.active {
  -webkit-filter: drop-shadow(1px 2px 10px rgba(235,244,248,.75));
  filter: drop-shadow(1px 2px 10px rgba(235,244,248,.75));
  border-color: #ebf4f8;
  font-weight: 600;
}
