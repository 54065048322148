@import "../../style/vars";
@import "../../style/mixins";

.basket-form {
  &__comment {
    display: none;

    &.active {
      display: block;
    }
  }

  & .form__input {
    font-size: 14px;
  }
  & .radio-wrap {
    & .radio {
      font-size: 14px !important;
    }
  }
  & .way-for-pay {
    .radio-wrap {
      margin-bottom: 8px;
    }
  }
  & .basket-form__section--checkbox-wrap {
    & .checkbox-wrap {
      margin-bottom: 20px;
    }
    & .checkbox {
      font-size: 14px;
      line-height: 150%;
    }
    & .comment-add {
      margin-bottom: 20px;
      &__text {
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        margin-left: 12px;
      }
    }
  }
  & .basket-form__section--entity-footer {
    & .checkbox-wrap {
      margin-bottom: 20px;
    }
    & .checkbox span {
      font-size: 14px;
      line-height: 150%;
    }
  }

  & .comment-add {
    padding: 0;
    background: none;
    cursor: pointer;
    & span {
      transition: 0.2s;
    }
    &:hover {
      & span {
        color: $secondary;
      }
    }
  }

  // .basket-form__delivery-form

  &__delivery-form {
    & .radio-wrap {
      position: relative;
      margin-bottom: 14px;
      & .radio {
        @include main-text;
        & .basket-form__post {
          margin-left: 5px;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
        }
        & .days {
          color: #8d8d8d;
          margin: 0 0.5em;
        }
        & img {
          margin-right: 6px;
        }
      }
    }
    & .basket-form__dropdown-selector,
    & .basket-form__address-delivery {
      height: 0;
      overflow: hidden;
      transform: scaleY(0);
      transform-origin: top;

      &.active {
        height: fit-content;
        transform: scaleY(1);
      }
    }
    & .basket-form__address-delivery {
      display: flex;
      justify-content: space-between;
      max-width: 740px;
      &.active {
      }

      & .form__label {
        & .form__input {
          width: 100%;
          &:focus ~ span,
                        //&:not(:focus):valid ~ span,
                    &:not(:placeholder-shown) ~ span {
            //top: 3px !important;
            font-size: 11px;
          }
        }
      }
      & .form__label:first-child {
        max-width: 334px;
        width: 334px;
      }
      & .form__label:not(:first-child) {
        max-width: 100px;
      }
    }
  }

  // .basket-form__dropdown-selector

  &__dropdown-selector {
    position: relative;
    margin-bottom: 0;
    & .form__input {
      margin-bottom: 0;
    }

    &.active {
      overflow: visible;
      // margin-bottom: 24px;
      z-index: 2;
      & .basket-form__dropdown {
        position: absolute;
        transform: scaleY(1);
        height: fit-content;
        background: $white;
        box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7),
          0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198),
          0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275),
          0 2.12866px 10.0172px rgba(219, 226, 234, 0.035),
          0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725),
          0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
      }
    }
  }

  // .basket-form__dropdown-selector--city

  &__dropdown-selector--city {
    margin-left: 0;
    transform: scaleY(1) !important;
    // padding: 10px 16px !important;
    height: fit-content !important;
    margin-bottom: 20px;
    & .form__input {
      margin-bottom: 0 !important;
    }
  }

  // .basket-form__select-input

  &__select-input {
    @include main-text;
    width: 282px;
  }

  // .basket-form__dropdown

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background: $white;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    @include scroll-style;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.3s;
  }

  // .basket-form__dropdown-item

  &__dropdown-item {
    width: 100%;
    @include main-text;
    padding: 10px 16px;
    position: relative;
    cursor: pointer;
    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        height: 1px;
        background: #dbe2ea;
        width: calc(100% - 32px);
      }
    }
    &:hover {
      background: #ebf4f8;
      color: #2c599d;
    }
  }

  // .basket-form__post

  &__post {
  }

  // .basket-form__delivery-price

  &__delivery-price {
    margin-left: auto;
    @include main-text;
  }

  // .basket-form__address-delivery

  &__address-delivery {
  }

  // .basket-form__delivery-price--free

  &__delivery-price--free {
  }

  // .basket-form__payment-form

  &__payment-form {
    & .radio-wrap__drop-section {
      margin-left: 34px;
      & .form__label {
        .standart {
          //width: max-content;
          top: 40px;
        }
        & .form__input {
          margin-left: 0;
          &:focus ~ span,
                        //&:not(:focus):valid ~ span,
                    &:not(:placeholder-shown) ~ span {
            top: 0;
            font-size: 11px;
          }
        }
      }
    }
    & .radio-wrap {
      margin-bottom: 16px;
      & .radio {
        @include main-text;
      }
    }

    & .radio-wrap__drop-section-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 19px;
    }

    & .radio-wrap__drop-section {
      margin-bottom: 24px;
      & input {
        display: block;
        border: 1px solid #dbe2ea;
        padding: 10px 16px;
        width: 500px;
        @include main-text;
        margin-left: 34px;
        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.basket {
  margin: 70px 0;
  // .basket__body

  &__body {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.basket-form-wrap {
  background: #ffffff;
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  max-width: 750px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.basket-form-container-wrap {
  width: 100%;
  max-width: 550px;
  overflow: hidden;
}
.basket-form-container {
  display: flex;
  //max-width: max-content;
  //width: 200%;
}
.basket-form {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transition: 0.3s;
  &.active {
    //transform: translatex(-50%);
  }
  & .form__input {
    margin-bottom: 20px;
  }
  & .form__input {
    margin-left: 0;
  }

  // .basket-form__header

  &__header {
    max-width: 550px;
    width: 100%;
    margin-top: 60px;
  }

  // .basket-form__body

  &__body {
    max-width: 550px;
    width: 100%;
  }

  // .basket-form__body--individual

  &__body--individual {
    display: none;

    &.active {
      display: block;
    }
  }

  &__body--individual.active {
  }
  &__body--entity {
  }
  &__body--entity.active {
  }

  // .basket-form__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: $secondary;
    margin-bottom: 50px;
    text-transform: uppercase;
  }

  // .basket-form__section

  &__section {
    margin-bottom: 50px;
  }

  // .basket-form__section-title

  &__section-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: $primary;
    margin-bottom: 20px;
  }

  // .basket-form__delivery-form

  &__delivery-form {
  }

  // .basket-form__dropdown-selector

  &__dropdown-selector {
  }

  // .basket-form__dropdown-selector--city

  &__dropdown-selector--city {
  }

  // .basket-form__select-input

  &__select-input {
  }

  // .basket-form__dropdown

  &__dropdown {
    display: none;

    &.active {
      display: block;
    }
  }

  // .basket-form__dropdown-item

  &__dropdown-item {
  }

  // .basket-form__address-delivery

  &__address-delivery {
  }

  // .basket-form__delivery-price

  &__delivery-price {
  }

  // .basket-form__post

  &__post {
  }

  // .basket-form__delivery-price--free

  &__delivery-price--free {
  }

  // .basket-form__confirm-btn

  &__confirm-btn {
    margin-left: 28px;
    margin-bottom: 60px;
  }

  // .basket-form__body--entity

  &__body--entity {
    height: 500px;
    display: none;
    &.active {
      height: fit-content;
      display: block;
    }
  }

  // .basket-form__calendar-section

  &__calendar-section {
    margin-bottom: 20px;
  }

  // .basket-form__calendar-title

  &__calendar-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 20px;
  }

  // .basket-form__calendar

  &__calendar {
  }

  // .basket-form__textarea-label

  &__textarea-label {
    font-size: 14px;
    line-height: 150%;
  }
}
.user-type-selector {
  display: flex;

  // .user-type-selector__btn

  &__btn {
    padding: 9px 22px;
    position: relative;
    z-index: 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid $primary;
    margin-bottom: 20px;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    &:last-child {
      margin-left: -10px;
    }
    &.active {
      background: $primary;
      color: $white;
      z-index: 1;
    }
  }
}

.basket-container {
  position: relative;

  max-width: 620px;
  width: 100%;

  // .basket-container__body

  &__body {
    height: fit-content;
    position: sticky;
    top: 140px;
    background: $tint;
    border: 1px solid $shade;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 60px;
  }

  // .basket-container__title

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: $secondary;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  // .basket-container__products

  &__products {
    margin-bottom: 30px;
  }

  // .basket-container__result

  &__result {
  }

  // .basket-container__result-item

  &__result-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &--sum {
      margin-top: 20px;
      margin-bottom: 55px;
    }
  }

  // .basket-container__left-container

  &__left-container {
  }

  // .basket-container__result-title

  &__result-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
  }

  // .basket-container__result-subtitle

  &__result-subtitle {
    font-size: 14px;
    line-height: 120%;
  }

  // .basket-container__delivery-price

  &__delivery-price {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    min-width: 95px;
    text-align: right;
  }

  // .basket-container__bonus

  &__bonus {
    display: flex;
    align-items: center;
  }

  // .basket-container__bonus-label

  &__bonus-label {
    margin-right: 10px;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  // .basket-container__bonus-value

  &__bonus-value {
    font-size: 12px;
    line-height: 150%;
    color: $additional;
    text-decoration-line: underline;
  }

  // .basket-container__sum

  &__sum {
    display: flex;
  }

  // .basket-container__sum-label

  &__sum-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-right: 30px;
  }

  // .basket-container__sum-val

  &__sum-val {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $secondary;
    min-width: 95px;
    text-align: right;
  }

  // .basket-container__confirm-btn

  &__confirm-btn {
    width: fit-content;
    margin: 0 auto 30px auto;
  }

  // .basket-container__user-agreement

  &__user-agreement {
    font-size: 11px;
    line-height: 120%;
    color: $secondary-text-color;
    text-align: center;
    & a {
      text-decoration: underline;
      color: $secondary-text-color;
    }
  }
}
.basket-product {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $shade;
  padding: 20px;
  // .basket-product__img

  &__img {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .basket-product__section-wrap

  &__section-wrap {
    width: calc(100% - 140px);
  }

  // .basket-product__top-section

  &__top-section {
    display: flex;
    justify-content: space-between;
    height: 50%;
    & .close-btn {
      cursor: pointer;
    }
  }

  // .basket-product__name

  &__name {
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    max-width: 274px;
  }

  // .basket-product__bottom-section

  &__bottom-section {
    display: flex;
    justify-content: space-between;
  }

  // .basket-product__price

  &__price {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}
.close-btn {
}
.basket-counter {
  display: flex;
  width: 100px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #eeeded;
  border-radius: 4px;
  // .basket-counter__dec

  &__quantity {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
  }

  &__dec {
    width: 35%;
  }
  & input {
    width: 30%;
    text-align: center;
    font-size: 16px;
    line-height: 150%;
  }
  // .basket-counter__inc

  &__inc {
    width: 35%;
  }
  &__dec,
  &__inc {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s;
    &:hover {
      background: #5c83c4;
      color: $white;
    }
  }
}

@media only screen and (max-width: 1390px) {
  .basket-container {
    &__body {
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .basket {
    &__body {
      flex-direction: column;
      align-items: center;
    }
  }
  .basket-form-wrap {
    margin-bottom: 36px;
  }

  .basket-container {
    max-width: 750px;
    padding: 0;
  }
}

@media only screen and (max-width: 880px) {
  .basket {
    margin: 40px 0;
  }
  .basket-form {
    &__header {
      margin-top: 36px;
      padding: 0 10px;
    }
    &__title {
      margin-bottom: 24px;
      font-size: 16px;
    }
    &__section-title {
      font-size: 16px;
    }
    &__section {
      margin-bottom: 32px;
    }
  }

  .basket-form-wrap {
    margin-right: 0;
  }

  .user-type-selector {
    &__btn {
      font-size: 12px;
      margin-bottom: 36px;
    }
  }
  .form__label {
    & .form__input {
    }
  }
  .basket-form {
    &__delivery-form {
      & .radio-wrap {
        margin-bottom: 10px;
        & .radio {
          font-size: 12px !important;
        }
      }
    }
    &__delivery-price {
      font-size: 12px;
    }
    &__dropdown-item {
      font-size: 12px;
    }
  }
  .basket-container {
    padding-top: 28px;
    padding-bottom: 28px;
    &__title {
      margin-bottom: 24px;
      font-size: 16px;
    }
    &__confirm-btn {
      display: flex;
      align-items: center;
    }
    &__result-item--sum {
      margin-bottom: 32px;
    }
  }
  .basket-product {
    padding: 12px;
    & .close-btn {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
    &__img {
      width: 80px;
      height: 80px;
    }
    &__name {
      font-size: 14px;
    }
    &__price,
    &__delivery-price,
    &__sum-label,
    &__sum-val {
      font-size: 16px;
    }
    &__section-wrap {
      width: calc(100% - 100px);
    }
  }
  .basket-form {
    &__calendar-title,
    &__textarea-label {
      font-size: 12px;
    }
    & .radio-wrap {
      & .radio {
        font-size: 12px !important;
      }
    }
    & .checkbox-wrap {
      margin-bottom: 8px !important;
    }
    & .checkbox-wrap .checkbox span {
      font-size: 12px;
    }
    & .comment-add {
      margin-bottom: 8px !important;
      &__text {
        font-size: 12px !important;
      }
    }
    &__confirm-btn {
      margin-bottom: 36px;
    }
  }

  .basket-container {
    &__body {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 610px) {
  .basket-form {
    &__body {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__address-delivery {
      flex-direction: column;

      & > .form__label {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .basket-form {
    &__delivery-form {
      & .radio-wrap {
        & .radio {
          flex-wrap: wrap;
          &::before {
            align-self: flex-start;
          }
          &::after {
            top: 4px;
          }
        }
      }
    }
  }
  .basket-container {
    &__result-item {
      flex-wrap: wrap;
    }
    &__left-container {
      width: calc(100% - 95px);
    }
  }
}

@media only screen and (max-width: 420px) {
  .basket-product {
    &__bottom-section {
      flex-direction: column;
    }
    &__price {
      margin-bottom: 24px;
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .user-type-selector {
    justify-self: center;
    &__btn {
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      justify-content: center;
    }
  }
  .basket-form {
    &__confirm-btn {
      margin: 0 auto 36px auto;
      display: block;
    }
  }
}
