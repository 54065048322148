@import '../../../style/vars';
@import '../../../style/mixins';

.main-slider-wrap {
    width: 100%;
    position: relative;
}
.slider__button{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 50px;
    height: 50px;
    background: rgba(235, 244, 248, 0.3);
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    transition: .3s;
    & svg path{
        transition: .2s;
    }
    &:hover{
        background: rgba(235, 244, 248, 0.8);
        & svg path{
            fill: $primary;
        }
    }
}
.slider__prev{
    left: 3.5%;
    & svg{
        transform: translateX(-2px);
    }
}
.slider__next{
    right: 3.5%;
    & svg{
        transform: translateX(2px);
    }
}

.main-sl-dots{
    display: flex !important;
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
    & li{
        cursor: pointer;
        &:not(:last-child){
            margin-right: 12px;
        }
        & button{
            font-size: 0;
            width: 30px;
            height: 3px;
            border-radius: 2px;
            background-color: $white;
            border: none;
            display: block;
            cursor: pointer;
        }
    }
    & li.slick-active{
        & button{
            background-color: $accent;
        }
    }
}
.main-slider {
    width: 100%;
    margin-bottom: 0 !important;

    &__slide-content-wrap {
        display: flex;
        justify-content: space-between;
    }

    &__slide-content {
        margin-bottom: 60px;
        width: 60%;
    }

    // .main-slider__slide-wrap

    &__slide-wrap {
        position: relative;
    }
    // .main-slider__bg

    &__bg{
        width: 100%;
        height: 100%;
        position: relative;
    }

    // .main-slider__slide-bg

    &__slide-bg {
        object-fit: cover;
        width: 100%;
        height: 78vh;
    }
    &__bg-gradient{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(45, 50, 88, 0.45);
    }
    // .main-slider__slide

    &__slide {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: calc(100% - 120px);
        max-width: 1540px;
        padding-right: 60px;
        padding-left: 60px;
    }

    &__product-image-wrap {
        position: relative;
        //top: 50%;
        //right: 0;
        //transform: translate(-10%, -30%);
        align-self: flex-end;
    }

    &__product-image {
        //object-fit: cover;
    }

    &__brand-label {
        margin-bottom: 40px;
    }

    &__label {
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(180deg, #FFB073 0%, #FF7912 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--right {
            right: 0;
        }

        &--right-top {
            right: 0;
            top: 0;
            transform: translate(50%, -50%);
        }

        &--double-right {
            top: -50px;
            right: 25%;
        }

        &--double-left {
            top: -50px;
            left: 25%;
        }

        &--large {
            height: 220px;
            width: 220px;

            & .main-slider__label-price {
                font-size: 68px;
            }
        }

        &--small {
            height: 150px;
            width: 150px;

            & .main-slider__label-price {
                font-size: 50px;
            }
        }

        & p {
            color: $white;
            text-align: center;
        }
    }

    &__label-title {
        font-size: 18px;
    }

    &__label-price {
        font-size: 68px;
        font-weight: bold;
    }

    &__label-currency {
        font-size: 18px;
    }

    &__product-image {

    }

    // .main-slider__slide-content-wrap

    &__slide-content-wrap {
        margin-top: 8%;
    }

    // .main-slider__slide-content

    &__slide-content {
    }

    // .main-slider__slide-title

    &__slide-title {
        @include h1;
        color: $white;
        margin-bottom: 46px;
        position: relative;
        width: 100%;
        max-width: 780px;
        text-transform: uppercase;
        &::after{
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            z-index: 5;
            height: 3px;
            background-image: linear-gradient(to right, #FB9B51, rgba(251, 155, 81, 0.829), rgba(251, 155, 81, 0));
            width: 100%;
        }
    }

    // .main-slider__slide-subtitle

    &__slide-subtitle {
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $white;
        width: 100%;
        max-width: 780px;
        text-transform: uppercase;

        & span {
            color: $white;
            font-weight: bold;
        }
    }

    &__slide-description {
        color: $white;
        font-size: 18px;
        margin: 20px 0 40px;
    }

    // .main-slider__bottom-box

    &__bottom-box {
        display: flex;
        position: absolute;
        align-items: center;
        width: fit-content;
        bottom: 49px;
        height: 65px;
        border-radius: 13px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #2C599D;
    }

    // .main-slider__brand-logo-container

    &__brand-logo-container {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 34px;
        padding-right: 34px;
        &:not(:last-child){
            border-right: 1px solid #4277C8;
        }
        &:first-child{
            padding-left: 65px;
        }
        &:last-child{
            padding-right: 65px;
        }
    }

    // .main-slider__brand-logo-wrap

    &__brand-logo-wrap {
        display: flex;
        justify-content: center;
        position: relative;

    }
}

@media only screen and (max-width: 1520px){
    .main-slider{
        &__label {
            &--large {
                height: 180px;
                width: 180px;

                & .main-slider__label-price {
                    font-size: 40px;
                }
            }

            &--small {
                height: 120px;
                width: 120px;

                & .main-slider__label-price {
                    font-size: 30px;
                }
            }
        }

        .slider__prev{
            left: 15px;
        }
        .slider__next{
            right: 15px;
        }
        .slider__button{
            width: 40px;
            height: 40px;
            & svg{
                width: 10px;
            }
        }
        &__slide-content-wrap{
            width: 100%;
        }
    }
}

@media (max-width: 1340px) {
    .main-slider {
        &__slide-content {
            width: 100%;
        }

        &__product-image-wrap {
            display: none;
        }
    }
}

@media only screen and (max-width: 880px){
    .main-slider{
        &__slide-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__slide-title{
            font-size: 26px;
            line-height: 130%;
            text-align: center;
        }

        &__slide-subtitle{
            font-size: 18px;
            line-height: 130%;
            text-align: center;

            & span {
                color: inherit;
                font-weight: bold;
            }
        }
        &__slide-description {
            font-size: 10px;
            text-align: center;
        }
        &__slide-content{
            & .btn-accent{
                margin: 0 auto;
                display: block;
            }
        }
        &__slide-wrap{

        }
        &__slide-content-wrap{
            margin: 0;
        }
        &__brand-logo-wrap{
            margin: 0 3px;
            & img{
                // width: 70%;
            }
        }
        &__bottom-box{
            height: 38px;
        }
        &__slide{
            width: 100%;
            display: flex;
            align-items: center;
        }
        &__brand-logo-wrap{
            height: 20px;
            align-items: center;
            & img{
                width: auto;
                height: 100%;
                object-fit: contain;
            }
        }
        &__brand-logo-container {
            padding-left: 10px;
            padding-right: 10px;
            &:not(:last-child){
                border-right: 1px solid #4277C8;
            }
            &:first-child{
                padding-left: 13px;
                & img{
                    width: 67.5px;
                    height: 13px;
                }
            }
            &:nth-child(2){
                & img{
                    width: 49.5px;
                    height: 14px;
                }
            }
            &:nth-child(3){
                & img{
                    width: 48.5px;
                    height: 12px;
                }
            }
            &:last-child{
                padding-right: 13px;
                & img{
                    width: 48.5px;
                    height: 20px;
                }
            }
        }
        & .slider__button{
            width: 30px;
            height: 30px;
            & svg{
                width: 7.5px;
            }
        }
    }

}
@media only screen and (max-width: 576px){
    .main-slider{
        &__slide{
            padding-right: 40px;
            padding-left: 40px;
        }
    }

    .main-sl-dots li:not(:last-child) {
        margin-right: 5px;
    }
}
@media only screen and (max-width: 476px){
    .main-slider{
        .slider__prev{
            left: 7px;
        }
        .slider__next{
            right: 7px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .main-slider {
        &__bottom-box {
            bottom: 34px;
        }
        &__slide-title {
            margin-bottom: 20px;
        }

        &__slide-content {
            transform: translateY(-30px);
        }
        &__slide-bg {
            height: 91vh;
        }
    }
}



@media only screen and (max-width: 340px) {
    .main-slider {
        &__slide-content-wrap {
            margin-top: 100px;
        }
    }
}
