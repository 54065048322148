@import '../../style/vars';

.for-clients {
    padding: 70px 0;
    // .for-clients__title

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    // .for-clients__section-title

    &__section-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $secondary;
        margin-bottom: 70px;
        padding: 0 40px;
    }

    &__slider-wrap, & .user-documents__section-wrap {
        max-width: 1760px;
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;
    }

    // .for-clients__slider-wrap

    &__slider-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 70px;
        & .arrow {
            transform: scale(1);
            transition: .2s;
            &:active {
                transform: scale(0.8);
            }
        }

        .slider__next-sec {
            right: -65px;
        }

        .slider__prev-sec {
            left: -65px;
        }
    }

    // .for-clients__slider

    &__slider {
        max-width: 1550px;
        width: calc(100% - 60px);
        & .slick-slide  > div {
            display: flex;
            justify-content: center;
        }
    }

    // .for-clients__slider-item

    &__slider-item {
        max-width: 288px;
        & img {
            display: block;
        }
    }
    & .user-documents {
        width: 100%;
        margin-bottom: 100px;

        // .user-documents__body

        &__body {
            display: flex;
        }

        // .user-documents__section

        &__section {
            &:not(:last-child){
                margin-right: 280px;
            }
        }

        // .user-documents__section-title

        &__section-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 40px;
            color: $secondary;
            padding: 0;
        }

        // .user-documents__documents

        &__documents {
        }

        &__document-wrap {
            display: flex;
            margin-bottom: 10px;

            & img{
                margin-right: 30px;
            }
        }

        // .user-documents__document

        &__document {
            display: flex;
            align-items: center;
            width: 100%;
            &:not(:last-child){
                margin-bottom: 12px;
            }

            & svg {
                margin-right: 20px;
            }

            & .download-btn{
                cursor: pointer;
                & path{
                    transition: .2s;
                }
                &:hover{
                    & path{
                        fill: $secondary;
                    }
                }
            }
        }

        // .user-documents__document-name

        &__document-name {
            font-size: 16px;
            line-height: 150%;
        }
    }


    & .user-documents {
        &__section {
            display: flex;
            flex-wrap: wrap;
        }
        &__documents-wrap {
            width: 50%;
        }
        &__documents {
            max-width: 584px;
            margin-bottom: 40px;
            & .user-documents__section-title {
                text-transform: uppercase;
                margin-bottom: 40px;
            }
        }
        &__document {
            justify-content: flex-start;
        }
    }
}
.download-btn {
    min-width: 24px;
    min-height: 28px;
}


@media only screen and (max-width: 880px){
    .for-clients {
        & .user-documents{
            margin-bottom: 70px;
            &__body{
                flex-direction: column;
                align-items: center;
            }
            &__section{
                &:not(:last-child){
                    margin-bottom: 38px;
                }
            }
            &__section-title{
                font-size: 16px;
                margin-bottom: 24px;
            }
            &__document{
                & img{
                    width: 16px;
                    height: 16px;
                }
            }
            &__document-name{
                font-size: 14px;
            }
        }
        padding: 40px 0;
        &__title {
            font-size: 24px;
            margin-bottom: 32px;
        }
        &__section-title {
            font-size: 18px;
            margin-bottom: 32px;
        }
        & .user-documents__documents {
            margin-bottom: 32px;
            & .user-documents__section-title {
                margin-bottom: 24px;
            }
        }
    }
}

@media only screen and (max-width: 790px){
    .for-clients {
        &__slider {
            & .main-sl-dots {
                bottom: -20px;
                & li:not(.slick-active){
                    & button {
                        background: #1A3B70;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 710px){
    .for-clients {
        & .user-documents__documents-wrap {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 550px){
    .for-clients {
        &__section-title {
          text-align: center;
        }

        &__slider-wrap {
            & .arrow {
                display: none;
            }
        }
        &__slider {
            width: 100%;
        }
    }
    .for-clients {
        .user-documents {

            &__section-wrap {
                padding: 10px;
            }
        }
    }

}
