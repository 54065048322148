$accent: #FB9B51;
$hover: #F88125;
$primary: #1A3B70;
$secondary: #2C599D;
$additional: #5C83C4;
$text-color: #273043;
$secondary-text-color: #8D8D8D;
$muted: #DCDCDC;
$white: #FFFFFF;
$shade: #DBE2EA;
$tint: #EBF4F8;
$success: #14A38B;
$error: #FF7171;

@mixin title {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  @media (max-width: 880px) {
    font-size: 24px;
  }
}

@mixin main-text {
  @media (min-width: 880px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }
  @media (max-width: 880px) {
    font-size: 14px;
    line-height: 150%;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@include keyframes(dash) {
  0% {
    stroke-dashoffset: 1500;
    stroke-width: 1;
  }
  70% {
    stroke-width: 1;
  }
  80% {
    stroke-width: 1;
  }
  90%{
    stroke-width: 1;
  }
  100%{
    stroke-width: 1;
  }
}
.advantages-wrap {
  position: relative;
  background: linear-gradient(180deg, rgba(239, 243, 249, 0) 19.79%, #EFF3F9 80.73%);
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  z-index: 0;
}
.advantages-bg-item {
  position: absolute;
  z-index: -1;

}

.advantages-title {
  @include title;
  color: $primary;
  text-align: center;
  margin-bottom: 70px;
}
.advantages {
  display: grid;
  column-gap: 2%;
  row-gap: 80px;
  grid-template-columns: 32% 32% 32%;
  margin-top: 32px;

  // .advantages__item-wrap

  &__item-wrap {
    text-align: center;
    &:first-child, &:nth-child(2), &:nth-child(4), &:nth-child(5){
      .advantages__ico-wrap::after{
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='192' height='2' viewBox='0 0 192 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1H192' stroke='%23D4DBE2' stroke-dasharray='8 2'/%3E%3C/svg%3E%0A");
        position: absolute;
        right: calc(-150% - 150px);
        height: 2px;
        width: 192px;
      }
    }
  }

  // .advantages__item

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // .advantages__ico-wrap

  &__ico-wrap {
    background: linear-gradient(180deg, #5C83C4 0%, #2C599D 100%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    filter: drop-shadow(2px 6px 10px rgba(92, 131, 196, 0.5));
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    position: relative;
  }

  // .advantages__title

  &__title {
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  // .advantages__subtitle

  &__subtitle {
    @include main-text;
    color: $text-color;
    max-width: 340px;
  }
}
@media only screen and (max-width: 1549px){
  .advantages__ico-wrap::after{
    display: none;
  }
}
@media only screen and (max-width: 1300px){
  .advantages-bg-item--right{
    right: -135px;
    bottom: -50px;
  }
}
@media only screen and (max-width: 960px){
  .advantages-bg-item--left{
    left: -130px;
    top: -50px;
  }
}
@media only screen and (max-width: 880px){
  .advantages-wrap{
    & .advantages-bg-item--left{
      left: -160px;
      top: -65px;
    }
  }

  .advantages-wrap{
    padding-top: 60px;
  }
  .advantages-title{
    margin-bottom: 40px;
    font-size: 24px;
    text-transform: uppercase;
  }
  .advantages{
    row-gap: 40px;
    &__title{
      font-size: 14px;
    }
    &__ico-wrap{
      width: 70px;
      height: 70px;
      margin-bottom: 20px;
      & img{
        width: 42px;
        height: 42px;
      }
    }
    &__title{
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 712px){
  .advantages{
    grid-template-columns: 49% 49%;
  }
}

@media only screen and (max-width: 460px){
  .advantages{
    grid-template-columns: 100%;
  }
}