.sitemap {
  margin: 70px auto;
  max-width: 1270px;

  &__header {
    color: #1A3B70;
    text-align: center;
    margin-bottom: 70px;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &__sections {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__sections-item {
    padding: 20px;
  }
}

.sections-item {
  &__header {
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__list {

  }

  &__list-item {
    position: relative;
    padding-left: 28px;
    margin-bottom: 10px;

    &::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 3px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
      background-size: 18px 18px;
      margin-right: 10px;
    }
  }
}
