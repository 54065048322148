@import '../../style/vars';
@import '../../style/mixins';

.blog-article-wrap {
    margin-right: auto;
    margin-left:  auto;
    max-width: 1130px;
    width: 100%;
    padding-right: 10px;
    padding-left:  10px;
}


.blog-container .product-item-wrap--slide{
    padding-bottom: 100px;
}
.blog-article {

    // .blog-article__top-article

    &__top-article{
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 40px;

        & p {
            //text-indent: 30px;
            margin-bottom: 10px;
             flex-basis: auto;
             display: flex;
              flex-wrap: wrap;
            & img {
              object-fit: contain;
              height: 100% !important;
              margin: 0 auto;
                //margin-left: 30px;
            }
        }

        & li {
            margin-bottom: 4px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
                margin-right: 8px;
            }
        }
    }

    // .blog-article__title

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        color: $secondary;
        margin-bottom: 40px;
    }

    // .blog-article__paragraph

    &__paragraph {
        @include main-text;
        margin-bottom: 1em;
        &:last-child{
            margin-bottom: 40px;
        }
    }

    // .blog-article__section

    &__section {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 40px;
    }


    // .blog-article__p-section

    &__p-section {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        & .blog-article__paragraph{
            &:first-child{
                margin-bottom: 50px;
            }
        }
    }

    // .blog-article__image-box

    &__image-box {
        width: 100%;
        margin-bottom: 40px;
        & img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    & .product-item-wrap .product-item{
        box-shadow: 0 0 4px rgba(15, 15, 15, 0.1);
        // min-width: 360px;
        & .btn-accent{
            padding: 0;
            width: 140px;
            height: 51px;
        }
        & .product-item__price{
            color: $text-color;
            margin: 0;
        }
    }

    // .blog-article__functions-wrap

    &__functions-wrap {
        @include main-text;
    }

    // .blog-article__functions-title

    &__functions-title {
        font-weight: 600;
        color: $secondary;
        margin-bottom: 1em;
    }

    // .blog-article__functions-subtitle

    &__functions-subtitle {
        margin-bottom: 1em;
    }

    // .blog-article__functions-bold

    &__functions-bold {
        font-weight: 600;
        margin-right: 0.4em;
    }
}

.blog-article-header {
  position: relative;
  margin-bottom: 40px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: linear-gradient(90deg, #2C599D 61.47%, rgba(92, 131, 196, 0) 86.08%);
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -2;

    & img {
      height: 100%;
      width: 60%;
      object-fit: cover;
      float: right;
    }
  }

  &__wrap {
    height: 100%;
    margin: auto;
    max-width: 1130px;
    padding: 20px 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__category-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__category-section {
    padding: 5px 18px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    text-align: center;
    min-width: fit-content;

    &--news {
      background: #F2C94C;
    }
  }

  &__subject {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-left: 10px;
  }

  &__title {
    max-width: 600px;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: white;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 880px){
    .blog-article-header {
        &__title {
            font-size: 24px;
            max-width: 400px;
        }

        &::before {
            background: linear-gradient(90deg,#2c599d 61.47%,rgba(92,131,196,0) 86.08%);
        }
    }
    .blog-article{
        &__title{
            font-size: 24px;
        }
        &__paragraph{
            font-size: 14px;
        }
        &__section{
            flex-direction: column;
            align-items: center;
        }
        &__p-section {
            display: flex;
            flex-direction: column;
            & .blog-article__paragraph{
                &:first-child{
                    margin-bottom: 20px;
                }
            }
        }
        &__p-section{
            margin-top: 24px;
            margin-left: 0;
        }
        &__top-article{
            margin-bottom: 20px;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__paragraph {
            &:last-child{
                margin-bottom: 20px;
            }
        }
    }
    .blog-container{
        padding-left: 6px;
        padding-right: 6px;
    }
    .blog-container .product-item-wrap--slide{
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 512px) {
    .blog-article-header {
        &__bg {
            & img {
                width: 100%;
            }
        }

        &::before {
            position: absolute;
            opacity: .8;
            background-color: #2c599d;
        }
    }
}
