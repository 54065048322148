@import '../../../style/vars';

.empty-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60vh;

    &__icon {
        margin-bottom: 25px;
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: $primary;
        text-align: center;
    }
}