@import '../../style/vars';
@import '../../style/mixins';

.cases-article-wrap {
    margin: auto;
    max-width: 1130px;
    width: 100%;
    padding: 0 10px;
}

.cases-article {

  .product-item__subtitle {
    & p:nth-child(2) {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;

    & p {
      margin-bottom: 10px;
    }

    & li {
      margin-bottom: 4px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
        margin-right: 8px;
      }
    }
  }
}

.cases-article-header {
  height: 230px;
  position: relative;
  margin-bottom: 40px;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #2C599D 61.47%, rgba(92, 131, 196, 0) 86.08%);
    z-index: 1;
  }
  // .cases-article-header__bg

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    & img{
      height: 100%;
        width: 100%;
      object-fit: cover;
    }
  }
  & .cases-article-wrap{
    height: 100%;

  }
  // .cases-article-header__title

  &__title {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    max-width: 490px;
  }

  // .cases-article-header__title-head

  &__title-head {
    text-transform: uppercase;
    color: $white;
  }

  // .cases-article-header__title-text

  &__title-text {
    color: $white;
  }
}
.blog-article-wrap {
}
.cases-article-wrap {
}
.cases-article {
  margin-bottom: 100px;
  // .cases-article__text
  &__text{
    margin-bottom: 40px;
    & .cases-article__paragraph:not(:last-child){
      margin-bottom: 1em;
    }
  }

  // .cases-article__paragraph

  &__paragraph {
    @include main-text;

    & p {
      margin-bottom: 10px;
    }

    & li {
      margin-bottom: 4px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
        margin-right: 8px;
      }
    }
  }
  // .cases-article__paragraph--first

  &__paragraph--first{
    margin-bottom: 40px;
  }

  // .cases-article__content-section

  &__content-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  // .cases-article__content-section--with-img

  &__content-section--with-img {

    & .cases-article__text{
      max-width: 620px;
      margin-bottom: 0;
    }

    // .cases-article__image-box

    &__image-box {
      min-width: 310px;
      width: 310px;
      height: fit-content;
      & img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }



  // .cases-article__title

  &__title {
    @include description;
    color: $secondary;
    margin-bottom: 20px;

  }

  // .cases-article__equipment

  &__equipment {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    & .product-item-wrap--blog {
      margin-bottom: 20px;
      &:not(:last-child){
        margin-right: 30px;
      }
    }
  }

  // .cases-article__list

  &__list {

  }

  // .cases-article__list-item

  &__list-item {
    @include main-text;
    &:not(:last-child){
      margin-bottom: 1em;
    }
    &:before{
      content: '•';
      color: $accent;
      margin-right: 5px;
      margin-bottom: 1em;
    }
  }

  // .cases-article__bottom-section

  &__bottom-section {
    display: flex;
    justify-content: space-between;

  }

  // .cases-article__advantages-box

  &__advantages-box {
    width: fit-content;
    max-width: 360px;
  }

  // .cases-article__advantage

  &__advantage {
    margin-bottom: 60px;
  }

  // .cases-article__advantage-value

  &__advantage-value {
    color: $accent;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
  }

  // .cases-article__advantage-description

  &__advantage-description {
    color: $primary;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    text-transform: lowercase;
  }

  // .cases-article__solution-btn

  &__solution-btn {
    width: 268px;
    height: 51px;
    background: $primary;
    color: $white;
    @include button-text;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  // .cases-article__review-box

  &__review-box {
    max-width: 520px;
  }

  // .cases-article__review

  &__review {
    padding: 40px;
    border-radius: 8px;
    background: $tint;
  }

  // .cases-article__review-text

  &__review-text {
    @include main-text;
  }

  // .cases-article__reviewer

  &__reviewer {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $primary;
    text-align: right;
    margin-top: 30px;
  }
}
.product-item-wrap--blog{
  & .product-item {

    // .product-item__content

    &__content {
    }

    // .product-item__img-wrap

    &__img-wrap {
      height: 175px;
      max-width: 280px;
    }

    // .product-item__img

    &__img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    // .product-item__info

    &__info {
    }

    // .product-item__title

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // .product-item__subtitle

    &__subtitle {
    }

    // .product-item__buy-box

    &__buy-box {
      & .btn-accent {
        width: 140px;
        height: 51px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // .product-item__price

    &__price {
      margin: 0;
    }
  }
}

.product-item-wrap--blog .product-item{
  box-shadow: 0 0 4px rgba(15, 15, 15, 0.1);
}

@media only screen and (max-width: 1024px){
  .cases-article{
    &__content-section--with-img{
      flex-direction: column;
      align-items: center;
    }
  }
  .cases-article-header__title{
    width: 50%;
  }
}
@media only screen and (max-width: 915px){
  .cases-article{
    &__bottom-section{
      flex-direction: column;
      align-items: center;
    }
    &__advantages-box{
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 880px){
  .product-item-wrap--blog{
    & .product-item{
      max-width: 300px;
      padding: 10px;
    }
  }
  .cases-article-header__title{
    font-size: 26px;
  }
  .cases-article-header {
    height: 180px;
    overflow: hidden;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 110%;
      background: linear-gradient(90deg, #2C599D 61.47%, rgba(92, 131, 196, 0) 86.08%);
      z-index: 1;
    }
    &__title{
      width: 80%;
    }
  }
  .cases-article{
    margin-bottom: 40px;
    &__advantage-value, &__advantage-description{
      font-size: 20px;
    }
    &__advantage{
      margin-bottom: 24px;
    }
  }
}
@media only screen and (max-width: 695px){
  .cases-article{
    &__equipment{
      flex-direction: column;
      align-items: center;
      & .product-item-wrap--blog:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &__solution-btn{
      margin: 0 auto;
    }
  }

}
@media only screen and (max-width: 512px){
  .cases-article-header{
    height: fit-content;
    &__title{
      height: fit-content;
      padding: 1em 0;
      width: 100%;
    }
    &::before{
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 150%;
      opacity: 0.8;
      background: linear-gradient(90deg, #2C599D 61.47%, rgba(92, 131, 196, 0) 86.08%);
      z-index: 1;
    }
  }
  .cases-article{
    &__review{
      padding: 20px;
    }
    &__reviewer{
      margin-top: 15px;
    }
  }
}
