@import '../../style/vars';

.rent {
}
.rent-header {
    position: relative;
    height: 700px;
    margin-bottom: 90px;

    // .rent-header__bg

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .rent-header__body-wrap

    &__body-wrap {
        position: relative;
        z-index: 1;
        max-width: 620px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        background: rgba(26, 59, 112, 0.7);
        align-items: center;
        padding: 0 24px;
    }

    // .rent-header__body

    &__body {
        max-width: 420px;
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    // .rent-header__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 40px;
    }

    // .rent-header__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $white;
        margin-bottom: 70px;
    }

    // .rent-header__order-btn

    &__order-btn {
        width: fit-content;
    }
}
.rent-header-container {
    max-width: 1350px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}

.rent-description {
    margin-bottom: 90px;
    display: flex;
    justify-content: space-between;
    // .rent-description__section

    &__section {
        max-width: 600px;
        width: 100%;
    }

    // .rent-description__title

    &__title {
        font-weight: 500;
        font-size: 28px;
        line-height: 150%;
        color: #2D3258;
        margin-bottom: 26px;
    }

    // .rent-description__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        & .highlight {
            font-weight: bold;
        }
    }

    // .rent-description__list-title

    &__list-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        margin-bottom: 14px;
        color: $primary;
    }

    // .rent-description__list

    &__list {
    }

    // .rent-description__list-item

    &__list-item {
        font-size: 16px;
        // line-height: 150%;
        display: flex;
        &::before{
            content: url("data:image/svg+xml,%3Csvg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.019 6.11446C14.254 5.84857 14.6351 5.84857 14.8701 6.11446C15.1052 6.38034 15.1052 6.81144 14.8701 7.07732L7.64798 15.2475C7.41294 15.5134 7.03187 15.5134 6.79684 15.2475L3.7876 11.8433C3.55257 11.5774 3.55257 11.1463 3.7876 10.8804C4.02264 10.6145 4.40371 10.6145 4.63874 10.8804L7.22241 13.8032L14.019 6.11446Z' fill='black'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='3' y='5' width='13' height='11'%3E%3Cpath d='M14.019 6.11446C14.254 5.84857 14.6351 5.84857 14.8701 6.11446C15.1052 6.38034 15.1052 6.81144 14.8701 7.07732L7.64798 15.2475C7.41294 15.5134 7.03187 15.5134 6.79684 15.2475L3.7876 11.8433C3.55257 11.5774 3.55257 11.1463 3.7876 10.8804C4.02264 10.6145 4.40371 10.6145 4.63874 10.8804L7.22241 13.8032L14.019 6.11446Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect y='0.468262' width='18.0554' height='20.4255' fill='%232C599D'/%3E%3C/g%3E%3C/svg%3E%0A");
            transform: translateY(-2px);
            margin-right: 7px;
            height: fit-content;
        }
    }
}

.rent-slider {
    width: 100%;
    display: flex;
    justify-content: center;
}

.rent-product {
    display: flex;
    justify-content: space-between;
    margin: 90px 0;
    // .rent-product__info

    &__info {
        max-width: 665px;
        width: 100%;
        margin: 0 14px;
    }

    // .rent-product__info-p

    &__info-p {
        font-size: 18px;
        line-height: 150%;
    }

    // .rent-product__options

    &__options {
        width: 360px;
        min-width: 360px;
    }

    // .rent-product__options-title

    &__options-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        color: $primary;
        margin-bottom: 20px;
    }

    // .rent-product__option

    &__option {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 82px;
        padding: 0 26px;
        &:not(:last-child){
            margin-bottom: 8px;
        }
    }

    // .rent-product__option-ico

    &__option-ico {
        margin-right: 32px;
        min-width: 52px;
        height: fit-content;
    }

    // .rent-product__option-name

    &__option-name {
        font-size: 16px;
        line-height: 150%;
    }
}


.rent-price {
    background: #EFF3F9;
    width: 100%;
    padding: 60px 0 40px;
    // .rent-price__title

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $primary;
        margin-bottom: 50px;
        text-align: center;
    }

    // .rent-price__body

    &__body {
        max-width: 1270px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    // .rent-price__section

    &__section {
        width: 59%;
        display: flex;
        flex-direction: column;
        &--discount{
            width: 38.5%;
            background: #FFFFFF;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: 25px 0;
        }
    }

    // .rent-price__top-wrap

    &__top-wrap {
        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 34px 0;
        margin-bottom: 10px;
    }

    // .rent-price__service

    &__service {
        max-width: 278px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    // .rent-price__service-title

    &__service-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #2C599D;
    }

    // .rent-price__service-price

    &__service-price {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin-top: 8px;
    }

    // .rent-price__plus-ico

    &__plus-ico {
    }

    // .rent-price__service-title-tariff

    &__service-title-tariff {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: #2C599D;
    }

    // .rent-price__service-label

    &__service-label {
        font-size: 12px;
        line-height: 100%;
    }

    // .rent-price__bottom-wrap

    &__bottom-wrap {
        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // .rent-price__descr

    &__descr {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
    }

    // .rent-price__discount-wrap

    &__discount-wrap {
    }

    // .rent-price__discount-title

    &__discount-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        margin-bottom: 24px;
        color: $primary;
    }

    // .rent-price__discount-body

    &__discount-body {
        display: flex;
    }

    // .rent-price__term

    &__term {
        width: 50%;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-right: 1px dashed #D8D8D8;
    }

    // .rent-price__term-item

    &__term-item {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        width: 100px;
        text-align: center;
        margin-bottom: 23px;
    }

    // .rent-price__percentage

    &__percentage {
        width: 50%;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    // .rent-price__percentage-item

    &__percentage-item {
        width: 100px;
        text-align: center;
        margin-bottom: 23px;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
    }

    // .rent-price__order-btn

    &__order-btn {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1355px){
    .rent-description{
        &__section {
            &:first-child{
                margin-right: 20px;
            }
        }
    }
    .rent-product {
        flex-wrap: wrap;
        margin-top: 30px;
        &__info {
            max-width: calc(100% - 400px);
        }
    }
    .rent{
        .product-demo-wrap{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
        }
    }
}


@media only screen and (max-width: 880px){
    .rent-header{
        height: 440px;
        margin-bottom: 65px;

        &__body {
            align-items: center;
        }

        &__title {
            font-size: 18px;
            line-height: 150%;
            text-align: center;
        }
        &__subtitle {
            font-size: 14px;
            margin-bottom: 50px;
            text-align: center;
        }
        &__order-btn {
            display: block;
            margin: 0 auto;
        }
    }
    .rent-description{
        flex-direction: column;
        margin-bottom: 70px;
        &__title {
            font-size: 20px;

        }
        &__subtitle {
            font-size: 14px;
            margin-bottom: 36px;
        }
        &__list-item {
            font-size: 14px;
        }
    }
    .rent-product {
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 40px;
        &__options-title{
            font-size: 16px;
        }
        &__info-p{
            font-size: 14px;
        }
        &__info{
            max-width: 100% !important;
        }
        &__options{
            margin: 30px auto;
        }
        &__option{
            height: 65px;
            padding: 0 14px;
        }
        &__option-ico{
            min-width: 32px;
            width: 32px;
            margin-right: 14px;
        }
        &__option-name{
            font-size: 14px;
        }
    }
    .rent-price{
        padding: 36px 0 40px;
        &__body{
            margin-bottom: 25px;
        }
        &__title{
            font-size: 20px;
            margin-bottom: 25px;
        }
        &__service-title{
            font-size: 16px;
        }
        &__service-price{
            font-size: 14px;
        }
        &__service-title-tariff{
            font-size: 16px;
        }
        &__service-label{
            font-size: 11px;
        }
        &__descr{
            font-size: 14px;
        }
        &__discount-title{
            font-size: 14px;
            margin-bottom: 18px;
        }
        &__top-wrap{
            padding: 30px 8px;
        }
        &__section--discount{
            padding: 25px 8px;
        }
        &__term-item, &__percentage-item{
            font-size: 14px;
            margin-bottom: 13px;
        }
        &__bottom-wrap{
            padding: 20px 8px;
        }
    }
}

@media only screen and (max-width: 630px){
    .rent-header-container{
        padding: 0;
    }
    .rent-price{
        &__body{
            flex-wrap: wrap;
        }
        &__section{
            width: 100%;
        }
        &__bottom-wrap{
            margin-bottom: 10px;
        }
    }
}
@media only screen and (max-width: 458px){
    .rent{
        & .product-demo{
            & .slider__button-sec.slick-arrow{
                display: none !important;
            }
            &__thumbnail-slider{
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 415px){
    .rent-price{
        &__top-wrap{
            flex-direction: column;
        }
        &__plus-ico{
            margin: 20px 0;
        }
    }
    .rent{
        & .product-demo{
            width: 50%;
            &__main-slider{
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 390px){
    .rent-product{
        &__options {
            min-width: 100%;
        }
    }
}
