@import '../../style/vars';
@import '../../style/mixins';

.tariffs {
  margin: 70px auto;
  max-width: 1270px;
  // .tariffs__main-title

  &__main-title {
    @include title;
    color: $primary;
    text-align: center;
    margin-bottom: 70px;
  }

  // .tariffs__paragraph

  &__paragraph {
    @include main-text;
    margin-bottom: 40px;
  }

  // .tariffs__title

  &__title {
    @include description;
    color: $secondary;
    margin-bottom: 30px;
  }

  // .tariffs__list

  &__list {
    margin-bottom: 70px;
  }

  // .tariffs__list-item

  &__list-item {
    position: relative;
    padding-left: 28px;
    @include main-text;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    &::before{
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 3px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23FB9B51'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23F88125'/%3E%3C/svg%3E%0A");
      background-size: 18px 18px;
      margin-right: 10px;
    }
  }

  // .tariffs__table-wrap-overflow
  &__table-wrap-overflow{
    margin-bottom: 30px;
  }

  // .tariffs__table-wrap

  &__table-wrap {
  }

  // .tariffs__table-title-main

  &__table-title-main {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: $white;
    background: $primary;
    padding: 25px 0;
    text-align: center;
    margin-bottom: 10px;
  }

  // .tariffs__table-header

  &__table-header {
  }

  // .tariffs__table

  &__table {
    margin-bottom: 10px;
  }

  // .tariffs__table-title

  &__table-title {
    background: $shade;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $secondary;
    text-transform: uppercase;
    padding: 16px 28px;
  }

  // .tariffs__table-row

  &__table-row {
    display: flex;
    border-left: 1px solid $muted;
    border-right: 1px solid $muted;
    border-bottom: 1px solid $muted;
    &:first-child{
      border-top: 1px solid $muted;
    }
  }

  // .tariffs__table-cell-main

  &__table-cell-main {
    @include main-text;
    padding: 16px 28px;
    width: 50%;
  }

  // .tariffs__table-cell-right

  &__table-cell-right{
    display: flex;
    width: 50%;
    min-width: 650px;
  }

  // .tariffs__table-cell

  &__table-cell {
    border-left: 1px solid $muted;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $primary;
    overflow-wrap: anywhere;
  }

  // .tariffs__table-cell--disabled

  &__table-cell--disabled {
    color: $secondary-text-color;
  }

  // .tariffs__table-row--additional

  &__table-row--additional {
    background: $additional;
    color: $white;
    & .tariffs__table-cell-main, & .tariffs__table-cell{
      color: $white;
    }
  }

  // .tariffs__star-descr-wrap

  &__star-descr-wrap {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 70px;
  }

  // .tariffs__star-descr

  &__star-descr {
  }

  // .tariffs__service

  &__service {
  }

  // .tariffs__service-p

  &__service-p {
    @include main-text;
  }

  // .tariffs__service-price

  &__service-price {
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    & span{
      font-weight: 700;
      color: $secondary;
    }
  }
}
.table-header {
  background: $additional;
  display: flex;
  // .table-header__left-section

  &__left-section {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: $white;
    width: 50%;
    padding: 0 28px;
  }

  // .table-header__right-section

  &__right-section {
    border-left: 1px solid $white;
    width: 50%;
    min-width: 650px;
  }

  // .table-header__cell-fill

  &__cell-fill {
    border-bottom: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: $white;
    padding: 10px;
  }

  // .table-header__cell

  &__cell {
    font-size: 16px;
    line-height: 130%;
    color: $white;
    font-weight: 400;
    width: 20%;
    text-align: center;
    padding: 6px;
    &:not(:last-child){
      border-right: 1px solid $white;
    }
    & p{
      font-weight: 500;
      font-size: 13px;
      line-height: 130%;
      color: $white;
    }
  }

  // .table-header__cell-row
  &__cell-row{
    display: flex;
  }
}

@media only screen and (max-width: 1150px){
  .tariffs{
    &__table-title-main{
      font-size: 16px;
    }
    &__table-cell-main, &__table-cell, &__table-title{
      font-size: 14px;
    }
    &__table-cell-right{
    }

  }
  .tariffs__table-cell-right, .table-header__right-section{
    min-width: 500px;
  }
  .table-header{
    &__left-section, &__cell-fill, &__cell, &__cell p{
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 880px){
  .table-header{
    &__left-section{
      padding: 0 12px;
    }
    &__cell-fill{
      padding: 8px 0;
    }
  }
  .tariffs{
    margin: 60px 0;
    &__main-title, &__star-descr-wrap {
      margin-bottom: 40px;
    }
    &__paragraph{
      margin-bottom: 24px;
    }
    &__title, &__table-wrap-overflow{
      margin-bottom: 16px;
    }
    &__list{
      margin-bottom: 40px;
    }
    &__table-cell-main{
      padding: 8px 12px;
    }
    // .tariffs__list-item

    &__list-item {
      &::before{
        top: 2px;
      }
    }
  }

}

@media only screen and (max-width: 692px){
  .tariffs__table-wrap-overflow{
    overflow-x: auto;
  }
  .tariffs__table-wrap{
    min-width: 672px;
  }
}