html,
* {
  font-family: "Inter", sans-serif;
  color: $text-color;
  //scroll-behavior: smooth;
}

@keyframes roll-in {
  0% {
    left: 100vw;
  }
  20% {
    left: 100vw;
  }
  100% {
    left: 38vw;
  }
}

@media only screen and (max-width: 1420px) {
  @keyframes roll-in {
    0% {
      left: 100vw;
    }
    20% {
      left: 100vw;
    }
    100% {
      left: 50vw;
    }
  }
}

@media only screen and (max-width: 1120px) {
  @keyframes roll-in {
    0% {
      left: 100vw;
    }
    20% {
      left: 100vw;
    }
    100% {
      left: 60vw;
    }
  }
}

@media only screen and (max-width: 870px) {
  @keyframes roll-in {
    0% {
      left: 100vw;
    }
    20% {
      left: 100vw;
    }
    100% {
      left: 20vw;
    }
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (min-width: 1024px) and (max-width: 1580px) {
  .container {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.container-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.content-wrap {
  margin-top: 116px;
  display: flex;
  flex-direction: column;
}

.bread-crumbs {
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 150%;
  color: #adadad;

  &__item {
    margin-right: 16px;
    color: inherit;

    &:not(:last-child) {
      &::after {
        content: "/";
        display: inline-block;
        transform: translateX(8px);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bread-crumbs {
    font-size: 11px;
    line-height: 120%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1250px) {
  .content-wrap {
    margin-top: 50px;
  }
}

//==================================================================================BUTTONS STYLE
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

.btn-accent {
  background: $accent;
  border-radius: 5px;
  border: none;
  padding: 16px 60px;
  @include button-text;
  text-align: center;
  color: $white;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: 0.3s;
  width: fit-content;

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $accent solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover {
    background: #f88125;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    // opacity: 0;
  }
}

.btn-primary {
}

.btn-additional {
  background: $additional;
  border-radius: 5px;
  border: none;
  padding: 16px 60px;
  @include button-text;
  text-align: center;
  color: $white;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: 0.3s;

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $additional solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover {
    background: $additional;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    // opacity: 0;
  }
}

.btn-secondary {
  background: $secondary;
  border-radius: 5px;
  border: none;
  padding: 16px 60px;
  @include button-text;
  text-align: center;
  color: $white;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: 0.3s;

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $secondary solid 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover {
    background: $secondary;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    // opacity: 0;
  }
}

.btn-ghost-accent {
  border: 2px solid $accent;
  border-radius: 6px;
  padding: 16px 60px;
  @include button-text;
  color: $primary;
  background: $white;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: 0.3s;

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $accent solid 2px;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    transition: 0.3s;
    opacity: 0;
  }

  &:hover {
    border: 2px solid $secondary;
    transition: 0.3s;
    transition-delay: 0.2s;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}

.btn-ghost-secondary {
  // font-weight: 600;
  // font-size: 16px;
  // line-height: 19px;
  color: $secondary;
  border: 2px solid $secondary;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 56px;
  background: $white;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  @include button-text;
  transition: 0.3s;

  &:disabled {
    color: $muted-text;
    border-color: $muted-text;
    cursor: default;

    &:hover {
      border-color: $muted-text;
      color: $muted-text;
    }

    &:before {
      border: none;
    }
  }

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $secondary solid 2px;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover,
  #file-input:hover + & {
    border: 2px solid #f88125;
    color: #f88125;
  }

  &:hover:before,
  &:focus:before,
  &:active:before,
  #file-input:hover + &:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    // opacity: 0;
  }
}

.btn-medium {
}

.btn-secondary {
  @include button-text;
  padding: 16px 60px;
  border-radius: 5px;
  background: $secondary;
  cursor: pointer;
  color: $white;
}

.btn-medium-secondary {
  padding: 10px 52px;
  color: $white;
  font-size: 12px;
  line-height: 15px;
  @include button-text;
  background: $accent;
  border-radius: 5px;
  cursor: pointer;
}

.product-btn {
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: 0.3s;
  cursor: pointer;

  @include button-text;

  &:before {
    content: "";
    border: 1px solid #fff;
    border-radius: 8px;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    animation-fill-mode: forwards;
    // opacity: 0;
  }
}

.btn-primary {
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  @include button-text;
  transition: 0.3s;

  &:before {
    content: "";
    border-radius: 5px;
    position: absolute;
    border: $primary solid 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &:hover {
    &:hover:before,
    &:focus:before,
    &:active:before {
      -webkit-animation-name: hvr-ripple-out;
      animation-name: hvr-ripple-out;
      animation-fill-mode: forwards;
      // opacity: 0;
    }
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0;
    width: 100%;
  }

  75% {
    font-size: 300%;
    margin-left: 25%;
    width: 150%;
  }
}

.arrow-down {
  cursor: pointer;
  transition: 0.2s;
}

.arrow-down.active {
  transform: rotateX(180deg);
}

input::placeholder {
}

.section-title {
  @include description;
  color: $primary;
  margin-bottom: 40px;
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.checkbox-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;

  & input {
    position: absolute; // take it out of document flow
    opacity: 0;
  }

  & .checkbox {
    display: flex;
    position: relative;

    & img {
      position: absolute;
      top: 8.5px;
      left: 7px;
      opacity: 0;
    }

    &::before {
      content: "";
      margin-right: 10px;
      display: block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      background: $white;
      border: 1px solid $tint;
      box-shadow: 1px 2px 10px rgba(235, 244, 248, 0.5);
      border-radius: 6px;
      cursor: pointer;
    }
  }

  // Disabled state label.
  & input:disabled + .checkbox {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  & input:disabled + .checkbox:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  & input:checked + .checkbox img {
    opacity: 1;
  }
}
.b24-form-header-description {
  margin-top: 30px !important;
}
.radio-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;

  & input {
    position: absolute; // take it out of document flow
    opacity: 0;
  }

  & .radio {
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      box-sizing: border-box;
      margin-right: 10px;
      display: block;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      background: $white;
      border: 2px solid $additional;
      box-shadow: 1px 2px 10px rgba(235, 244, 248, 0.5);
      border-radius: 50%;
      cursor: pointer;
    }

    &::after {
      content: "";
      display: block;
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $additional;
      position: absolute;
      z-index: 1;
      left: 4px;
      opacity: 0;
      cursor: pointer;
    }
  }

  // Disabled state label.
  & input:disabled + .radio {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  & input:disabled + .radio::after {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  & input:checked + .radio::after {
    opacity: 1;
  }
}

.product-slider {
  .product-item {
    &__info {
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.product-slider-wrap {
  padding: 100px 20px 0;
}

.product-item-wrap .product-item {
  max-width: 360px;
  padding: 30px 40px;
  background: #fff;
  -webkit-box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7),
    0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198),
    0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275),
    0 2.12866px 10.0172px rgba(219, 226, 234, 0.035),
    0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725),
    0 0.47043px 2.21381px rgba(219, 226, 234, 0.0196802);
  box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7),
    0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198),
    0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275),
    0 2.12866px 10.0172px rgba(219, 226, 234, 0.035),
    0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725),
    0 0.47043px 2.21381px rgba(219, 226, 234, 0.0196802);
  position: relative;
}

.product-item-wrap .product-item__compare-button {
  position: absolute;
  background: none;
  right: 26px;
  top: 25px;
  cursor: pointer;
}

.product-item__compare-button {
  &.active {
    path {
      fill: #f88125;
    }
  }
}

.product-item-wrap .product-item__title {
  color: #1a3b70;
  margin-bottom: 8px;
}

.product-item-wrap .product-item__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 30px;
  color: #273043;
}

.product-item-wrap .product-item__price {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #2c599d;
  margin-bottom: 11px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.product-item-wrap--slide {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 40px;
  margin-left: 5%;

  & a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.product-item-wrap--slide .product-item {
  width: 90%;
}

.product-item__subtitle {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 880px) {
  .catalog__hfilter-title,
  .product-item-wrap .product-item__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
}

@media only screen and (max-width: 880px) {
  .product-item-wrap--slide .product-item__price {
    font-size: 12px;
    line-height: 150%;
    text-align: center;
  }

  .product-slider {
    .product-item {
      &__info {
        height: 100px;
      }
    }
  }

  .product-slider .main-sl-dots li button {
    font-size: 0;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: #1a3b70;
  }

  .product-item-wrap--slide {
    margin: 0 0 0 4px;
  }

  .product-item-wrap--slide .product-item__compare-button {
    width: 20px;
    height: 20px;
  }

  .product-item-wrap--slide .product-item__title {
    text-align: center;
  }

  .product-item-wrap--slide .product-item {
    -webkit-box-shadow: 2px 6px 8px rgba(44, 39, 56, 0.062);
    box-shadow: 2px 6px 8px rgba(44, 39, 56, 0.062);
    border-radius: 8px;
  }

  .product-slider-wrap {
    padding-top: 40px;
  }

  .product-item-wrap--slide .product-item__subtitle {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .catalog__body .product-item-wrap--drop .product-item__subtitle {
    display: none;
  }
}

@media only screen and (max-width: 1480px) {
  .product-item-wrap--slide .product-item {
    padding: 30px;
  }

  .product-item-wrap--slide .product-item__subtitle {
    margin-bottom: 20px;
  }
}

.capabilities-next-button {
  right: -60px;
}

.capabilities-prev-button {
  left: -60px;
}

.slider__next-sec {
  right: -30px;
}

.slider__prev-sec {
  left: -30px;
}

@media (max-width: 1620px) {
  .capabilities-next-button {
    right: -20px;
  }

  .capabilities-prev-button {
    left: -20px;
  }
}

.slider__button-sec {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 40px;
  height: 60px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  cursor: pointer;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;

  &:active {
    transform: scale(0.8);
  }
}

// .capabilities

.capabilities {
  position: relative;

  // .capabilities__tabs-wrap

  & .slider-dots {
    bottom: -50px;
  }

  &__tabs-wrap {
    width: 100%;
    position: relative;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .arrow {
      height: fit-content;
      cursor: pointer;
      transition: 0.2s;

      &:active {
        transform: scale(0.8);
      }
    }
  }

  &__container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1670px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  // .capabilities__bg

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(248, 129, 37, 0.35) 0%,
        rgba(26, 59, 112, 0.63) 62.5%
      );
      mix-blend-mode: multiply;
    }
  }

  // .capabilities__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $white;
    margin-bottom: 38px;
    padding-top: 38px;
    text-align: center;
  }

  // .capabilities__tabs

  &__tabs {
    display: flex;
    max-width: 1518px;
    width: calc(100% - 50px);
    justify-content: center;
    margin: 0 auto;

    & .slick-list {
      width: 100%;
    }

    & .slick-slide > div {
      display: flex;
      justify-content: center;
    }
  }

  // .capabilities__tab

  &__tab {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.87);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    max-width: 294px;
    width: 294px;
    padding: 25px 20px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  // .capabilities__tab-ico-border

  &__tab-ico-border {
    width: 89px;
    height: 89px;
    border: 1px solid #2c599d;
    border-radius: 50%;
    margin-bottom: 38px;
  }

  // .capabilities__tab-ico-wrap

  &__tab-ico-wrap {
    width: 100%;
    height: 100%;
    border: 3px solid $white;
    border-radius: 50%;
    background: #2c599d;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .capabilities__tab-subtitle

  &__tab-subtitle {
    text-align: center;
    font-size: 15px;
    line-height: 150%;
    height: 86px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1620px) {
  .capabilities {
    &__tabs-wrap {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .capabilities {
    &__tabs {
      & .slick-slide > div {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .capabilities {
    &__title {
      font-size: 18px;
    }

    &__tab {
      max-width: 226px;
      height: 215px;
      padding: 25px 5px;
    }

    &__tab-subtitle {
      font-size: 14px;
    }

    &__tab-ico-border {
      width: 71px;
      height: 71px;
      margin-bottom: 16px;
    }

    &__tab-ico-wrap {
      border: none;

      & img {
        width: 55%;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .capabilities {
    &__title {
      max-width: 250px;
    }

    &__tabs-wrap .arrow {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 370px) {
  .capabilities {
    &__tabs {
      width: 100%;
    }

    &__container {
      padding: 0;
    }
  }
}

.suitable-tabs {
  margin-bottom: 26px;

  // .suitable-tabs__title

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $text-color;
    margin-bottom: 16px;
  }

  // .suitable-tabs__tabs

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    padding-right: 65px;
  }

  // .suitable-tabs__list

  &__list {
    display: none;
  }

  // .suitable-tabs__list-item

  &__list-item {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #2c599d;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #2c599d;
      border-radius: 50%;
      margin-right: 7px;
    }
  }

  // .suitable-tabs__tab

  &__tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 29.5px;
    position: relative;
    max-width: 59px;
    max-height: 59px;
    cursor: pointer;
    transition: 0.4s ease;
    transition-delay: 0.1s;
    background: $white;
    margin-bottom: 10px;
    z-index: 1;

    &-name {
      visibility: hidden;
    }

    &::before {
      width: 58px;
      content: "";
      position: absolute;
      display: block;
      top: -2px;
      bottom: -2px;
      right: 4px;
      left: -2px;
      border: 1px solid #2c599d;
      border-radius: 29.5px;
      transition: width 0.4s ease;
      background: #ffffff;
      z-index: 1;
    }

    &:hover {
      &::before {
        width: 180px;
      }

      & ~ .suitable-tabs__tab {
        z-index: 0;
        transition: z-index 0.5s step-start, opacity 0.5s linear;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      width: fit-content;
      transition-delay: 0.1s;

      & .suitable-tabs__tab-name {
        opacity: 1;
        z-index: 2;
        visibility: visible;
      }
    }
  }

  // .suitable-tabs__tab-ico

  &__tab-ico {
    width: 53px;
    height: 53px;
    min-width: 53px;
    min-height: 53px;
    background: #2c599d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  // .suitable-tabs__tab-name

  &__tab-name {
    position: absolute;
    //left: 45px;
    transform: translateX(calc(50% - 15px));
    padding: 0 7px 0 2px;
    opacity: 0;
    max-width: 140px;
    min-width: 130px;
    transition: 0.2s;
    transition-delay: 0.1s;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2c599d;
  }
}

@media only screen and (max-width: 880px) {
  .suitable-tabs {
    &__title {
      font-size: 16px;
      color: $secondary;
    }

    &__tabs {
      display: none;
    }

    &__list {
      display: block;
    }
  }
}

.opportunities-content-wrap {
  display: flex;
  width: 100%;
}

.opportunities {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;

  & .suitable-tabs {
    .suitable-tabs__title {
      color: $secondary;
    }
  }

  // .opportunities__slider-wrap

  &__slider-wrap {
    position: relative;
    //min-width: 340px;
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-right: 30px;

    & .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      z-index: 1;
      height: fit-content;
      cursor: pointer;
      transition: 0.2s;

      &:active {
        transform: scale(0.8);
      }
    }

    .arrow-left {
      left: -10px;
    }

    .arrow-right {
      right: -10px;
    }
  }

  // .opportunities__slider

  &__slider {
    max-width: 360px;
  }

  // .opportunities__slide-item

  &__slide-item {
    max-width: 360px;
    padding: 30px 43.5px 14px 43.5px;
  }

  // .opportunities__slide-img

  &__slide-img {
    width: 100%;
    margin-bottom: 20px;

    & img {
      width: 100%;
      height: 280px;
      object-fit: cover;
    }
  }

  // .opportunities__product-name

  &__product-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 8px;
  }

  // .opportunities__product-descr

  &__product-descr {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 36px;
  }

  // .opportunities__slide-footer

  &__slide-footer {
    display: flex;
    justify-content: space-between;
  }

  // .opportunities__price

  &__price {
    font-weight: 600;
    font-size: 20px;
    line-height: 200%;
  }

  // .opportunities__buy-btn

  &__buy-btn {
    padding: 0;
    width: 140px;
    height: 39px;
  }
}

.opp-info {
  margin-left: 20px;
  max-width: 816px;

  // .opp-info__info-wrap

  &__info-wrap {
  }

  // .opp-info__info-title

  &__info-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: $secondary;
    margin-bottom: 20px;
  }

  // .opp-info__descr

  &__descr {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;

    & li {
      margin-bottom: 4px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5286 0.52827C11.7889 0.26792 12.2111 0.26792 12.4714 0.52827C12.7318 0.788619 12.7318 1.21073 12.4714 1.47108L4.4714 9.47108C4.21105 9.73143 3.78895 9.73143 3.5286 9.47108L0.195262 6.13775C-0.0650874 5.8774 -0.0650874 5.45529 0.195262 5.19494C0.455612 4.93459 0.877722 4.93459 1.13807 5.19494L4 8.05687L11.5286 0.52827Z' fill='%232C599D'/%3E%3C/svg%3E%0A");
        margin-right: 8px;
      }
    }
  }
}

.marked-transport {
  width: calc(100% - 490px);
  display: flex;
  justify-content: center;
  align-items: center;
  // .marked-transport__img

  &__img {
    position: relative;
  }

  // .marked-transport__marks

  &__marks {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  // .marked-transport__mark

  &__mark {
    position: relative;
    width: fit-content;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 2px solid #fb9b51;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }
  }

  // .marked-transport__mark--1

  &__mark--1 {
  }

  // .marked-transport__mark--2

  &__mark--2 {
  }

  // .marked-transport__mark--3

  &__mark--3 {
  }

  // .marked-transport__mark--4

  &__mark--4 {
  }

  // .marked-transport__mark--5

  &__mark--5 {
  }

  // .marked-transport__mark--6

  &__mark--6 {
  }

  // .marked-transport__mark--7

  &__mark--7 {
  }

  // .marked-transport__mark--8

  &__mark--8 {
  }

  // .marked-transport__mark--9

  &__mark--9 {
  }
}

.marked-transport--trucks {
}

.suitable-tabs-wrap {
}

.opportunities-selector {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  // .opportunities-selector__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: $primary;
    margin-bottom: 54px;
    width: 100%;
  }

  // .opportunities-selector__tabs

  &__tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 10px;
  }

  // .opportunities-selector__tab

  &__tab {
    border-radius: 10px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0 0 16px #dbe2ea;
    margin-right: 1%;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.3s;

    &:not(:last-child) {
      margin-bottom: 11px;
    }

    &:hover,
    &.active {
      background: #1a3b70;

      & .opportunities-selector__tab-name {
        color: $white;
      }

      & .opportunities-selector__tab-ico {
        & .tab-ico {
          filter: brightness(100);
        }

        & .tab-ico-hover {
          opacity: 1;
        }
      }
    }
  }

  // .opportunities-selector__tab-ico

  &__tab-ico {
    width: 42px;
    height: 42px;
    margin-left: 30px;
    margin-right: 26px;
    position: relative;

    & .tab-ico {
      opacity: 1;
      transition: 0.3s;
      max-width: 42px;
    }

    & .tab-ico-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: 0.3s;
    }
  }

  // .opportunities-selector__tab-name

  &__tab-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #1a3b70;
    transition: 0.2s;
  }
}

@media only screen and (max-width: 1305px) {
  .opportunities-selector {
    flex-direction: column;
    align-items: center;
  }
  .marked-transport {
    width: 100%;
    height: 386px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 880px) {
  .opportunities {
    padding: 0 20px 20px;
    box-shadow: none;
    margin-bottom: 40px;

    &__product-name {
      font-size: 14px;
    }

    &__buy-btn {
      width: 110px;
      height: 33px;
    }

    &__price {
      font-size: 18px;
    }

    &__slide-item {
      max-width: 288px;
      padding: 22px;
      margin-bottom: 0;
    }

    &__slider-wrap {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &__product-descr {
      margin-bottom: 24px;
    }

    &__slider {
      & .slick-slide > div {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 28px;
      }
    }

    &__slide-item {
      background: #ffffff;
      box-shadow: 0 0 16px #dbe2ea;
      border-radius: 10px;
    }

    & .main-sl-dots {
      top: auto;
      bottom: 0;

      & li button {
        background: #1a3b70;
      }

      & li.slick-active button {
        background: #fb9b51;
      }
    }
  }
  .opportunities-content-wrap {
    flex-direction: column;
    align-items: center;
  }
  .opp-info {
    &__info-title {
      margin-bottom: 12px;
    }

    &__descr {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }
  .marked-transport {
    display: none;
  }
  .opportunities-selector {
    &__tab-name {
      font-size: 14px;
    }

    &__title {
      margin-bottom: 40px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .opportunities-selector {
    &__tabs {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .opportunities {
    padding-left: 0;
    padding-right: 0;

    &__slider-wrap {
      & .arrow.slick-arrow {
        display: none !important;
      }
    }
  }
  .opportunities-selector {
    &__tab-ico {
      margin-left: 22px;
      margin-right: 22px;
    }
  }
}

.user-type-selector {
  display: flex;

  // .user-type-selector__btn

  &__btn {
    padding: 9px 22px;
    position: relative;
    z-index: 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid $primary;
    margin-bottom: 50px;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;

    &:last-child {
      margin-left: -10px;
    }

    &.active {
      background: $primary;
      color: $white;
      z-index: 1;
    }
  }
}

.switcher-checkbox-wrap {
  display: flex;
  cursor: pointer;
}

.switcher-checkbox-hidden {
  opacity: 0;
  position: absolute;

  &:checked + .switcher-checkbox {
    &__body {
      background: #5c83c4;
      padding-left: 24px;
    }

    &__button {
    }
  }
}

.switcher-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  // .switcher-checkbox__body

  &__body {
    width: 52px;
    height: 30px;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    background: #8d8d8d;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    transition: 0.2s;
  }

  // .switcher-checkbox__button

  &__button {
    width: 24px;
    height: 24px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.2);
    border-radius: 50%;
    transition: 0.2s;
  }

  // .switcher-checkbox__label

  &__label {
    font-size: 14px;
    line-height: 120%;
    margin-left: 8px;
  }

  // .switcher-checkbox-hidden

  &-hidden {
    // // .switcher-checkbox__button

    // &__button {
    //   width: 24px;
    //   height: 24px;
    //   background: #FFFFFF;
    //   box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.2);
    //   border-radius: 50%;
    //   transition: .2s;
    // }
  }
}

.other-solutions {
  margin: 70px 0;

  & .sc-transport-item {
    margin-bottom: 0;
    width: 100%;
  }

  .slider__next-sec {
    right: -40px;
  }

  .slider__prev-sec {
    left: -40px;
  }

  // .other-solutions__title

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: $secondary;
    margin-bottom: 70px;
  }

  // .other-solutions__slider-wrap

  &__slider-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 1700px;
    width: 100%;
    //padding-right: 10px;
    //padding-left: 10px;
    & .arrow {
      cursor: pointer;
      transition: 0.2s;
      transform: translateY(-15px) scale(1);

      &:active {
        transform: translateY(-15px) scale(0.8);
      }
    }

    & .sc-transport-slide {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 6%;
      box-shadow: inset 0 2px 8px rgba(219, 226, 234, 0.8),
        inset 0 0 16px rgba(219, 226, 234, 0.6);
      border: 2px solid #dbe2ea;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      &__img-section {
        margin: auto;
        width: 300px;
        max-width: 300px;
        position: relative;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__industry-solutions-slider {
    max-width: 1550px;
    width: calc(100% - 40px);
    margin: auto;

    & .slick-slide > div {
      display: flex;
      justify-content: center;
      padding: 0 5px;
    }

    & .sc-solution-item {
      max-width: 360px;
    }
  }

  // .other-solutions__slider

  &__slider {
    max-width: 1550px;
    width: calc(100% - 40px);

    & .slick-slide > div {
      display: flex;
      justify-content: center;
    }

    & .sc-transport-item {
      max-width: 490px;
      min-width: 300px;
      display: flex !important;

      &__img-section {
        width: 300px;
        max-width: 300px;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 1615px) {
  .other-solutions {
    &__slider {
      & .sc-transport-item {
        max-width: 30vw;
      }
    }

    & .main-sl-dots {
      bottom: -15px;

      & li button {
        background: #1a3b70;
      }

      & li.slick-active button {
        background: #fb9b51;
      }
    }
  }
}

@media only screen and (max-width: 1285px) {
  .opportunities-selector__tabs {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 880px) {
  .opportunities-selector__tabs {
    grid-template-columns: 1fr;
  }

  .other-solutions {
    &__title {
      font-size: 20px;
      margin-bottom: 44px;
    }

    &__industry-solutions-slider {
      margin: 0 auto 30px;
    }

    &__slider-wrap {
      & .arrow {
        display: none;
      }
    }

    &__slider {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 632px) {
  .other-solutions {
    &__slider-wrap {
      & .sc-transport-item {
        min-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 510px) {
  .other-solutions {
    &__slider-wrap {
      & .sc-transport-item {
        min-width: 100%;
      }
    }

    & .main-sl-dots {
      & li button {
        width: 20px;
        height: 2px;
        border-radius: 1px;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .opportunities__slider {
    max-width: 300px;
  }
}

.slider-dots {
  display: flex !important;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;

  & li {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 12px;
    }

    & button {
      font-size: 0;
      width: 30px;
      height: 3px;
      border-radius: 2px;
      background-color: $white;
      border: none;
      display: block;
      cursor: pointer;
    }
  }

  & li.slick-active {
    & button {
      background-color: $accent;
    }
  }
}
