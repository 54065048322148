.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  &__image {
    height: 200px;
    width: 200px;
    animation: spin 3s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}