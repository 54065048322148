@import "../../../style/vars";

.react-calendar {
    border: 1px solid $muted !important;
    border-radius: 6px;
    &__navigation {
        &__arrow {

        }
    }

    &__navigation {
        padding: 0 20px;
        &__arrow {
            background: transparent !important;
            &:hover {

            }

            .label-arrow {
                padding: 8px;
                box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
                border-radius: 6px;
            }
        }

        &__label {
            background: transparent !important;
            &__labelText {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-transform: capitalize;
            }
        }

        &__next2-button {
            display: none;
        }

        &__prev2-button {
            display: none;
        }
    }

    &__month-view {
        &__weekdays {
            border-bottom: 1px solid $muted;
            &__weekday {
                abbr {
                    text-decoration: none;
                    color: #1A3B70;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }

    &__tile {
        padding: 0.25em 0.1em !important;
        &:hover {
            background: transparent !important;
        }

        abbr {
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-weight: 400;
            font-size: 12px ;
            line-height: 16px;
            width: 32px !important;
            height: 32px !important;
        }

        &--now {
            background: transparent !important;
            abbr {
                background: #2C599D !important;
                color: $white;
            }
        }

        &--active {
            background: transparent !important;

            abbr {
                background: #DCDCDC !important;
                color: $text-color ;
            }
        }
    }
}
