@import '../../style/vars';

.industry-solutions {
  &__opportunities {
    margin-top: 60px;
  }
}

.industry-solutions-header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 620px;
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #273043;
    opacity: 0.8;
    // z-index: 2;
  }

  // .industry-solutions-header__bg

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    .video-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .industry-solutions-header__container

  &__container {
    max-width: 1350px;
    height: 100%;
    width: 100%;
    padding: 100px 10px;
    margin: 0 auto;
  }

  // .industry-solutions-header__body

  &__body {
    position: relative;
    z-index: 1;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  // .industry-solutions-header__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;

    color: $white;
    margin-bottom: 8px;
  }

  // .industry-solutions-header__subtitle

  &__subtitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 40px;
  }

  // .industry-solutions-header__paragraph

  &__paragraph {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    margin-bottom: 70px;
  }

  // .industry-solutions-header__order-btn

  &__order-btn {
    display: flex;
    width: fit-content;
  }
}



@media only screen and (max-width: 1450px){
  .industry-solutions-header{
    &__container{
      max-width: 100%;
      padding-left: 12%;
    }
  }
}

@media only screen and (max-width: 880px){
  .industry-solutions-header{
    margin-bottom: 35px;
    &__container{
      max-width: 100%;
      padding: 60px 40px;
    }
    &__title{
      font-size: 18px;
      margin-bottom: 24px;
    }
    &__subtitle{
      font-size: 16px;
      margin-bottom: 24px;
    }
    &__paragraph{
      font-size: 14px;
      margin-bottom: 60px;
    }
    &__order-btn{
      width: 190px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 0;
    }
  }
}
