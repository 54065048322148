@import '../../style/vars';
@import '../../style/mixins';

.compare {
    padding: 70px 0 100px 0;

    &__title {
        @include description;
        color: $primary;
    }

    // .compare__top-section

    &__top-section {
        display: flex;
        padding: 50px 0 60px 0;
    }

    // .compare__filter

    &__filter {
        width: 330px;
        // min-width: 330px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // .compare__filter-section

    &__filter-section{
        position: relative;
    }

    // .compare__filter-category-drop

    &__filter-category-drop{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        & .compare__filter-category{
            cursor: pointer;
            margin-bottom: 0;
            margin-right: 10px;
        }
    }
    // .compare__arrow-down

    &__arrow-down {
        transition: .3s;
        &.active{
            transform: rotate(180deg);
        }
    }

    // .compare__filter-category-drop-body

    &__filter-category-drop-body{
        overflow: hidden;
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: .5s;
        position: absolute;
        background-color: #FFFFFF;
        z-index: 2;
        left: 0;
        right: 0;
        padding: 5px 0;

        .compare__filter-category {
            border-bottom: none;
            cursor: pointer;
        }
        &.active{
            transform: scaleY(1);
            max-height: 700px;
        }
    }

    // .compare__form

    &__form{
        & .radio-wrap{
            margin-bottom: 9px;
        }
        & .btn-ghost-secondary{
            margin-top: 24px;
            padding: 0;
            width: 90%;
            height: 41px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // .compare__filter-title

    &__filter-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $text-color;
        margin-bottom: 10px;
    }

    // .compare__filter-category

    &__filter-category {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        color: $additional;
        width: fit-content;
        border-bottom: 1px solid $additional;
        margin-bottom: 10px;
        // text-decoration-line: underline;
    }

    // .compare__clean-list-btn

    &__clean-list-btn {
        background: none;
        font-size: 12px;
        line-height: 150%;
        cursor: pointer;
    }

    // .compare__product-cards

    &__product-cards {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        & .product-item-wrap{
            width: 25%;
            margin-right: 30px;
            & .product-item{
                padding: 25px 20px 35px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                & .close-btn{
                    top: 8px;
                    right: 8px;
                }
                & .product-item__img-wrap{
                    width: 160px;
                    height: 160px;
                }
                &__info{
                    width: 100%;
                }
                & .product-item__buy-box{
                    & .product-item__price{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 150%;
                        margin: 0;
                    }
                }
                & .btn-accent{
                    width: 130px;
                    height: 45px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

            }
            & .product-item__title{
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }

    // .compare__table

    &__table {
        word-break: break-word;
    }

    // .compare__table-empty

    &__table-empty {
        width: 100%;
        height: 98px;
        background: $tint;
        border-radius: 8px;
        text-align: center;
        padding: 15px 30px;
        display: flex;
        align-items: center;
    }

    // .compare__table-empty-text

    &__table-empty-text {
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
    }

    // .compare__table-row

    &__table-row {
        border-radius: 8px;
        padding: 15px 30px;
        display: flex;
        width: 100%;
        &:nth-child(odd){
            background: $tint;
        }
    }

    // .compare__table-title

    &__table-title {
        font-size: 14px;
        line-height: 120%;
        min-width: 270px;
        width: 270px;
        display: flex;
        align-items: center;
    }

    // .compare__table-item

    &__table-item {
        width: 24%;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:not(:last-child){
            margin-right: 1.25%;
        }
    }
}

@media only screen and (max-width: 1590px) {
    .compare__top-section{
        padding-right: 0;
    }
}
@media only screen and (max-width: 1460px) {
    .compare__product-cards .product-item-wrap{
        width: auto;
        & .product-item__price{
            margin-right: 8px !important;
        }
    }
}

@media only screen and (max-width: 1426px) {
    .compare{
        &__table-title{
            width: 190px;
            min-width: 190px;
        }
        &__filter{
            width: 220px;
            min-width: 220px;
        }
        & .product-item-wrap{
            &:not(last-child){
                margin-right: 1.25%;
            }
            width: 24%;
        }
        &__table-row{
            padding-right: 5px;
        }
        &__filter-title{

        }
        &__filter-category{
            font-size: 14px;
        }
        & .btn-ghost-secondary{
            font-size: 12px;
        }
        & .product-item-wrap{
            & .product-item{
                padding: 10px;
                width: min-content;
            }
        }
    }
}
@media only screen and (max-width: 1270px) {
    .compare{
        & .product-item-wrap .product-item{
            width: auto;
            &__buy-box{
                flex-direction: column;
            }
        }
        & .product-item__info{
            margin-top: 8px;
        }
        & .product-item__price{
            margin-bottom: 8px !important;
        }
    }
}

@media only screen and (max-width: 992px) {
    .compare-wrap{
        overflow-x: scroll;
    }
    .compare{
        width: fit-content;
        padding: 50px 0;
        &__top-section{
            padding: 30px 0;
        }
        & .product-item-wrap{
            min-width: 172px;
        }
        &__table-title{
            font-size: 11px;
        }
        &__table-item{
            font-size: 11px;
            min-width: 172px;
        }
        &__product-cards{
            & .product-item-wrap{
                & .product-item{
                    &__title{
                        font-size: 12px;
                    }
                    &__price{
                        font-size: 12px !important;
                    }
                }
            }
        }
        &__table-item:last-child{

        }
        &__table-row{
            // min-width: fit-content;
        }
    }
}

.compare .basket-empty {
    margin-top: 76px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #DBE2EA;
    border-radius: 8px;
    background: $tint;
    padding: 45px 65px 50px;
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 18px;
        color: $primary;
    }
    &__subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 36px;
    }
}

.compare {
    &__filter-section.disabled {
        & .compare__filter-title {
            color: #E0E0E0;
        }
        & .radio-wrap {
            & .radio {
                color: #E0E0E0;
                &::before {
                    border-color: #E0E0E0;
                }
            }
        }
    }
}

@media only screen and (max-width: 880px) {
    .compare .basket-empty {
        padding: 30px 24px;
        width: 100%;
        margin-top: 36px;
        margin-bottom: 36px;
        &__title {
            font-size: 20px;
            margin-bottom: 18px;
        }
        &__subtitle {
            font-size: 16px;
            margin-bottom: 36px;
        }
    }
    .compare {
        &__table-empty-text {
            font-size: 16px;
        }
    }
}
