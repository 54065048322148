@import 'src/style/vars';

.pay-thank {
  position: relative;
  width: 100%;
  height: 70vh;

  display: grid;
  place-items: center;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: $primary;
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 25px;
  }

  @media (max-width: 880px) {
    height: 620px;
    &__title {
      font-size: 17px;
      letter-spacing: 0.05em;
      margin-top: 90px;
    }
    &__subtitle {
      font-size: 16px;
    }
    &__img {
      width: 380px;
    }
  }

  @media (max-width: 520px) {
    &__img {
      width: 277px;
    }
  }
}
