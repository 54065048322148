@import '../../../style/vars';
@import '../../../style/mixins';

.cityListWrapper {
    display: none;
    position: relative;
    .cityList {
        margin-top: 2px;
        position: absolute;
        right: 0;
        padding: 11px 0 11px 11px;
        background: #fff;
        box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
        border-radius: 8px;
        border: 1px solid #DBE2EA;
        width: 380px;
        max-height: 195px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 13px;
            height: 8px;
            background-color: #DBE2EA;
            border-radius: 6.5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #7C9CBF;
            border-radius: 9em;
            width: 12px;
        }
        .cityListItem {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px 10px 5px 5px;
            cursor: pointer;
            &:hover {
                background: #DBE2EA;
            }
        }
    }
    &.active {
        display: block;
    }
}

.alert-wrap {
    max-width: 480px;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: $white;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    max-height: 220px;
    display: none;
    &.active{
        display: block;
    }
}
.modal__close {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    cursor: pointer;
}
.alert {
    padding: 30px;
    text-align: center;

    &.deal-success {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    // .alert__title

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $primary;
        margin-bottom: 20px;
    }

    // .alert__subtitle

    &__subtitle {
        @include secondary-text;
        color: $text-color;
        margin-bottom: 30px;
    }

    // .alert__confirm-btn

    &__confirm-btn {
        padding: 16px 40px;
        color: $white;
        @include button-text;
        border-radius: 5px;
        background: $secondary;
        cursor: pointer;
    }
}

.form-modal {
    position: fixed;
    // position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
    z-index: 6;
    padding: 40px;
    background: $white;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    width: fit-content;
    height: fit-content;
    opacity: 0;
    transform: scale(0);
    transition: 0.3s;
    filter: none;
    &.active{
        transform: scale(1);
        opacity: 1;
        transition: 0.3s;
    }

    // .form-modal--request

    &--request {
        & .checkbox {
            margin-bottom: 10px;
            & span {
                font-size: 14px;
            }
        }
    }

    // .form-modal__body

    &__body {
        width: 280px;
    }

    // .form-modal__title

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: $primary;
        margin-bottom: 10px;
    }

    // .form-modal__subtitle

    &__subtitle {
        font-size: 14px;
        margin-bottom: 20px;
    }

    // .form-modal__label

    &__label  {
        position: relative;
        & .field-title{
            position: absolute;
            left: 12px;
            top: 2px;
            font-size: 14px;
            color: #273043;
            transition: 0.2s;
        }
        & .required{
            &::after{
                content: '*';
                color: red;
            }
        }
    }

    // .form-modal__input

    &__input {
        width: 100%;
        border: 1px solid $shade;
        box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 18px 16px;
    }

    // .form-modal__submit-btn

    &__submit-btn{
        display: block;
        margin: 10px auto 0 auto;
    }

    // .form-modal__input--textarea

    &__input--textarea {
    }
}


.basket-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    max-width: 620px;
    width: 100%;
    background: #FFF;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    display: none;
    padding: 50px 32px 0;
    z-index: 100;
    &.active{
        display: block;
    }
    & .modal__close{
    }

    // .basket-modal__body

    &__body {
    }

    // .basket-modal__title

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 50px;
    }

    // .basket-modal__products

    &__products {
        @include scroll-style;
        overflow-y: auto;
        max-height: calc(70vh - 200px);
        padding-right: 5px;
    }

    // .basket-modal__product

    &__product {
        display: flex;
        width: 100%;
        position: relative;
        border-bottom: 1px solid #dcdcdc;
    }

    // .basket-modal__img

    &__img {
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        margin-right: 20px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .basket-modal__left-section

    &__left-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
    }

    // .basket-modal__name

    &__name {
        font-size: 14px;
        line-height: 120%;
        max-width: 266px;
        margin-bottom: 10px;
    }

    // .basket-modal__right-section

    &__right-section {
        display: flex;
        align-items: center;
        & .close-btn{
            cursor: pointer;
        }
    }

    // .basket-modal__price

    &__price {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $secondary;
        margin-right: 20px;
        white-space: nowrap;
    }

    // .basket-modal__footer-top

    &__footer-top {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;
        margin-bottom: 0;
        align-items: center;
    }

    // .basket-modal__footer-bonus

    &__footer-bonus {
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.02em;
    }

    // .basket-modal__footer-bonus-val

    &__footer-bonus-val {
        font-size: 12px;
        line-height: 150%;
        text-decoration-line: underline;
        transition: .2s;
        &:hover{
            color: $secondary;
        }
    }

    // .basket-modal__footer-sum

    &__footer-sum {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: $secondary;
    }

    // .basket-modal__footer-price

    &__footer-price {
        margin-left: 20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: $secondary;
    }

    // .basket-modal__footer-bottom

    &__footer-bottom {
        display: flex;
        justify-content: space-around;
        padding: 60px 0;
    }

    // .basket-modal__continue-btn

    &__continue-btn {
        width: 231px;
        height: 51px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .basket-modal__confirm-btn

    &__confirm-btn {
        width: 196px;
        height: 51px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.basket-modal-counter {
    display: flex;
    width: 100px;
    height: 29px;
    border-radius: 6px;
    border: 1px solid #2C599D;
    overflow: hidden;
    justify-content: center;

    // .basket-modal-counter__dec

    &__dec {
        padding-left: 8px;
    }
    & input{
        width: 29%;
        text-align: center;
        color: $secondary;
        font-size: 14px;
        line-height: 150%;
    }
    // .basket-modal-counter__inc

    &__inc {
        padding-right: 8px;
    }
    &__dec, &__inc {
        width: 37%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $secondary;
        transition: .2s;
        height: 100%;
        cursor: pointer;
        user-select: none;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        &:hover{
            background: $secondary;
            color: $white;
        }
    }
}

@media only screen and (max-width: 880px){
    .basket-modal{
        &__title{
            font-size: 18px;
        }
        &__name{
            font-size: 12px;
        }
        &__price{
            font-size: 14px;
        }
        &__footer-bonus{
            font-size: 10px;
        }
        &__footer-sum{
            font-size: 14px;
        }
        &__footer-top{
            margin-bottom: 0;
        }
        &__footer-bottom {
            padding-top: 32px;
        }
    }
}

@media only screen and (max-width: 520px){
    .form-modal {
        padding: 20px;
    }

    .basket-modal{
        padding: 30px 5px 0;
        &__body {
            max-height: calc(100vh - 60px);
        }
        &__img{
            margin-right: 10px;
        }
        &__product{
        }
        &__right-section{
            display: flex;
            justify-content: flex-end;
            flex-direction: column-reverse;
            & .close-btn{
                align-self: flex-end;
                margin-bottom: 20px;
            }
        }
        &__price{
            margin-right: 0;
            width: max-content;
        }
        &__footer-bottom {
            padding-bottom: 30px;
        }
    }
}
@media only screen and (max-width: 500px){
    .basket-modal{
        &__footer-bottom{
            flex-direction: column;
            align-items: center;
        }
        &__continue-btn{
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 340px){
    .basket-modal{
        &__footer-top{
            flex-direction: column;
        }
        &__footer-bonus{
            margin-bottom: 10px;
        }
    }
}

.enter-modal {
    max-width: 360px;
    width: 100%;
    background: $white;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 30px 40px;
    height: fit-content;
    max-height: 100vh;
    display: none;
    z-index: 5;

    &__login-error {
        margin: 5px 0;
        font-size: 14px;
        color: $error;

        visibility: hidden;

        &.active {
            visibility: visible;
        }
    }

    & .btn-secondary {
        &::before {
            content: none;
        }
    }

    &.active{
        display: block;
    }
    & .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    & .user-type-selector {
        width: 100%;

        // .user-type-selector__btn

        &__btn {
            width: 50%;
            text-align: center;
        }
    }

    // .enter-modal__tabs-wrap

    &__tabs-wrap {
        display: flex;
        width: 280px;
        min-width: 280px;
        overflow: hidden;
        height: fit-content;
    }

    // .enter-modal__tabs

    &__tabs {
        display: flex;
        width: max-content;
        transform: translateX(0);
        transition: .3s;
        &.active{
            transform: translateX(-50%);
            & .enter-modal__body--registration{
                max-height: fit-content;
            }
        }
    }

    // .enter-modal__body

    &__body {
        width: 280px;
        min-width: 280px;
        transition: .3s;
        max-height: 70vh;
        overflow-y: auto;
        @include scroll-style;
    }


    // .enter-modal__body--registration

    &__body--registration{
        //max-height: 220px;
    }

    // .enter-modal__form

    &__form {
        & .form__input{
            margin-bottom: 15px;
        }
        & .password-label{
            font-size: 12px;
            line-height: 120%;
            color: #8D8D8D;
            margin-bottom: 20px;
        }
        & .checkbox-wrap{
            margin-bottom: 30px;
            .checkbox span{
                font-size: 14px;
                line-height: 120%;
            }
        }
    }

    // .enter-modal__footer

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    // .enter-modal__footer-btn

    &__footer-btn {
        max-width: 100%;
    }

    // .enter-modal__forgot-password

    &__forgot-password {
        margin-top: 20px;
        font-size: 14px;
        line-height: 120%;
        text-decoration-line: underline;
        color: #8D8D8D;
        transition: .2s;
        cursor: pointer;
        &:hover{
            color: #2C599D;
        }
    }

    &__footer-btn--reg{
        margin-top: 20px;
    }
}


@media only screen and (max-width: 360px){
    .enter-modal{
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;

        &.active {
            display: flex;
        }

        & .user-type-selector{
            max-width: 280px;
        }
    }
}

.password-recovery-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    padding: 60px 40px;
    max-width: 360px;
    width: 100%;
    height: fit-content;
    display: none;
    z-index: 5;

    &.active {
        display: block;
    }
    & .form__input{
        margin-bottom: 20px;
    }

    & .close-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
    // .password-recovery-modal__body

    &__body {
    }

    // .password-recovery-modal__title

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
    }

    // .password-recovery-modal__subtitle

    &__subtitle {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
    }

    // .password-recovery-modal__send-btn

    &__send-btn {
        display: block;
        margin: 0 auto;
    }
}

.empty-products {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    max-width: 360px;
    width: 100%;
    background: #FFF;
    box-shadow: 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 16px;
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    z-index: 5;
    & .close-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
    &.active {
        display: flex;
    }
    // .empty-products__title

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 24px;
        color: $primary;
    }

    // .empty-products__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: $primary;
        margin-bottom: 16px;
    }

    // .empty-products__descr

    &__descr {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        margin-bottom: 32px;
    }

}
