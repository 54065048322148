@import '../../style/vars';

.transport-solutions {

  // .transport-solutions__header

  &__header {
    position: relative;
    width: 100%;
    margin-bottom: 82px;
    overflow: hidden;
    & .container{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  // .transport-solutions__header-bg

  &__header-bg {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .transport-solutions__header-bg-item

  &__header-bg-item {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: fit-content;
    animation: roll-in;
    //animation-duration: 2s;
    animation-fill-mode: forwards;
    & img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__header-rails {
    height: 62px !important;
    position: absolute;
    width: 65%;
    right: 0;
    bottom: 0;
    object-position: bottom right;
  }

  &__header-train {
    z-index: 1;
  }

  // .transport-solutions__header-body

  &__header-body {
    background: rgba(255, 255, 255, 0.85);
    max-width: 620px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    position: relative;
    padding: 100px;

  }

  // .transport-solutions__header-title

  &__header-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: $primary;
    margin-bottom: 8px;
  }

  // .transport-solutions__header-subtitle

  &__header-subtitle {
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 40px;
  }

  // .transport-solutions__header-descr

  &__header-descr {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $primary;
    margin-bottom: 70px;
  }

  // .transport-solutions__header-order-btn

  &__header-order-btn {
    width: fit-content;
  }

  // .transport-solutions__opportunities

  &__opportunities {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 880px){
  .transport-solutions{
    margin: 0;

    &__header-body {
      & .btn-accent {
        align-self: center;
      }
    }

    &__header-title{
      font-size: 18px;
      margin-bottom: 24px;
      line-height: 150%;
    }
    &__header-subtitle{
      font-size: 16px;
      margin-bottom: 24px;
    }
    &__header-descr{
      font-size: 14px;
      margin-bottom: 60px;
    }
    &__opportunities{
      margin-top: 40px;
    }
  }
}
@media only screen and (max-width: 870px){
  .transport-solutions{
    &__header-body{
      z-index: 1;
    }
    &__header{
      & .container{
        padding: 0;
      }
    }
  }
}


@media only screen and (max-width: 542px){
  .transport-solutions{
    &__header-body{
      padding: 50px 30px;
    }
    &__header-order-btn{
      max-width: 249px;
      padding: 0;
      width: 100%;
    }
  }
}
