@import '../../../style/vars';
@import '../../../style/mixins';

body {
  position: relative;
}

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: .3s;
  backdrop-filter: blur(3px);

  &.active {
    z-index: 5;
    opacity: 1;
    transition: .3s;
    overflow: auto;
  }

}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}

.header-top {
  height: 40px;
  background-color: $primary;
  position: relative;
  z-index: 2;
  display: flex;

  & .container-header {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 5555;
  }

  // .header-top__nav

  &__nav {
    display: flex;
    width: fit-content;
    height: 100%;
    align-items: center;
  }

  // .header-top__nav-item

  &__nav-item {
    margin-right: 18px;

    & a {
      text-decoration: none;
      @include secondary-text;
      color: $white;
      position: relative;

      &.active {
        &::before {
          transform: scaleX(1);
        }
      }

      &::before {
        content: '';
        display: block;
        height: 1px;
        border-bottom: 1px solid $white;
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: .2s;
        transform: scaleX(0);
      }
    }

    &:hover {
      a {
        &::before {
          transform: scaleX(1);
        }
      }

    }

    &:first-child {
      & a {
        font-weight: 700;
      }
    }
  }

  // .header-top__items-section

  &__items-section {
    display: flex;
    height: 100%;
    align-items: center;
  }

  // .header-top__search-wrap

  &__search-wrap {
    display: flex;
    background: transparent;
    position: relative;
    margin-right: 23px;
  }

  // header-top__search-ico

  &__search-ico {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    background: none;
  }

  // .header-top__search

  &__search {
    padding: 9px 15px;
    font-size: 12px;
    line-height: 100%;
    background: transparent;
    visibility: hidden;
    opacity: 0;
    width: 229px;
    height: 30px;
    transition: 0.3s;

    &.active {
      visibility: visible;
      opacity: 1;
      background: $white;
    }

    &.active ~ .header-top__search-ico svg path {
      fill: $secondary;
    }

    &::placeholder {
      color: $secondary-text-color;
    }
  }

  &__search-products {
    visibility: hidden;
    position: absolute;
    top: 35px;
    width: 400px;
    background: #FFFFFF;
    border: 1px solid #DBE2EA;

    &.active {
      visibility: visible;
    }
  }

  & .search-products {
    &__item {
      border-bottom: 1px solid #eeeeee;
    }

    &__link {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }

    &__title {
      font-size: 14px;
      width: 290px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__price {
      font-size: 14px;
    }
  }

  // .header-top__location

  &__location {
    display: flex;
    cursor: pointer;
    margin-right: 9px;
    position: relative;
    width: max-content;

    & img {
      height: fit-content;
      margin-right: 6px;
    }
  }

  &__location-btn {
    display: flex;
  }

  // .header-top__location-dropdown
  &__location-dropdown {
    position: fixed;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 8px;
    max-width: 620px;
    width: 620px;
    padding: 50px 40px;
    transform: translateY(-200%);
    opacity: 0;
    transition: .4s;
    z-index: 5;
    left: 0;
    right: 0;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // .header-top__modal-close
  &__modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  // .header-top__label
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $primary;
  }

  // .header-top__input
  &__input {
    border: 1px solid $shade;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    border-radius: 8px;
    padding: 18px 15px;
    font-size: 14px;
    color: $text-color;
    max-width: 380px;
    width: 380px;

    &::placeholder {

    }
  }

  // .header-top__city-list
  &__city-list {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  // .header-top__city-column
  &__city-column {
    width: 160px;
  }

  // .header-top__city-item
  &__city-item {
    margin-bottom: 6px;
    cursor: pointer;
  }

  // .header-top__location-current-value

  &__location-current-value {
    color: $white;
    letter-spacing: 0.0075em;
    text-decoration: underline;
    font-size: 14px;
    line-height: 188%;
  }

  // .header-top__lang

  &__lang {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    color: $white;
    width: 67px;
    margin-top: 6px;
    border-radius: 4px;
    height: 27px;
    transition: .3s;
    padding: 4px 0;
    position: relative;
    overflow: hidden;

    &.active {
      background-color: $white;
      color: $text-color;
      height: 79px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      & .header-top__lang-current {
        color: $text-color;
        z-index: 555;
      }
    }
  }


  // .header-top__lang-item-select

  &__lang-item-select {
    display: flex;
    width: 100%;
    cursor: pointer;

    & .arrow-down {
      position: absolute;
      right: 4px;
      transition: .2s;
    }
  }

  // .header-top__lang-current

  &__lang-current {
    width: 100%;
    padding-left: 10px;
    margin-bottom: 8px;
    color: $white;
  }

  // .header-top__lang-item

  &__lang-item {
    margin-bottom: 6px;
    width: 100%;
    padding-left: 14px;
    height: 20px;
    cursor: pointer;

    &:hover {
      background: $tint;
      color: $primary;
    }
  }

  // .header-top__phone

  &__phone {
    height: 40px;
    min-width: max-content;

    &.active {

    }
  }

  // .header-top__phone-landline

  &__phone-landline {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  // .header-top__phone-landline-number

  &__phone-landline-number {
    @include button-text;
    color: white;
    margin-right: 16px;

    & > a {
      color: white !important;
    }
  }

  // .header-top__phone-ico

  &__phone-ico {
    margin-right: 7.5px;
  }

  // .header-top__phone-dropdown

  &__phone-dropdown {
    background: $white;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    border-radius: 8px;
    padding: 13px 12px;
    transform: translateY(-500px);
    transition: .5s;

    &.active {
      transform: translateY(0px);
    }
  }

  // .header-top__phone-cellphone

  &__phone-cellphone {
    padding: 5px 0;
    display: flex;
    align-items: center;

    & img {
      margin-right: 6px;
    }

    & a {
      @include secondary-text;
      color: $secondary;
    }

  }

  // .header-top__phone-schedule

  &__phone-schedule {
    @include small-text;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  // .header-top__phone-schedule-item

  &__phone-schedule-item {
    line-height: 16px;
  }

  // .header-top__phone-days

  &__phone-days {
    color: $primary;
    font-weight: 500;
    margin-right: 4px;
  }

  // .header-top__phone-time

  &__phone-time {
    color: $text-color;
  }

  // .header-top__phone-input-label

  &__phone-input-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $primary;
    display: flex;
    flex-direction: column;
  }

  // .header-top__phone-input
  &__phone-input {
    @include input-textfield-normal;
    margin: 12px 0;
  }

  // .header-top__call-btn
  &__call-btn {
    width: 100%;
    padding: 10px 52px;
    background: $accent;
    border-radius: 5px;
    color: $white;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }
}

.arrow-down {
}

.header-bottom {
  border-bottom: 1px solid #BDBDBD;
  display: flex;
  justify-content: center;
  background: $white;

  // .header-bottom__sidebar-overflow-wrap

  &__sidebar-overflow-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 0;

    & .header-bottom__menu-section {
      margin-left: auto;
    }
  }

  & .container-header {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  // .header-bottom__content-wrap

  &__content-wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // .header-bottom__logo-wrap

  &__logo-wrap {
  }

  // .header-bottom__nav

  &__nav {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 43px;
  }

  // .header-bottom__nav-dropdown

  &__nav-dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  // .header-bottom__drop-item

  &__drop-item {
  }

  // .header-bottom__drop-btn

  &__drop-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: max-content;
  }

  // .header-bottom__drop-btn-text

  &__drop-btn-text {
    margin: 0 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: $text-color;
  }

  // .header-bottom__drop-menu

  &__drop-menu {
    visibility: hidden;
    display: flex;
    z-index: 0;
    position: absolute;
    top: 115px;
    background: $white;
    border: 1px solid $shade;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04), 0 20px 20px rgba(44, 39, 56, 0.04);
    border-radius: 6px;
    transform: scaleY(0);
    transform-origin: top;
    transition: .3s ease-in-out;

    &.active {
      visibility: visible;
      transform: scaleY(1);
      z-index: 0;
    }

    &--catalog {
      & a {
        padding: 10px 0;
      }
    }

    &--services {
      & a {
        padding: 14px 0;
      }
    }

    &--solutions {
      & a {
        padding: 10px 0;
      }
    }

    &--catalog, &--services, &--solutions {
      max-width: 1256px;
      width: calc(100vw - 170px);

      & .header-bottom__drop-menu-item {
        width: 50%;
        border-bottom: 1px solid #EEEEEE;

        & a {
          display: flex;
          height: 100%;
          width: 100%;
          padding-left: 20px;
          align-items: center;
          transition: .2s;
        }

        & .ico-wrap {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          margin-right: 17px;

          & .ico {
            opacity: 1;
            transition: .2s;
          }

          & .ico-alt {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: .2s;
            opacity: 0;
          }
        }

        &:hover {
          background: #2C599D;

          a {
            color: $white;
          }

          & .ico-wrap {
            & .ico {
              // opacity: 0;
              filter: brightness(100);
            }

            & .ico-alt {
              // opacity: 1;
            }
          }
        }
      }

      & .header-bottom__catalog-drop {
        display: flex;
        max-width: 783px;
        flex-wrap: wrap;
        // padding-top: 14px;
        // padding-bottom: 20px;
      }

      & .header-bottom__drop-pic {
        max-width: 473px;
        width: 473px;
        height: auto;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        overflow: hidden;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--services, &--solutions {
      left: 0;
      right: 0;
      margin: 0 auto;

      & .header-bottom__catalog-drop {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      & .header-bottom__drop-menu-item {
        width: 100%;
      }
    }

    &--services {
      max-width: 1003px;
      left: 0;
      right: 0;
      margin: 0 auto;

      & .header-bottom__catalog-drop {
        width: 100%;
        max-width: 529px;
        height: fit-content;
        align-self: center;
      }
    }

    &--solutions {
      & .header-bottom__list-wrap {
        max-width: 395px;
        width: 100%;
      }
    }
  }

  // .header-bottom__list-title

  &__list-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $primary;
    text-align: center;
    width: 100%;
    padding: 20px 0 12px;
    border-bottom: 1px solid #EEEEEE;
  }


  // .header-bottom__drop-menu-item

  &__drop-menu-item {
    padding: 0 15px;
    cursor: pointer;
    font-weight: 450;
    // letter-spacing: 0.018em;
    transition: .5s;
    display: flex;
    align-items: center;

    & a {
      color: #525252;
      font-size: 14px;
    }

    &:hover {
      background: $tint;

      & a {
        color: $primary;
      }
    }
  }

  // .header-bottom__btn-selection

  &__btn-selection {
    border: 2px solid $secondary;
    border-radius: 5px;
    padding: 10px 20px;
    color: $secondary;
    @include button-text;
    cursor: pointer;
    background: transparent;
    line-height: 100% !important;
    position: relative;
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
  }


  // .header-bottom__menu-section

  &__menu-section {
    display: flex;
    align-items: center;
  }

  // .header-bottom__menu-item

  &__menu-item {
    margin-left: 10px;
    cursor: pointer;

    & .arrow-down {
      transition: .2s;
    }
  }

  // .header-bottom__menu-item--dropdown

  &__menu-item--dropdown {
    position: relative;

    .system-login {
      position: absolute;
      border-radius: 8px;
      background: $white;
      width: 190px;
      z-index: 1;
      right: 0;
      box-shadow: (0px 4px 8px rgba(44, 39, 56, 0.04));
      overflow: hidden;
      transform: scaleY(0);
      transform-origin: top;
      transition: .2s;

      .system-login__item {
        & a {
          display: block;
          width: 100%;
          padding: 11px 0 11px 16px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          letter-spacing: 0.1px;
          color: $secondary-text-color;
          transition: .2s;

          &:hover {
            background: $tint;
            color: $primary;
          }
        }

      }

      &.active {
        transform: scaleY(1);
      }
    }
  }

  // .header-bottom__menu-item--bonus
  &__menu-item--bonus {
    display: none;
  }

  .bonus-active {
    display: block;
  }
}

@media only screen and (max-width: 1480px) {
  .header-top__items-section {
    position: relative;
  }
  .header-top__search-wrap {
    .header-top__search {
      width: 42px;
      padding: 0;
    }

    .header-top__search .active {
      padding: 9px 15px;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1480px) {
  .header-top__search-wrap.active {
    position: absolute;
    top: 42px;
    transform: translateX(-50%);
    border: 2px solid $primary;
    border-radius: 3px;

    .header-top__search {
      width: 229px;
      padding: 9px 15px;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1425px) {
  .header-bottom {
    &__drop-menu--catalog {
      & .header-bottom__catalog-drop {
        width: 60%;
      }

      & .header-bottom__drop-pic {
        //max-width: 40%;
        //width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .header-top__search-box {
    width: 42px;
    display: flex;
  }
  .header-top__items-section {
    //width: 410px;
    //justify-conent: space-between;

    & > div {
      margin: 0;
    }
  }
  .header-top__nav-item {
    min-width: max-content;
  }
  .header-top__nav-item:last-child {
    margin-right: 0;
  }
  .header-top__phone-landline {
    justify-content: space-between;
    width: 190px;

    .header-top__phone-ico {
      margin: 0;
    }
  }
  .header-top__lang {
    align-self: center;
  }
  .header-top__lang.active {
    align-self: flex-start;
    top: 7px;
  }
  .header-top__phone-landline-number {
    margin-right: 0;
  }
  .header-top__search-wrap {
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 1290px) {
  .header-bottom__nav-dropdown {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1250px) {
  .header-bottom {
    &__sidebar-overflow-wrap {
      max-height: calc(100vh - 65px);
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-style;
    }

    &__drop-menu--catalog, &__drop-menu--services, &__drop-menu--solutions {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      & .header-bottom__drop-menu-item {
        width: 100%;
        padding: 0;

        & a {
          padding: 14px 5px;
          font-size: 13px;

          & .ico-wrap {
            margin-right: 12px;
          }
        }
      }

      & .header-bottom__catalog-drop {
        width: 100% !important;
      }

      & .header-bottom__drop-pic {
        display: none;
      }
    }
  }
  .header-top__nav, .header-top__phone, .header-top__location, .header-top__lang, .header-bottom__logo-wrap {
    display: none;
  }
  .header-top__items-section {
    width: 130px !important;
  }
  .header-top {
    height: 50px;

    &__search-products {
      top: 40px;
      right: 0;
    }
  }
  .header-bottom__drop-btn {
    margin-bottom: 6px;

    & .arrow-down {
      transition: .2s;
      // transform: rotate(-90deg);
    }
  }
  .header-bottom__btn-selection {
    margin-right: auto;
  }
  .header-bottom__nav {
    align-items: flex-start;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .header-bottom {
    width: 320px;
    max-width: 320px;
    //height: fit-content;
    border-right: 1px solid #BDBDBD;
    border-bottom: 1px solid #BDBDBD;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translateX(-100%);
    opacity: 0;
    transition: 0.3s ease-out;

    &.active {
      transform: translateX(0%);
      opacity: 1;
    }

    & .container-header, .header-bottom__nav, .header-bottom__menu-section, .header-bottom__content-wrap {
      flex-direction: column;
    }

    & .container-header {
      padding: 65px 10px 30px 10px;
      position: relative;
    }
  }
  .header-bottom__content-wrap {
    padding-bottom: 15px;
    border-bottom: 1px solid #BDBDBD;
  }
  .header-bottom__menu-section {
    align-items: flex-start;
    margin: 15px 0;
    border-bottom: 1px solid #BDBDBD;
  }
  .header-bottom__menu-item {
    margin-left: 0;
  }
  .header-bottom__menu-item:nth-child(3), .header-bottom__menu-item:last-child {
    display: none;
  }
  .header-bottom__menu-item:first-child, .header-bottom__menu-item:nth-child(2) {
    margin-bottom: 4px;
  }
  .header-bottom__menu-item--dropdown {
    & .arrow-down {
      transition: .2s;
      margin-left: 7px;
    }
  }
  .header-bottom__nav-dropdown:first-child .header-bottom__drop-menu.active {
    &.active {
      top: 100px;
    }
  }
  .header-bottom__nav-dropdown:nth-child(2) .header-bottom__drop-menu.active {
    &.active {
      top: 130px;
    }
  }
  .header-bottom__nav-dropdown {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .header-bottom__list-title {
    font-size: 12px;
    padding: 12px 0 8px;
  }

  .header-bottom__drop-menu {
    position: static;
    max-height: 0;
    transform: scaleY(1);
    opacity: 0;
    transition: .3s;
    box-shadow: none;
    border: none;

    &.active {
      max-height: 3000px;
      opacity: 1;
    }
  }
  .header-bottom__drop-menu-item {
    border-bottom: 1px solid $tint;
  }


  .header-top__search-wrap.active {
    position: absolute;
    top: 8px;
    right: -30px;
    z-index: 6;
    transform: translateX(-50%);
    border: 2px solid $primary;
    border-radius: 3px;

    .header-top__search {
      width: 229px;
      padding: 9px 15px;
    }
  }
}

.header-mobile__section {
  display: flex;
}

.header-mobile__menu-btn-wrap {
  display: flex;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  z-index: 5;
}

.header-mobile__menu-btn-wrap.active {
  transform: translateX(260px);
  // z-index: 5;
}

.header-mobile__menu-btn {
  width: 20px;
  height: 20px;
  position: relative;

  &.active {
    & span:nth-child(1) {
      top: 8px;
      transform: rotate(135deg);
    }

    & span:nth-child(2) {
      opacity: 0;
      left: -400px;
      transition: 1s;
    }

    & span:nth-child(3) {
      top: 8px;
      transform: rotate(-135deg);
    }
  }

  & span {
    height: 2px;
    background-color: $white;
    display: block;
    position: absolute;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  & span:nth-child(1) {
    top: 0;
  }

  & span:nth-child(2) {
    top: 8px;
  }

  & span:nth-child(3) {
    top: 16px;
  }
}

.header-mobile__logo-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 54px;
}

.header-mobile__compare-btn, .header-mobile__cart-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-sidebar__nav-wrap {
  padding: 15px 0;
  border-bottom: 1px solid #BDBDBD;
}

.mobile-sidebar__nav-item {
  & a {
    color: rgba(39, 48, 67, 0.8);
  }

  font-size: 14px;
  line-height: 120%;
}

.mobile-sidebar__nav-item:first-child {
  margin-bottom: 15px;
  font-weight: 600;
  line-height: 158%;
}

.mobile-sidebar__nav-item:not(:last-child) {
  margin-bottom: 15px;
}

.header-bottom__mobile-text {
  color: rgba(39, 48, 67, 0.8);
}

.mobile-sidebar__location {
  cursor: pointer;
  width: fit-content;

  .mobile-sidebar__title {
    font-size: 14px;
    line-height: 120%;
    color: rgba(39, 48, 67, 0.8);
    margin-right: 4px;
  }

  .mobile-sidebar__value {
    font-size: 14px;
    line-height: 120%;
    color: rgba(92, 130, 196, 0.8);
    border-bottom: 1px solid rgba(92, 130, 196, 0.8);
    display: inline-block;
  }
}

.header-bottom__sidebar-header {
  width: 100%;
  height: 50px;
  background-color: $primary;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-bottom__sidebar-lang-selector {
  width: 52px;
  display: flex;
  justify-content: space-between;
  margin-right: 70px;
}

.header-bottom__sidebar-lang-item {
  font-size: 12px;
  line-height: 140%;
  color: #8D8D8D;
  position: relative;
  transition: .3s;

  &.active {
    color: $white;
  }
}

.header-bottom__sidebar-lang-item:not(:last-child) {
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    border-right: 1px solid #8D8D8D;
    right: -6px;
  }
}

.header-bottom__sidebar-logo-wrap {
  margin-left: 20px;
}

@media only screen and (min-width: 1251px) {
  .header-mobile__menu-btn, .header-mobile__logo-wrap, .header-mobile__menu-btn-wrap, .header-mobile__compare-btn, .header-mobile__cart-btn, .header-bottom__mobile-text, .mobile-sidebar__location, .mobile-sidebar__nav-wrap, .header-mobile__section, .header-bottom__sidebar-header {
    display: none;
  }
}

@media only screen and (max-width: 625px) {
  .header-top {
    &__search-products {
      right: -10px;
      width: calc(100vw - 20px);
    }
  }
  .header-top__location-dropdown {
    width: 100%;

    .header-top__label {
      flex-direction: column;
    }

    .header-top__input {
      // margin-top: 8px;
    }

    .header-top__city-list {
      flex-wrap: wrap;
    }

    .header-top__city-column {
      width: 50%;
    }

    .header-top__city-item {
      text-align: center;
      margin-bottom: 10px;
    }

    .header-top__city-column:last-child {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & .header-top__city-item {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 468px) {
  .header-top__search-wrap.active {
    right: -120px;
    z-index: 6;
    transform: translateX(-50%);
    border: 2px solid $primary;
    border-radius: 3px;

    .header-top__search {
      width: 229px;
      padding: 9px 15px;
    }
  }

}

@media only screen and (max-width: 430px) {
  .header-top__location-dropdown {
    padding: 30px 20px;

    .header-top__input {
      width: 100%;
    }
  }
}

.drop-pic {
  height: 100%;

  &__wrapper {
    position: relative;
    height: 100%;
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__bg-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(45, 50, 88, .45);
  }

  &__image-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-right: 20px;
    padding-left: 20px;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 30px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: .02em;
    text-transform: uppercase;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      z-index: 5;
      height: 3px;
      background-image: linear-gradient(90deg, #fb9b51, rgba(251, 155, 81, .829), rgba(251, 155, 81, 0));
      width: 100%;
    }
  }

  &__subtitle {
    font-size: 12px;
    line-height: 150%;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    letter-spacing: .02em;
    margin-bottom: 40px;
  }

  & .btn-ghost-secondary {
    outline: none;
    padding: 7px 21px;
    color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid white;
    }

    &::before {
      border-radius: 4px;
      border: 1px solid white;
    }
  }
}
