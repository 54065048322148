@import '../../../style/vars';
@import '../../../style/mixins';

.subscription-wrap {
    width: 100%;
    position: relative;
}

.container {
}

.subscription-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-position: center;
}

.subscription {
    padding: 80px 0 95px 0;
    position: relative;
    // .subscription__title-wrap

    &__title-wrap {
        text-align: center;
        color: $white;
        margin-bottom: 57px;
    }

    // .subscription__title

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.02em;
        margin-bottom: 10px;
        color: $white;
    }

    // .subscription__subtitle

    &__subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $white;
    }

    // .subscription__form

    &__form {
        display: flex;
        justify-content: center;
        position: relative;
        width: fit-content;
        margin: 0 auto;
    }

    // .subscription__email-input

    &__email-input {
        width: 587px;
        max-width: 587px;
        margin-right: 8px;
        background: #FFFFFF;
        border-radius: 8px;
        font-size: 16px;
        color: $text-color;
        padding: 14px 67px;

        border: none;
        outline: none;
    }

    // .subscription__validation-ico--fail
    &__validation-ico--fail{

    }

    // .subscription__mail-ico
    &__mail-ico{
        display: none;
        &.active{
            display: inline;
        }
    }

    // .subscription__confirm-btn

    &__confirm-btn {
        border-radius: 8px;
    }
}
.btn-accent {

    // .btn-accent__mail-ico

    &__mail-ico {
        position: absolute;
        left: 33px;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
    }
}


@media only screen and (max-width: 880px) {
    .subscription__form{
        flex-direction: column;
        width: 100%;
        max-width: 500px;
    }
    .subscription__email-input{
        margin-right: 0;
        height: 48px;
        margin-bottom: 12px;
        width: 100%;
        font-size: 14px;
        line-height: 17px;
    }
    .btn-accent__mail-ico{
        height: fit-content;
        top: 12px;
    }
    .subscription__title{
        font-size: 16px;
    }
    .subscription__subtitle{
        font-size: 11px;
        line-height: 120%;
    }
    .subscription__confirm-btn{
        width: 100%;
        margin: 0 auto;
    }
}
