$accent: #FB9B51;
$hover: #F88125;
$primary: #1A3B70;
$secondary: #2C599D;
$additional: #5C83C4;
$text-color: #273043;
$secondary-text-color: #8D8D8D;
$muted: #DCDCDC;
$white: #FFFFFF;
$shade: #DBE2EA;
$tint: #EBF4F8;
$success: #14A38B;
$error: #FF7171;

@mixin title {
  @media (min-width: 880px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  @media (max-width: 880px) {
    font-size: 24px;
  }
}

.industry-solutions-wrap {
}
.industry-solutions {

  // .industry-solutions__title

  &__title {
    @include title;
    color: $primary;
    margin-bottom: 60px;
  }

  // .industry-solutions__content-box

  &__content-box {
    position: relative;
    display: flex;
    align-items: center;
  }

  // .industry-solutions__content-img-box

  &__content-img-box{
    position: relative;
    width: 100%;
    height: 600px;
  }

  // .industry-solutions__content-img

  &__content-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity .5s;

    &.active {
      opacity: 1;
    }
  }

  // .industry-solutions__content-selectors

  &__content-selectors {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 150px;
    background: rgba(255, 255, 255, 0.9);
    width: 50%;
    max-width: 807px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // .industry-solutions__bg-img

  &__bg-img {
    position: absolute;
    right: 0;
    top: 0;
  }

  // .industry-solutions__content-list

  &__content-list {
    min-width: 600px;
  }

  // .industry-solutions__content-list-item

  &__content-list-item {
    height: 33px;
    cursor: pointer;
    transition: .3s;
    &:active, &:hover{
      .industry-solutions__content-list-text{
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $secondary;
        transition: .3s;
      }
      .industry-solutions__arrow{
        & svg{
          transform: scaleX(1);
        }
      }
    }
  }

  // .industry-solutions__content-list-text

  &__content-list-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: $text-color;
    transition: .3s;
  }

  // .industry-solutions__arrow

  &__arrow {
    margin-left: 30px;

    & svg{
      transition: .3s;
      transform: scaleX(0);
      transform-origin: left;
      overflow: hidden;
      margin-bottom: 5px;
    }

  }
}
.is-ar2{
  display: none;
}
@media only screen and (max-width: 1440px) {
  .is-ar1{
    display: none;
  }
  .is-ar2{
    display: inline;
  }
  .industry-solutions__arrow{
    margin-left: 7px;
  }
}


@media only screen and (max-width: 1280px) {
  .industry-solutions {
    &__content-selectors{
      min-width: 655px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .industry-solutions-wrap{
    padding: 0 0 30px;
  }

  .industry-solutions{
    &__title{
      margin-bottom: 40px;
      font-size: 24px;
      text-transform: uppercase;
    }
    &__content-selectors{
      min-width: auto !important;
      width: 100%;
      right: 0;
      max-width: 100%;
    }
    &__content-img-box{
      height: 450px;
    }
    &__content-list{
      margin-left: 14px;
      min-width: auto;
    }
    &__arrow{
      display: none;
    }
    &__content-list-item {
      &:active, &:hover{
        .industry-solutions__content-list-text{
          font-size: 14px;
          line-height: 130%;
          color: $secondary;
          text-transform: none;
        }
      }
    }
    &__content-list-text{
      font-size: 14px;
      line-height: 130%;
    }
  }
}
@media only screen and (max-width: 490px) {
  .industry-solutions__bg-img{
    display: none;
  }
}