@import '../../style/vars';
@import '../../style/mixins';

.services {

  & .container{
    padding: 0 10px !important;
  }

  // .services__header

  &__header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 620px;
    &::after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #273043;
      opacity: 0.8;
      // z-index: 2;
    }
  }

  // .services__header-body

  &__header-body{
    display: flex;
    flex-direction: column;
    max-width: 1290px;
    padding: 0 10px;
    z-index: 1;
    padding-bottom: 5%;
  }

  // .services__header-bg

  &__header-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    .video-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .services__header-title-wrap

  &__header-title-wrap{
    margin-bottom: 40px;
  }

  // .services__header-title

  &__header-title {
    position: relative;
    font-weight: 800;
    font-size: 48px;
    line-height: 150%;
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }

  // .services__header-title-price

  &__header-title-price{
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: $white;
    text-align: center;
  }

  // .services__header-subtitle

  &__header-subtitle{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: $white;
    text-align: center;
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }

  // .services__body

  &__body {
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1030px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  // .services__title

  &__title {
    @include subtitle;
    color: $secondary;
    text-align: center;
    margin-bottom: 70px;
  }

  // .services__body-content

  &__body-content {
    width: 100%;
    position: relative;
    margin-bottom: 100px;
  }

  // .services__tab-wrap

  &__tab-wrap {
    box-shadow: 0 0 16px #DBE2EA;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  // .services__tab

  &__tab {
    display: flex;
    width: 100%;
    background: $tint;
    align-items: center;
    height: 86px;
    transition: .25s;
    cursor: pointer;
    background: $white;
    & + .services__description-wrap{
      transition: 0.3s;
      max-height: 0;
    }
    &.active{
      background: #1A3B70;
      & + .services__description-wrap{
        transition: 0.3s;
        max-height: 1500px;
      }
      & .services__count-number{
        -webkit-text-stroke-color: transparent;
      }
      & .services__tab-title{
        color: $white;
      }
      & .services__tab-arrow{
        transform: rotate(180deg);
        & svg path {
          fill: $white;
        }
      }
      & .services__tab-ico{
        & .active{
          opacity: 1;
        }
        & .no-active{
          opacity: 0;
        }
      }
    }
  }

  // .services__tab-ico

  &__tab-ico {
    margin-left: 46px;
    position: relative;
    & .active{
      transition: .25s;
    }
  }

  // .services__tab-title

  &__tab-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: $text-color;
    transition: 0.2s;
    margin-left: 28px;
  }

  // .services__tab-arrow

  &__tab-arrow {
    margin-left: auto;
    margin-right: 30px;
    transform: rotate(0);
    transition: .3s;
    & svg path {
      transition: .2s;
    }
  }

  //.services__description-wrap

  &__description-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    transition: .3s;
    transition-delay: .2s;
  }
  // .services__description

  &__description {
    width: 100%;
    display: flex;
  }

  // .services__description-img

  &__description-img {
    max-width: 433px;
    min-width: 433px;
    height: 100%;
    position: relative;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
    &::before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
    }
    & img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  // .services__description-body

  &__description-body {
    width: 100%;
    padding: 26px 33px 36px 58px;
    background: $white;
    border-radius: 8px;

  }

  // .services__description-title

  &__description-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $text-color;
    margin-bottom: 1.5em;
  }

  // .services__description-list

  &__description-list {
    font-size: 14px;
    line-height: 150%;
    color: $text-color;
  }

  // .services__description-li

  &__description-li {
    margin-bottom: 6px;
  }

  // .services__btns-wrap

  &__btns-wrap{
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
  }

  // .services__order-btn

  &__order-btn {
    background: #F88125;;
    border-radius: 5px;
    width: 221px;
    height: 51px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    display: block;
    cursor: pointer;
    position: relative;
    backface-visibility: hidden;
    transform: translateZ(0);
    @include button-text;
    transition: .3s;
    &:before {
      content: '';
      border-radius: 5px;
      position: absolute;
      border: #F88125 solid 4px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-animation-duration: 0.8s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }
    &:hover{
      &:hover:before, &:focus:before, &:active:before {
        -webkit-animation-name: hvr-ripple-out;
        animation-name: hvr-ripple-out;
        animation-fill-mode: forwards;
        // opacity: 0;
      }
    }
  }

  // .services__details-btn

  &__details-btn {
    border: 1px solid #2c599d;
    height: 51px;
    width: 217px;
    &::before{
      border: 1px solid #2c599d;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
    }
    &:hover{
      border: 1px solid #f88125;
    }
  }

  & .product-slider-wrap{
    padding-bottom: 60px;
  }
}

.other-services {
  width: 100%;
  margin-bottom: 100px;
  // .other-services__title

  &__title {
    @include subtitle;
    color: $secondary;
    margin-bottom: 70px;
  }

  // .other-services__body

  &__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  // .other-services__item

  &__item {
    width: 24%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    &:hover{
      & .other-services__bg {
        &::before{
          opacity: 1;
        }
        &::after{
          opacity: 0;
        }
      }
    }
  }

  // .other-services__bg

  &__bg {
    width: 100%;
    height: auto;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(26, 59, 112, 0.75) 0%, #091A34 100%);
      mix-blend-mode: multiply;
      border-radius: 8px;
      z-index: 1;
      opacity: 0;
      transition: .3s;
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
      mix-blend-mode: multiply;
      border-radius: 8px;
      z-index: 1;
      opacity: 1;
      transition: .3s;
    }
    & img{
      width: 100%;
      object-fit: cover;
    }
  }
  &__card-title{
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: $white;
    margin: auto;
    width: 100%;
    top: 0; left: 0; bottom: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    z-index: 1;
  }
}

@media only screen and (max-width: 1038px){
  .services{
    &__description-img {
      width: 40%;
      min-width: 40%;
    }
    &__description-body {
      width: 60%;
      padding-left: 3%;
      padding-right: 3%;
    }
  }
}

@media only screen and (max-width: 968px){
  .services{
    &__btns-wrap{
      flex-direction: column;
      align-items: center;
    }
    &__order-btn{
      margin-bottom: 8px;
    }
  }
}

@media only screen and (max-width: 880px){
  .content-wrap .services{
    &__header{
      height: fit-content;
      min-height: 555px;
    }
    &__header-body{
      padding: 0 24px 5%;
    }
    &__header-title-wrap{
      margin-bottom: 11px;
    }
    &__header-title{
      font-size: 18px;
      text-align: left;
      margin-bottom: 11px;
    }
    &__header-title-price{
      font-size: 14px;
      text-align: left;
    }
    &__header-subtitle{
      font-size: 14px;
      text-align: left;
    }
    &__title{
      text-align: left;
    }
    &__body {
      margin-top: 40px;
    }
    &__tab{
      height: 56px;
    }
    &__tab-title{
      font-size: 12px;
      margin-left: 14px;
    }
    &__tab-arrow{
      margin-right: 12px;
      & svg{
        height: 8px;
      }
    }
    .services__title{
      font-weight: 600;
      margin-bottom: 24px;
    }
    &__description{
      flex-direction: column;
      align-items: center;
    }
    &__description-img {
      width: 100%;
      min-width: auto;
      max-width: 495px;
    }
    &__description-body {
      width: 100%;
      padding-left: 3%;
      padding-right: 3%;
    }
    &__body{
      padding: 0;
    }
    &__order-btn{
      height: 45px;
    }
    &__details-btn{
      height: 45px;
    }
    &__tab-ico{
      height: 36px;
      width: 36px;
      margin-left: 8px;
      & svg{
        height: 36px;
        width: 36px;
      }
    }
  }
  .other-services{
    margin-bottom: 40px;
    &__title{
      margin-bottom: 40px;
    }
    &__card-title{
      font-size: 16px;
    }
    & .product-slider-wrap{
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1290px){
  .other-services{
    &__body{
      flex-wrap: wrap;
    }
    &__item {
      width: 48%;
      margin-bottom: 4%;
    }
  }
}


@media only screen and (max-width: 470px){
  .other-services{
    &__item {
      width: 100%;
    }
  }
}