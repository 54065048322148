.product-sl-dots {
    display: flex !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
    bottom: -90px;

    li.slick-active button {
        background-color: #FB9B51;
    }

    li:not(:last-child) {
        margin-right: 12px;
    }

    li button {
        font-size: 0;
        width: 30px;
        height: 3px;
        border-radius: 2px;
        background-color: #FFFFFF;
        border: none;
        display: block;
        cursor: pointer;
    }

    li button {
        font-size: 0;
        width: 30px;
        height: 3px;
        border-radius: 2px;
        background-color: #1a3b70;
    }
}

@media only screen and (max-width: 792px) {
    .product-sl-dots {
        bottom: 30px;
    }
}

@media (max-width: 400px) {
    .product-sl-dots {
        bottom: 20px;
    }
}
