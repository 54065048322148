@import '../../../style/vars';

.footer {
    width: 100%;
    background: $text-color;
    display: flex;
    & .container-header {
        position: relative;
    }
    .page-up-btn{
        background: transparent;
        position: absolute;
        right: 0;
        top: 40px;
        height: fit-content;
        cursor: pointer;
        & svg{

        }
        &:hover{
            svg{
                rect{
                    fill: transparent;
                }
                path{
                    fill: $white;
                }
            }
        }
    }
    // .footer__top-section

    &__top-section {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
        margin-bottom: 42px;
    }

    // .footer__section

    &__section {
    }

    // .footer__section--logo

    &__section--logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .footer__section-logo

    &__section-logo {
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    // .footer__section--list

    &__section--list {

    }

    // .footer__list-title

    &__list-title {
        font-weight: 600;
        font-size: 16px;
        color: $tint;
        margin-bottom: 24px;
        position: relative;
        & .plus{
            display: none;
            position: absolute;
            width: 11px;
            height: 11px;
            top: 5px;
            right: 10px;
            &:before,
            &:after{
                content: "";
                position: absolute;
                background-color: $muted;
                transition: transform 0.25s ease-out;
            }

            /* Vertical line */
            &:before{
                top: 0;
                left: 50%;
                width: 1px;
                height: 100%;
                margin-left: -0.5px;
            }

            /* horizontal line */
            &:after{
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                margin-top: -0.5px;
            }

            &.active{
                &:before{ transform: rotate(90deg); }
                &:after{ transform: rotate(180deg); }
            }
        }
    }

    // .footer__list

    &__list {
    }

    // .footer__item

    &__item {
        font-size: 14px;
        line-height: 120%;
        color: $white;
        margin-bottom: 10px;
        max-width: 180px;
        & a{
            color: inherit;
        }

        &:hover {
            & a {
                text-decoration: underline;
            }
        }
    }

    // .footer__section--contact

    &__section--contact {

    }

    // .footer__cellphone-section

    &__cellphone-section {
    }

    // .footer__cellphone-item

    &__cellphone-item {
        font-size: 14px;
        line-height: 150%;
        color: $white;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        & img{
            margin-right: 8px;
        }
        & a{
            color: inherit;
        }
    }

    // .footer__cellback-button

    &__cellback-button {
        margin-top: 30px;
        background: transparent;
        border: 2px solid $white;
        border-radius: 5px;
        height: 45px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $white;
    }

    // .footer__social-box

    &__social-box {
        margin-top: 25px;
        margin-bottom: 25px;
        height: fit-content;
    }

    &__logos-box {
    }

    &__logos-box-image {
        margin-right: 10px;
    }

    &__logos-title {
        font-size: 14px;
        line-height: 21px;
        color: white;
        margin-bottom: 5px;
    }

    // .footer__facebook-link

    &__facebook-link {
        height: 34px;
        display: inline-block;
        & svg rect, & svg path{
            transition: .2s ease-in-out;
        }
        &:hover{
            svg rect{
                fill: transparent;
            }
            svg path{
                fill: $white;
            }
        }
        &:first-child{
            margin-right: 20px;
        }
    }

    // .footer__bottom-section

    &__bottom-section {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 150%;
        color: $secondary-text-color;
    }

    // .footer__rights

    &__rights {
        margin: 0 15px;
        color: $secondary-text-color;
    }

    // .footer__agreements

    &__agreements {
        margin: 0 15px;
        color: inherit;
    }

    &__developer {
        margin: 0 15px;
        color: inherit;

        & > a {
            color: inherit;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .footer__list-title{
        height: 30px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        & .plus{
            display: block;
            top: 10px;
        }
    }
    .page-up-btn{
        top: 140px !important;
    }
    .footer__social-box{
        margin-top: 0;
    }
    .footer__top-section{
        flex-wrap: wrap;
        width: 786px;
        margin: 0 auto;
        justify-content: space-between;
    }
    .footer__list{
        height: 0;
        overflow: hidden;
        transition: .3s;
        &.active{
            height: fit-content;
            margin-top: 8px;
        }
    }
    .footer__section--logo{
        width: 100%;
        justify-content: flex-start;
        & .footer__section-logo{
            width: 122px;
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }
    .footer__section--list{
        height: fit-content;
        width: 170px;
        margin-bottom: 12px;
        border-bottom: 1px solid #4F4F4F;
    }
    .footer__section--list:not(:last-child){
        // margin-right: 20px;
    }
    .footer__list{
        // display: none;
    }
    .footer__section--contact{
        width: 100%;
        display: flex;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 820px) {
    .footer {
      &__top-section{
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .footer__section--contact{
          width: 100%;
          max-width: 400px;
          margin-top: 18px;
          margin-bottom: 30px;
          min-width: auto;
          justify-content: space-between;
          align-items: flex-start;
        }
        .footer__cellphone-section{
          width: fit-content;
        }
        .footer__section--list{
          width: 100%;
          max-width: 400px;
        }
      }

      &__rights, &__developer {
        margin-left: 0;
      }
    }

    .page-up-btn{
        top: auto !important;
        bottom: 72px !important;
        right: 3px !important;
    }
    .footer .container-header{
        width: 420px;
        max-width: 420px;
    }

    .footer__bottom-section{
        flex-direction: column;
        align-items: flex-start;
        .footer__agreements{
            margin-left: 0;
        }
    }
    .footer__cellback-button{
        width: 130px;
        height: 29px;
        border: 1px solid #FFFFFF;
        font-size: 11px;
        cursor: pointer;
    }
    .footer__cellphone-item{
        font-size: 12px !important;
        & img{
            width: 14px;
            height: 14px;
        }
    }
    .footer__list-title{
        font-size: 14px;
    }
    .footer__item{
        font-size: 11px;
        margin-bottom: 6px;
    }
    .footer__bottom-section{
        font-size: 11px;
    }
}

@media only screen and (max-width: 425px) {
    .footer__top-section, .footer__section{
        width: 100% !important;

        &--contact {
            flex-direction: column;
        }
    }

    .footer__cellphone-section {
        margin-bottom: 20px;
    }

    .footer__social-box{
        margin-top: 0;
        display: flex;

        .footer__facebook-link{
            display: block;
            margin-right: 0;
            margin-bottom: 10px;
            &:first-child{
                margin-right: 16px;
            }
        }
    }
}