.form{
    &__label{
        position: relative;

        & .label-tooltip {
          visibility: hidden;
          width: 120px;
          background-color: black;
          color: white;
          text-align: center;
          padding: 5px 0;

          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -60px;
          z-index: 1;
        }

        &:hover, &:focus {
          & .label-tooltip {
            visibility: visible;
          }
        }

        & .field-title{
            position: absolute;
            left: 12px;
            top: 1px;
            font-size: 14px;
            color: $secondary-text-color;
            transition: 0.2s;
        }

        & .error-message {
            top: initial !important;
            bottom: -30px;
            left: 5px;
            position: absolute;
            visibility: hidden;
            color: $error;
            font-size: 12px;

            &.active {
                visibility: visible;
            }
        }

        & .required{
            &::after{
                content: '*';
                color: red;
            }
        }
    }
    &__input{
        width: 100%;
        border: 1px solid $shade;
        box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 16px 16px;
        &:focus ~ .field-title,
        &:not(:placeholder-shown) ~ .field-title
        {
            top: -14px;
            font-size: 11px;
        }
    }
}
.textarea-wrap{
    position: relative;
    & span{
        position: absolute;
        top: 6px !important;
        left: 16px;
        color: $secondary-text-color;
    }
}
.form__textarea{
    padding-top: 27px;
}
#file-input{
    opacity: 0;
    position: absolute;
    width: 97px;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
    // z-index: -1;
    // overflow: hidden;
    // width: 0.4px;
    // height: 0.4px;
}
.file-upload-label{
    width: 97px;
    height: 50px;
    margin-left: auto;
}
.file-upload{
    background: $white;
    border: 1px solid $shade;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    border-radius: 8px;
    height: 52px;
    position: relative;
    display: flex;
    align-items: center;
}
.file-upload-btn{
    background: $white;
    height: 100%;
    width: 97px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.file-upload-text{
    font-size: 14px;
    line-height: 17px;
    color: $secondary-text-color;
}
.file-upload-ico{
    margin: 0 10px 0 12px;
}


@media only screen and (max-width: 880px){
    .basket-form-wrap{
        margin-right: 0;
        margin-bottom: 36px;
    }
    .form{
        &__label{
            & span{
                left: 12px;
                top: 19px;
                font-size: 12px;
            }
            & .required{
                &::after{
                    content: '*';
                    color: red;
                }
            }
        }
        &__input{
            width: 100%;
            border: 1px solid $shade;
            box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
            border-radius: 8px;
            font-size: 12px;
        }
    }
}

.form__input, .form-modal__input{
    &.invalid{
        border-color: #FF7171;
    }
    &.valid{
        border-color: #14A38B;
    }
}

.support__request-form {
    & .error-message {
        bottom: 5px;
    }
}