@import '../../style/vars';
@import '../../style/mixins';

.solutions-catalog {

  // .solutions-catalog__title

  &__title {
    @include title;
    color: $primary;
    margin-bottom: 50px;
    text-transform: uppercase;
    text-align: center;
  }
}

.sc-industry-solutions {
  margin-top: 70px;
  // .sc-industry-solutions__body

  &__body {
    display: grid;
    column-gap: 1.25%;
    grid-template-columns: 24% 24% 24% 24%;
  }

  // .sc-industry-solutions__solution-item

  &__solution-item {
  }

}
.sc-solution-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: block;
  margin-bottom: 4%;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(26, 59, 112, 0.75) 0%, #091A34 100%);
    mix-blend-mode: multiply;
    border-radius: 8px;
    z-index: 1;
    opacity: 0;
    transition: .3s;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(248, 129, 37, 0.35) 0%, #1A3B70 100%);
    mix-blend-mode: multiply;
    border-radius: 8px;
    z-index: 1;
    opacity: 1;
    transition: .3s;
  }
  // .sc-solution-item__bg

  &__bg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  // .sc-solution-item__title

  &__title {
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: $white;
    width: 90%;
    will-change: transform;
    backface-visibility: hidden;
    filter: blur(.0px);
    transition: .3s;

  }
  &:hover{
    &::before{
      opacity: 1;
    }
    &::after{
      opacity: 0;
    }
    .sc-solution-item__title{
      bottom: 50%;
      transform: translateY(50%);
      transition: .3s;
    }
  }
}
.sc-transport-solutions {
  margin-top: 70px;
  margin-bottom: 70px;

  // .sc-transport-solutions__body

  &__body {
    display: grid;
    column-gap: 2%;
    grid-template-columns: repeat(3, 32%);
  }

  // .sc-transport-solutions__solution-item

  &__solution-item {
  }
}
.sc-transport-item {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6%;
  box-shadow: inset 0 2px 8px rgba(219, 226, 234, 0.8), inset 0 0 16px rgba(219, 226, 234, 0.6);
  border: 2px solid #DBE2EA;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &__img-section{
    width: 300px;
    max-width: 300px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  // .sc-transport-item__img-wrap

  &__img-wrap {
    max-width: 290px;
    max-height: 194px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
  }

  // .sc-transport-item__img

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: .3s;
  }

  // .sc-transport-item__title

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: $secondary;
    margin-top: 5px;
    margin-bottom: 14px;
    text-align: center;
  }

  // .sc-transport-item__img-wrap--standard-alt

  &__img-wrap--standard-alt{
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  // .sc-transport-item__img-wrap--plane

  &__img-wrap--plane {

  }

  // .sc-transport-item__img-wrap--plane-alt

  &__img-wrap--plane-alt {
    position: absolute;
    left: 0;
    right: 0;
    top: 14px;
    margin: 0 auto;
    transform: translate(-140px, 140px);
    opacity: 0;
  }

}

@media only screen and (max-width: 1370px){
  .sc-industry-solutions__body{
    column-gap: 1.25%;
    grid-template-columns: repeat(3, 32.5%);
  }
}
@media only screen and (max-width: 1370px){
  .sc-transport-solutions__body{
    column-gap: 3%;
    grid-template-columns: repeat(2, 48.5%);
  }
}
@media only screen and (min-width: 880px){
  .sc-transport-item:hover{
    .sc-transport-item__img-wrap--standard{
      transform: translateX(150%);
      opacity: 0;
      transition: transform .5s ease .1s,opacity .3s ease .1s;
    }
    .sc-transport-item__img-wrap--standard-alt{
      opacity: 1;
      transform: translateX(137.5%);
      transition: transform .5s ease .1s,opacity .5s ease .2s;
    }
    .sc-transport-item__img-wrap--stationar{
      animation: shake .7s;
    }

    .sc-transport-item__img-wrap--plane {
      opacity: 1;
      transform: translate(100px, -100px);
      transition: transform .5s ease .1s, opacity .5s ease .2s;
    }
    .sc-transport-item__img-wrap--plane-alt{
      transform: translate(0px, 0px);
      opacity: 1;
      transition: transform .5s ease .1s, opacity .5s ease .1s;
    }
  }
}
@media only screen and (max-width: 880px){
  .sc-solution-item__title{
    font-size: 18px;
  }
  .sc-transport-item__title{
    font-size: 18px;
  }
  .sc-industry-solutions {
    margin-top: 40px;
  }
  .sc-transport-solutions{
    margin-bottom: 40px;
  }
  .solutions-catalog__title{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 720px){
  .sc-industry-solutions__body{
    grid-template-columns: repeat(2, 48.5%) !important;
    column-gap: 3% !important;
  }
}
@media only screen and (max-width: 536px){
  .sc-industry-solutions__body{
    grid-template-columns: 100% !important;
  }
  .sc-solution-item__title{
    font-size: 20px;
  }
  .sc-transport-solutions__body{
    grid-template-columns: 100%;
  }
}


@keyframes to-right {
  24%  {transform: translateX(0px); opacity: 0;}
  25% {transform: translateX(-320px);}
  80% {opacity: 1;}
  100% {transform: translateX(0px);}
}

@keyframes shake {
  10%  {transform: rotate(-0.5deg) translate(-1%)}
  20% {transform: rotate(0.5deg) translate(1%)}
  40% {transform: rotate(-0.5deg) translate(-0.5%)}
  50% {transform: rotate(0.5deg) translate(0.5%)}
  70% {transform: rotate(-0.5deg) translate(-1.1%)}
  90% {transform: rotate(0.5deg) translate(0.5%)}
  100% {transform: rotate(-0.5deg) translate(-0.5%)}
}
