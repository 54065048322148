@import '../../style/vars';

.basket-page {
    margin: 70px 0 80px;
    // .basket-page__title

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $primary;
        text-align: center;
        margin-bottom: 70px;
    }

    // .basket-page__basket

    &__basket {
        border: 1px solid #DBE2EA;
        border-radius: 8px;
        background: $tint;
        padding: 45px 65px 50px;
    }

    // .basket-page__header

    &__header {
        display: flex;
        justify-content: flex-end;
        height: 60px;
        border-bottom: 1px solid $shade;
    }

    // .basket-page__header-container

    &__header-container {
        display: flex;
        justify-content: space-between;
        max-width: 476px;
        width: 476px;
        padding-right: 100px;
    }

    // .basket-page__header-item

    &__header-item {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: $primary;
        width: 120px;
        text-align: center;
    }

    // .basket-page__footer

    &__footer {
        padding-top: 46px;
        display: flex;
        justify-content: space-between;
    }

    // .basket-page__footer-left-wrap

    &__footer-left-wrap {
        height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    &__bonus {
        display: flex;
        align-items: center;
    }

    // .basket-container__bonus-label

    &__bonus-label {
        margin: 0 10px;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
    }

    // .basket-container__bonus-value

    &__bonus-value {
        font-size: 14px;
        line-height: 150%;
        color: $additional;
        text-decoration-line: underline;
    }

    // .basket-page__back-btn

    &__back-btn {
        background: transparent;
        border: 1px solid #2c599d;
        display: flex;
        align-items: center;
        width: fit-content;
        &::before {
            border: 1px solid #2c599d;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
        }
        &:hover {
            border: 1px solid #f88125;
        }
    }

    // .basket-page__footer-right-wrap

    &__footer-right-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
    }

    // .basket-page__total

    &__total {
        font-weight: 600;
    }

    // .basket-page__total-price

    &__total-price {
        font-weight: 700;
    }
    &__total, &__total-price {
        font-size: 20px;
        line-height: 120%;
        color: $secondary;
    }

    & .basket-empty {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title {
            font-weight: 500;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 18px;
            color: $primary;
        }
        &__subtitle {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 36px;
        }
    }
}
.basket-page-product {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $shade;

    // .basket-page-product__link-wrap

    &__link-wrap {
        display: flex;
        align-items: center;
    }

    // .basket-page-product__img

    &__img {
        width: 160px;
        height: 160px;
        min-width: 160px;
        min-height: 160px;
        margin-right: 85px;
        position: relative;
        & img {
            width: 100%;
            height: auto;
            object-fit: cover;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
    }

    // .basket-page-product__name

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
        margin-bottom: 10px;
        max-width: 450px;
    }

    // .basket-page-product__subtitle

    &__subtitle {
        font-size: 14px;
        line-height: 150%;
    }

    // .basket-page-product__right-section

    &__right-section {
        display: flex;
        justify-content: space-between;
        max-width: 476px;
        width: 476px;
        padding-right: 100px;
        position: relative;
        & .close-btn {
            height: fit-content;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            cursor: pointer;
        }
    }

    // .basket-page-product__counter-wrap

    &__counter-wrap {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .basket-page-product__price-wrap

    &__price-wrap {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .basket-page-product__price

    &__price {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
    }

    // .basket-page-product__deleted

    &__deleted {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 20px 100px 15px 243px;
    }

    // .basket-page-product__deleted-title

    &__deleted-title {
        font-size: 18px;
        line-height: 150%;
        color: #000;
    }

    // .basket-page-product__cancel-btn

    &__cancel-btn {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        cursor: pointer;
        color: #2C599D;
        padding: 0;
        background: transparent;
    }
}





@media only screen and (max-width: 1290px){
    .basket-page {

        &__footer {
            flex-direction: column;
            align-items: center;
        }
        &__header-container {
            max-width: 320px;
            width: 320px;
            padding-right: 50px;
        }
        &__footer-right-wrap {
            width: 100%;
        }
        &__basket {
            padding: 30px 24px;
        }

        &__back-btn {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            text-align: center;
        }
    }
    .basket-page-product {
        &__right-section {
            max-width: 320px;
            width: 320px;
            padding-right: 50px;
        }
        &__deleted {
            padding-right: 50px;
        }
        &__img {
            margin-right: 20px;
        }
        &__deleted {
            padding-left: 180px;
        }
    }
}

@media only screen and (max-width: 880px){
    .basket-page {
        margin: 50px 0;

        &__footer-right-wrap {
            flex-direction: column;
            align-items: center;
        }

        &__total, &__total-price {
            font-size: 16px;
        }
        &__total {
            margin-bottom: 4px;
        }
        &__total-price {
            margin-bottom: 12px;
        }

        &__title {
            font-size: 24px;
            margin-bottom: 36px;
        }

        &__confirm-btn {
            margin-bottom: 0;
        }
        &__header {
            height: 45px;
        }
        &__header-container {
            padding-right: 0 !important;
        }
        &__header-item {
            font-size: 17px;
        }
        &__header-container {
            max-width: 230px !important;
            width: 230px;
        }
        & .basket-empty {
            &__title {
                font-size: 20px;
                margin-bottom: 18px;
            }
            &__subtitle {
                font-size: 16px;
                margin-bottom: 36px;
            }
        }
    }
    .basket-page-product {
        &__right-section {
            padding-right: 0 !important;
            & .close-btn {
                top: 0;
                bottom: auto;
            }
        }
        &__img {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
        }
        &__name {
            font-size: 14px;
        }
        &__subtitle {
            font-size: 12px;
        }
        &__price {
            font-size: 17px;
        }
        &__deleted {
            padding-left: 135px;
        }
        &__right-section {
            max-width: 230px !important;
            width: 230px;
        }
        &__deleted-title, &__cancel-btn {
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 620px){
    .basket-page-product {
        padding: 8px 0;
        &__link-wrap {
            flex-direction: column;
            align-items: flex-start;
        }
        &__img {
            margin-right: 0;
        }
        &__info {
            //text-align: center;
        }
        &__deleted {
            padding-left: 0;
            padding-right: 0;
        }
    }

}


@media only screen and (max-width: 490px) {
    .basket-page {
        .basket-page {
            &__header {
                height: 1px;
            }
            &__header-container {
                display: none;
            }

        }
    }
    .basket-page-product {
        &__right-section {
            width: fit-content;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__price-wrap {
            margin-top: 10px;
        }
        &__counter-wrap {
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width: 420px) {
    .basket-page {
        &__basket {
            padding: 24px 10px;
        }
    }
}
